import React, { ChangeEvent, useState } from 'react';
import BmwButton from '../components/form/BmwButton';
import { useTranslation } from 'react-i18next';
import { FORMAT } from '../utils/constants';
import { requestImportFolderStatus } from '../state/folder/FolderEffects';
import { FolderStatusImportResponse } from '../types/model/folderStatusImportResponse';

type Props = {
  path: string;
};

const FolderStatusImportScreen = (props: Props) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<FolderStatusImportResponse | undefined>(
    undefined,
  );

  const onFileUpload = (e: ChangeEvent<HTMLInputElement>): void => {
    setResult(undefined);
    const targetFiles = e.target.files;
    if (targetFiles && targetFiles.length > 0) {
      const data = targetFiles[0];
      setFile(data);
    }
  };

  const handleSubmit = () => {
    if (file) {
      setLoading(true);

      const formData = new FormData();
      formData.append('file', file);

      void requestImportFolderStatus(formData).then((response) => {
        setLoading(false);
        if (response.ok && response.data) {
          setResult(response.data);
        } else {
          setResult(undefined);
        }
      });
    }
  };

  return (
    <div className="content-body container px-2 px-sm-0">
      <div id="back-office">
        <h2 className="title">
          {t('reporting.importFolderStatus.screen.title')}
        </h2>

        <div className="mb-3">
          <p>{t('reporting.importFolderStatus.screen.description')}</p>
          <p className="mb-0 font-italic">
            {t('reporting.importFolderStatus.screen.format_1')}
            <br />
            {t('reporting.importFolderStatus.screen.format_2')}
            <br />
            {t('reporting.importFolderStatus.screen.format_3')}
          </p>
        </div>

        <div className="card p-2 text-center mb-3">
          <input
            disabled={loading}
            type="file"
            className={`extra-file-input cursor-pointer${
              loading ? ' loading' : ''
            }`}
            accept={FORMAT.CSV.join(',')}
            onChange={onFileUpload}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end buttons">
        <BmwButton
          loading={loading}
          onClick={handleSubmit}
          disabled={!file}
          label={t('actions.save')}
        />
      </div>

      {result && (
        <>
          <h3>{t('reporting.importFolderStatus.screen.results')}</h3>
          <p>
            <span className="font-weight-bold">
              {t('reporting.importFolderStatus.screen.updated', {
                count: result?.updated.length,
              })}
            </span>
            {result?.updated.length > 0 && (
              <ul>
                {result?.updated.map((id) => (
                  <li>#{id}</li>
                ))}
              </ul>
            )}
          </p>
          <p>
            <span className="font-weight-bold">
              {t('reporting.importFolderStatus.screen.unchanged', {
                count: result?.unchanged.length,
              })}
            </span>
            {result?.unchanged.length > 0 && (
              <ul>
                {result?.unchanged.map((id) => (
                  <li>#{id}</li>
                ))}
              </ul>
            )}
          </p>
          <span className="font-weight-bold">
            {t('reporting.importFolderStatus.screen.errors', {
              count: result?.errors.length,
            })}
          </span>
          {result?.errors.length > 0 && (
            <ul>
              {result?.errors.map((item) => (
                <li>
                  #{item.id} :{' '}
                  {t(
                    `reporting.importFolderStatus.errors.${
                      item.error ?? 'unknown'
                    }`,
                    item.error,
                  )}
                </li>
              ))}
            </ul>
          )}
          <p></p>
        </>
      )}
    </div>
  );
};

export default FolderStatusImportScreen;
