import { createEffect } from 'effector';
import {
  CONTENT_TYPE,
  restCreationHandler,
  restDetailsHandler,
  restPatchHandler,
} from '../../rest/RestClient';
import { FolderResponse } from '../../types/model/folderResponse';
import { handleParsedResponse } from '../../utils/utils';
import { setFolder, setFolderCompletion } from './FolderEvents';
import { CompletionResponse } from '../../types/model/completionResponse';
import { FOLDERS } from '../../rest/apiPath';
import { FolderStatusFrontUpdateRequest } from '../../types/model/folderStatusFrontUpdateRequest';
import { ENDING_STATUS } from '../../utils/constants';
import { FolderStatusFrontUpdateEnum } from '../../types/model/folderStatusFrontUpdateEnum';
import { ParsedResponse } from '../../rest/ServerResponseParse';
import { TypeFolderStore } from '../../types/storeTypes';
import { clearDocuments } from '../documents/DocumentsEvents';
import { FolderStatusImportResponse } from '../../types/model/folderStatusImportResponse';

export const requestGetFolder = createEffect({
  handler: restDetailsHandler<FolderResponse, null>(FOLDERS.FOLDER),
});

requestGetFolder.done.watch(({ result, params }) =>
  handleParsedResponse(
    result,
    false,
  )((data: FolderResponse) => {
    setFolder({
      ...data,
      nbDocumentToUpload: 0,
      nbDocumentValid: 0,
      nbDocumentTotal: 0,
      hasFeedbackToSubmit: false,
      isMissingRefusalId: false,
      completed: false,
    });
    if (!ENDING_STATUS.includes(data.status) && !params.isClient) {
      requestGetFolderCompletion({
        id: data.id,
      });
    }
  }),
);

export const requestGetFolderCompletion = createEffect({
  handler: restDetailsHandler<CompletionResponse, void>(FOLDERS.COMPLETION),
});

requestGetFolderCompletion.done.watch(({ result }) =>
  handleParsedResponse(result)((data: CompletionResponse) =>
    setFolderCompletion(data),
  ),
);

export const requestUpdateFolderStatus = createEffect({
  handler: restPatchHandler<FolderStatusFrontUpdateRequest, FolderResponse>(
    FOLDERS.STATUS,
  ),
});

export const updateFolderStatus = (
  folderStore: TypeFolderStore,
  status: FolderStatusFrontUpdateEnum,
  setLoading: (loading: boolean) => void,
): void => {
  setLoading(true);
  requestUpdateFolderStatus({
    id: folderStore.id,
    dto: { status },
  })
    .then((response: ParsedResponse<FolderResponse>) => {
      handleParsedResponse(response)(
        (data: FolderResponse) => {
          clearDocuments();
          setFolder({ ...folderStore, ...data });
          requestGetFolderCompletion({
            id: data.id,
          });
        },
        () => {
          setLoading(false);
        },
      );
    })
    .catch(() => setLoading(false));
};

export const requestImportFolderStatus = createEffect({
  handler: restCreationHandler<FormData, void, FolderStatusImportResponse>(
    FOLDERS.IMPORT_STATUS,
    CONTENT_TYPE.FORM_DATA,
  ),
});
