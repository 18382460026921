import React from 'react';
import { Form } from 'react-bootstrap';

interface TypeOption {
  value: string;
  label: string;
}

type Props = {
  options: TypeOption[];
  className?: string;
  value?: string;
  onChange: (e: { target: HTMLSelectElement }) => void;
  disabled?: boolean;
};

const BmwSelect = (props: Props) => {
  const {
    options,
    value = '',
    className = '',
    onChange,
    disabled = false,
  } = props;
  return (
    <Form.Control
      onChange={(e) => onChange(e as { target: HTMLSelectElement })}
      value={value}
      as="select"
      custom
      className={className}
      disabled={disabled}
    >
      {options.map((option: TypeOption) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Form.Control>
  );
};

export default BmwSelect;
