import { createStore } from 'effector';
import { clearFolder, setFolder, setFolderCompletion } from './FolderEvents';
import moment from 'moment';
import { FolderStatusEnum } from '../../types/model/folderStatusEnum';
import { TypeFolderStore } from '../../types/storeTypes';
import { CompletionResponse } from '../../types/model/completionResponse';

const defaultState: TypeFolderStore = {
  id: '',
  dealerId: '',
  dealershipId: '',
  dealershipName: '',
  kycFolderId: undefined,
  financialProduct: '',
  category: undefined,
  vehicleBrand: '',
  vehicleModel: '',
  onePDF: false,
  status: FolderStatusEnum.STUDYSUPPLYDOCUMENT,
  mailingList: '',
  creationDate: moment().toDate(),
  creationUser: '',
  modificationDate: moment().toDate(),
  modificationUser: '',
  lastPaymentDate: moment().toDate(),
  studyLastMedocSent: moment().toDate(),
  paymentLastMedocSent: moment().toDate(),
  endDate: moment().toDate(),
  nbDocumentTotal: 0,
  nbDocumentToUpload: 0,
  nbDocumentValid: 0,
  hasFeedbackToSubmit: false,
  isMissingRefusalId: false,
  completed: false,
};

export const FolderStore = createStore<TypeFolderStore>(defaultState)
  .on(setFolder, (state: TypeFolderStore, payload: TypeFolderStore) => payload)
  .on(
    setFolderCompletion,
    (state: TypeFolderStore, payload: CompletionResponse) => ({
      ...state,
      ...payload,
      completed: payload.nbDocumentValid === payload.nbDocumentTotal,
    }),
  )
  .on(clearFolder, () => defaultState);
