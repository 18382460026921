import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import BmwButton from './BmwButton';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick?: (value: boolean) => void;
  selected?: boolean;
  firstLabel?: string;
  secondLabel?: string;
  className?: string;
  disabled?: boolean;
};

const BmwButtonGroup = (props: Props) => {
  const { t } = useTranslation();
  const {
    selected,
    firstLabel = t('actions.yes'),
    secondLabel = t('actions.no'),
    onClick,
    className = '',
    disabled,
  } = props;
  return (
    <ButtonGroup className={className}>
      <BmwButton
        disabled={disabled}
        type={selected ? 'primary' : 'tertiary'}
        label={firstLabel}
        onClick={() => onClick && onClick(true)}
      />
      <BmwButton
        disabled={disabled}
        type={selected === false ? 'primary' : 'tertiary'}
        label={secondLabel}
        onClick={() => onClick && onClick(false)}
      />
    </ButtonGroup>
  );
};

export default BmwButtonGroup;
