import React from 'react';
import PublicDisplay from '../../components/public/PublicDisplay';

type Props = {
  path: string;
};

const LegalMention = (props: Props) => (
  <PublicDisplay name="legalMention" hasLink={[]} numberOfBloc={10} />
);

export default LegalMention;
