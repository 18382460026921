import React, { useEffect, useState } from 'react';
import FileItem from '../../../components/folder/study/FileItem';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from 'react-bootstrap';
import BmwSelect from '../../../components/form/BmwSelect';
import BmwBloc from '../../../components/layout/BmwBloc';
import { STATUS, STUDY_SENDING_STATUS } from '../../../utils/constants';
import BmwAlert from '../../../components/BmwAlert';
import { FolderStore } from '../../../state/folder/FolderStore';
import { useStore } from 'effector-react';
import BmwButton from '../../../components/form/BmwButton';
import { TypeDocument } from '../../../types/globalTypes';
import { DocumentsStore } from '../../../state/documents/DocumentsStore';
import { requestGetDocumentFolder } from '../../../state/documents/DocumentsEffects';
import { restPatchHandler } from '../../../rest/RestClient';
import { handleParsedResponse } from '../../../utils/utils';
import { RefusalStore } from '../../../state/refusal/RefusalStore';
import { RefusalItemResponse } from '../../../types/model/refusalItemResponse';
import { TypeFolderStore } from '../../../types/storeTypes';
import { DOCUMENTS, FOLDERS } from '../../../rest/apiPath';
import { FolderResponse } from '../../../types/model/folderResponse';
import { ParsedResponse } from '../../../rest/ServerResponseParse';
import { setFolder } from '../../../state/folder/FolderEvents';
import ButtonLaunchControl from '../../../components/folder/study/ButtonLaunchControl';
import { successToast } from '../../../utils/toast';

const ControlOneFile = () => {
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const documentStore = useStore<TypeDocument[]>(DocumentsStore);
  const refusalStore = useStore<RefusalItemResponse[]>(RefusalStore);
  const [selected, setSelected] = useState(false as boolean);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const disabled = STUDY_SENDING_STATUS.includes(folderStore.status);
  const isInvalidControlUpdate =
    (folderStore.hasFeedbackToSubmit && folderStore.isMissingRefusalId) ||
    !folderStore.hasFeedbackToSubmit;
  const file = documentStore[0];

  useEffect(() => {
    requestGetDocumentFolder({
      id: folderStore.id,
    });
  }, [folderStore.id]);

  useEffect(() => {
    setSelected(file?.rejected);
  }, [file]);

  const refuse = (id?: string) => {
    restPatchHandler(DOCUMENTS.UPDATE_REFUSAL)({
      id: file.id,
      dto: {
        refusalId: selected && !id ? null : id || '',
      },
    }).then((result) =>
      handleParsedResponse(result)(
        () => updateDocument(),
        () => updateDocument(),
      ),
    );
  };

  const updateDocument = () => {
    requestGetDocumentFolder({
      id: file.folderId || '',
    }).finally(() => setLoading(false));
  };

  const onClick = (): void => {
    restPatchHandler<null, FolderResponse>(FOLDERS.FEEDBACK_SUBMIT)({
      id: folderStore.id,
      dto: null,
    }).then((result: ParsedResponse<FolderResponse>) =>
      handleParsedResponse(result)((data: FolderResponse) => {
        successToast(t);
        setFolder({ ...folderStore, ...data });
      }),
    );
  };

  return (
    <>
      {disabled && (
        <BmwAlert
          type="info"
          label={t(`folder.alert.${STATUS.STUDY_LAUNCH_DOC_CONTROL}`)}
        />
      )}
      <div className="mb-5">
        <BmwBloc
          title={t('folder.oneFile.title')}
          description={t('folder.oneFile.warning')}
        >
          <div className="btn-toolbar">
            {file && (
              <FileItem
                loading={loading}
                disabled={disabled}
                files={file.files}
                fileId={file.id}
                subtypeId={file.subtype?.id}
                isUploaded={file.files.length > 0}
                error={selected}
                className="custom-file-btn"
                title={
                  file.files.length > 0
                    ? file.files[0].name
                    : t('folder.oneFile.title')
                }
                isOnePdf={true}
                fileIndex={0}
              />
            )}
            <>
              <ButtonGroup className="ml-3">
                <BmwButton
                  disabled={disabled}
                  loading={loading}
                  className={selected ? 'selected' : ''}
                  type="tertiary"
                  onClick={() => {
                    setLoading(true);
                    refuse(selected ? undefined : '0');
                  }}
                  label={t('actions.refuse')}
                />
              </ButtonGroup>
              {selected && (
                <BmwSelect
                  disabled={disabled || loading}
                  onChange={(e) => refuse(e.target.value)}
                  className="ml-3 flex-auto"
                  options={[
                    { value: '0', label: 'Sélectionnez un motif' },
                  ].concat(
                    refusalStore.map((refusal) => ({
                      value: refusal.id,
                      label: refusal.reason,
                    })),
                  )}
                  value={file && file.refusal && file.refusal?.id}
                />
              )}
            </>
          </div>
        </BmwBloc>
      </div>
      <div className="d-flex justify-content-end">
        {folderStore.status === STATUS.STUDY_SEND_CONTROL && (
          <BmwButton
            onClick={onClick}
            disabled={disabled || isInvalidControlUpdate}
            label={t('folder.validateControl')}
          />
        )}
        {folderStore.status === STATUS.STUDY_RETAKE_SUPPLY && (
          <ButtonLaunchControl disabled={!folderStore.completed} />
        )}
      </div>
    </>
  );
};

export default ControlOneFile;
