import React from 'react';
import Shortcut from './Shortcut';

type Props = {
  count: number;
  onClick: () => void;
  disabled: boolean;
  selected: boolean;
};

const Shortcuts = (props: Props) => {
  const { count, onClick, disabled, selected } = props;
  return (
    <div className="shortcuts d-flex flex-row flex-wrap align-items-stretch justify-content-between pt-1 pb-4 mb-1 multiple-5">
      <Shortcut
        selected={selected}
        disabled={disabled}
        onClick={onClick}
        icon="fa fa-folder"
        count={count}
        label="Dossiers en attente d'action"
      />
    </div>
  );
};

export default Shortcuts;
