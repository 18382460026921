import React, { useEffect, useState } from 'react';
import BmwBloc from '../../../components/layout/BmwBloc';
import { useTranslation } from 'react-i18next';
import FileItem from '../../../components/folder/study/FileItem';
import { useStore } from 'effector-react';
import { FolderStore } from '../../../state/folder/FolderStore';
import { STATUS } from '../../../utils/constants';
import BmwAlert from '../../../components/BmwAlert';
import { TypeFolderStore, TypeUserStore } from '../../../types/storeTypes';
import { TypeDocument } from '../../../types/globalTypes';
import { DocumentsStore } from '../../../state/documents/DocumentsStore';
import { requestGetDocumentFolder } from '../../../state/documents/DocumentsEffects';
import ButtonLaunchControl from '../../../components/folder/study/ButtonLaunchControl';
import { UserStore } from '../../../state/user/UserStore';
import { isDealer } from '../../../utils/rights';

const StudyOneFile = () => {
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const userStore = useStore<TypeUserStore>(UserStore);
  const documentStore = useStore<TypeDocument[]>(DocumentsStore);
  const { t } = useTranslation();
  const disabled = folderStore.status !== STATUS.STUDY_SUPPLY_DOCUMENT;
  const file = documentStore[0];
  const [loading] = useState<boolean>(false);

  useEffect(() => {
    requestGetDocumentFolder({
      id: folderStore.id,
    });
  }, [folderStore.id]);

  return (
    <>
      {disabled && (
        <BmwAlert
          type="info"
          label={t(
            `folder.alert.${
              folderStore.status === STATUS.STUDY_SEND_CONTROL
                ? STATUS.STUDY_SEND_CONTROL
                : STATUS.STUDY_LAUNCH_DOC_CONTROL
            }`,
          )}
        />
      )}
      <BmwBloc
        title={t('folder.oneFile.title')}
        description={t('folder.oneFile.warning')}
      >
        {file && (
          <FileItem
            loading={loading}
            disabled={disabled}
            files={file.files}
            fileId={file.id}
            subtypeId={file.subtype?.id}
            isUploaded={file.files.length > 0}
            className="custom-file-btn"
            title={
              file.files.length > 0
                ? file.files[0].name
                : t('folder.oneFile.title')
            }
            isOnePdf={true}
            fileIndex={0}
          />
        )}
      </BmwBloc>
      {folderStore.status === STATUS.STUDY_SUPPLY_DOCUMENT &&
        isDealer(userStore.profiles) && (
          <ButtonLaunchControl disabled={!folderStore.completed} />
        )}
    </>
  );
};

export default StudyOneFile;
