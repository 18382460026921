import React from 'react';

type Props = {
  background?: boolean;
  global?: boolean;
  opaqueBackground?: boolean;
};

const BmwLoader = (props: Props) => {
  const { background, global, opaqueBackground } = props;

  const Loader = () => (
    <div className="loader d-flex m-auto">
      <i
        style={{ fontSize: '2rem' }}
        className="fa fa-spinner fa-spin mr-auto ml-auto"
      />
    </div>
  );

  if (opaqueBackground) {
    return (
      <div className="loader-opaque-background">
        <Loader />
      </div>
    );
  } else if (background) {
    return (
      <div className="loader-background">
        <Loader />
      </div>
    );
  } else if (global) {
    return (
      <div className="loader-global">
        <Loader />
      </div>
    );
  }
  return <Loader />;
};

export default BmwLoader;
