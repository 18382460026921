import { createEffect } from 'effector';
import {
  CONTENT_TYPE,
  restWithoutAuthHandler,
  restWithoutAuthPostHandler,
} from '../../rest/RestClient';
import { handleParsedResponse } from '../../utils/utils';
import { clearUser, setUser } from './UserEvents';
import { USERS } from '../../rest/apiPath';
import { MeInformationResponse } from '../../types/model/meInformationResponse';
import { ClientAuthenticationRequest } from '../../types/model/clientAuthenticationRequest';
import { ClientAuthenticationResponse } from '../../types/model/clientAuthenticationResponse';
import { TestLoginRequest } from '../../types/model/testLoginRequest';
import { TestLoginResponse } from '../../types/model/testLoginResponse';

export const requestGetUser = createEffect({
  handler: restWithoutAuthHandler<MeInformationResponse>(USERS.GET),
});

export const requestPostLogin = createEffect({
  handler: restWithoutAuthPostHandler<
    ClientAuthenticationRequest,
    ClientAuthenticationResponse
  >(USERS.AUTHENTICATE, CONTENT_TYPE.JSON),
});

export const requestPostLogout = createEffect({
  handler: restWithoutAuthPostHandler<{}, {}>(USERS.LOGOUT, CONTENT_TYPE.JSON),
});

export const requestPostTestLogin = createEffect({
  handler: restWithoutAuthPostHandler<TestLoginRequest, TestLoginResponse>(
    USERS.TEST_LOGIN,
    CONTENT_TYPE.JSON,
  ),
});

requestGetUser.done.watch(({ result }) =>
  handleParsedResponse(result)((data: MeInformationResponse) => setUser(data)),
);

requestPostLogout.done.watch(({ result }) =>
  handleParsedResponse(result)(() => clearUser()),
);
