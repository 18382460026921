import { BRAND, COOKIES } from './constants';
import { CLIENT_TYPE } from './rights';
import { TypeBrand, TypeClientType } from '../types/globalTypes';
import { TypeProfile } from '../types/rightTypes';
import { getCookie } from './utils';

// BMW | MINI | MOTORRAD
export const CONF_BRAND = BRAND.BMW as TypeBrand;
// COMMERCIAL | OPERATOR | CLIENT
export const CONF_PROFILE = [getCookie(COOKIES.PROFILES)] as TypeProfile[];
// MAIN | SECONDARY | TERTIARY
export const CONF_CLIENT_TYPE = CLIENT_TYPE.PRIMARY as TypeClientType;

export const CONF_REPORTING_PAGE_SIZE: number = 10;
