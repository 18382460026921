import React, { useEffect, useState } from 'react';
import Header from './layout/Header';
import { Link, navigate, Router } from '@reach/router';
import Dashboard from './pages/Dashboard';
import {
  PUBLIC_ROUTES,
  REDIRECT_ROUTES,
  REPORTING_ROUTES,
  ROUTE_BACK_OFFICE,
  ROUTE_COOKIE,
  ROUTE_DASHBOARD,
  ROUTE_FOLDER,
  ROUTE_TERMS,
  ROUTE_LEGAL_MENTION,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_NOT_FOUND,
  ROUTE_PERSONAL_DATA,
  ROUTE_PREFIX,
  ROUTE_REPORTING,
  ROUTE_REPORTING_AGENCY,
  ROUTE_REPORTING_CURRENT_FOLDER,
  ROUTE_REPORTING_DEALERSHIP,
  ROUTE_REPORTING_FOLDER,
  ROUTE_TEST_LOGIN,
  ROUTE_REPORTING_IMPORT_FOLDER_STATUS,
} from './utils/routes';
import Folder from './pages/folder/Folder';
import Guidance from './pages/folder/Guidance';
import { useStore } from 'effector-react';
import { UserStore } from './state/user/UserStore';
import 'core-js';
import cssVars from 'css-vars-ponyfill';
import { HeaderStore } from './state/header/HeaderStore';
import { BRAND, LOCAL_STORAGE } from './utils/constants';
import BackOffice from './pages/BackOffice';
import Footer from './layout/Footer';
import PersonalData from './pages/public/PersonalData';
import Cookie from './pages/public/Cookie';
import BmwBanner from './components/BmwBanner';
import { useTranslation } from 'react-i18next';
import Login from './pages/Login';
import BmwToaster from './components/BmwToaster';
import { TypeHeaderStore, TypeUserStore } from './types/storeTypes';
import DossierView from './pages/reporting/DossierView';
import ConcessionView from './pages/reporting/ConcessionView';
import AgenceView from './pages/reporting/AgenceView';
import { hasAccessToFolder, PROFILES } from './utils/rights';
import { getUserInformation } from './services/loginService';
import Logout from './pages/Logout';
import BmwLoader from './components/BmwLoader';
import Reporting from './pages/reporting/Reporting';
import { MeInformationResponse } from './types/model/meInformationResponse';
import HtmlParser from 'react-html-parser';
import { TypeProfile } from './types/rightTypes';
import TestLogin from './pages/TestLogin';
import NotFound from './pages/error/NotFound';
import LegalMention from './pages/public/LegalMention';
import CurrentFoldersView from './pages/reporting/CurrentFoldersView';
import FolderStatusImportScreen from './pages/FolderStatusImportScreen';

const App = () => {
  const [banner, setBanner] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const userStore = useStore<TypeUserStore>(UserStore);
  const headerStore = useStore<TypeHeaderStore>(HeaderStore);
  const { t } = useTranslation();

  // USE EFFECTS
  useEffect(() => {
    const pathname = window.location.pathname;
    setLoading(true);
    getUserInformation(userStore).then((me?: MeInformationResponse) => {
      setLoading(false);
      const profiles = (me?.profiles || []) as TypeProfile[];
      if (
        !hasAccessToFolder(profiles) &&
        profiles.includes(PROFILES.BACK_OFFICE_REPORTING) &&
        !REPORTING_ROUTES.includes(pathname) &&
        !PUBLIC_ROUTES.includes(pathname)
      ) {
        navigate(ROUTE_REPORTING);
      } else if (
        !hasAccessToFolder(profiles) &&
        profiles.includes(PROFILES.BACK_OFFICE_LABEL) &&
        ROUTE_BACK_OFFICE !== pathname &&
        !PUBLIC_ROUTES.includes(pathname)
      ) {
        navigate(ROUTE_BACK_OFFICE);
      }
    });
    if (REDIRECT_ROUTES.includes(pathname)) {
      navigate(ROUTE_PREFIX);
    }

    // Cookie management
    const cookie = window.localStorage.getItem(LOCAL_STORAGE.COOKIE);
    if (!cookie) {
      setBanner(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const brandStyleV1 = document.getElementById('brand-style-v1');
    const link = document.createElement('link');
    if (brandStyleV1) {
      link.id = 'brand-style-v2';
    } else {
      link.id = 'brand-style-v1';
    }
    link.rel = 'stylesheet';
    switch (headerStore.brand) {
      case BRAND.BMW:
        link.href = '/static/css/styles-v2.css';
        break;
      case BRAND.ALPHERA:
        link.href = '/static/css/styles-alphera-v2.css';
        break;
      case BRAND.BMW_MOTORRAD:
        link.href = '/static/css/styles-motorrad-v2.css';
        break;
      case BRAND.MINI:
        link.href = '/static/css/styles-mini-v2.css';
        break;
      default:
        break;
    }
    document.head.appendChild(link);
    if (brandStyleV1) {
      document.head.removeChild(brandStyleV1);
    } else {
      const brandStyleV2 = document.getElementById('brand-style-v2');
      if (brandStyleV2) {
        document.head.removeChild(brandStyleV2);
      }
    }
    cssVars({ onlyLegacy: false });
  }, [headerStore.brand]);

  // FUNCTIONS
  const closeBanner = () => {
    setBanner(false);
    window.localStorage.setItem(LOCAL_STORAGE.COOKIE, 'true');
  };

  return (
    <>
      {loading && <BmwLoader global />}
      <div className="global no-focus-outline">
        <div className="container-no-side-menu">
          <Header brand={headerStore.brand} />
          {banner && (
            <BmwBanner
              onClose={closeBanner}
              label={
                <>
                  {HtmlParser(t('cookie.banner'))}
                  <Link to={ROUTE_COOKIE} onClick={closeBanner}>
                    {t('cookie.link')}
                  </Link>
                </>
              }
            />
          )}
          <main className="container-content d-flex flex-column">
            <BmwToaster />
            <Router>
              {hasAccessToFolder(userStore.profiles) &&
                !userStore.profiles.includes(PROFILES.CLIENT) && (
                  <Dashboard path={ROUTE_DASHBOARD} />
                )}
              {userStore.profiles.includes(PROFILES.BACK_OFFICE_LABEL) && (
                <BackOffice path={ROUTE_BACK_OFFICE} />
              )}
              {hasAccessToFolder(userStore.profiles) && (
                <Folder path={ROUTE_FOLDER} />
              )}
              <Guidance path={ROUTE_TERMS} />
              <LegalMention path={ROUTE_LEGAL_MENTION} />
              <PersonalData path={ROUTE_PERSONAL_DATA} />
              <Cookie path={ROUTE_COOKIE} />
              <Login path={ROUTE_LOGIN} />
              <Logout path={ROUTE_LOGOUT} />
              <TestLogin path={ROUTE_TEST_LOGIN} />
              {userStore.profiles.includes('BACK_OFFICE_REPORTING') && (
                <>
                  <Reporting path={ROUTE_REPORTING} />
                  <AgenceView path={ROUTE_REPORTING_AGENCY} />
                  <ConcessionView path={ROUTE_REPORTING_DEALERSHIP} />
                  <DossierView path={ROUTE_REPORTING_FOLDER} />
                  <CurrentFoldersView path={ROUTE_REPORTING_CURRENT_FOLDER} />
                  <FolderStatusImportScreen
                    path={ROUTE_REPORTING_IMPORT_FOLDER_STATUS}
                  />
                </>
              )}
              <NotFound path={ROUTE_NOT_FOUND} default />
            </Router>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default App;
