import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { FolderStore } from '../../../state/folder/FolderStore';
import ControlOneFile from './ControlOneFile';
import Control from './Control';
import { DocumentThresholdResponse } from '../../../types/model/documentThresholdResponse';
import { restDetailsHandler } from '../../../rest/RestClient';
import { handleParsedResponse } from '../../../utils/utils';
import { DOCUMENTS } from '../../../rest/apiPath';
import { TypeFolderStore, TypeUserStore } from '../../../types/storeTypes';
import { UserStore } from '../../../state/user/UserStore';
import { isOperator } from '../../../utils/rights';

const ControlOperator = () => {
  const [thresholds, setThresholds] = useState<DocumentThresholdResponse>();
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const userStore = useStore<TypeUserStore>(UserStore);

  useEffect(() => {
    if (isOperator(userStore.profiles)) {
      restDetailsHandler<void, void>(DOCUMENTS.THRESHOLDS)({
        id: '',
      }).then((result) =>
        handleParsedResponse(result)((data: DocumentThresholdResponse) =>
          setThresholds(data),
        ),
      );
    }
  }, [userStore.profiles]);

  const good = thresholds?.good || 0;
  const average = thresholds?.average || 0;
  return (
    <>
      {folderStore.onePDF ? (
        <ControlOneFile />
      ) : (
        <Control
          isError={(file) =>
            ((!!file.rating || file.rating === 0) && file.rating < average) ||
            !file.uploaded ||
            file.rejected
          }
          isWarning={(file) =>
            !!file.rating &&
            file.rating < good &&
            file.rating >= average &&
            !file.rejected
          }
          isValid={(file) =>
            file.uploaded &&
            !file.rejected &&
            ((!!file.rating && file.rating >= good) ||
              (!file.rating && file.rating !== 0))
          }
        />
      )}
    </>
  );
};

export default ControlOperator;
