import StudyOneFile from './StudyOneFile';
import Study from './Study';
import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { FolderStore } from '../../../state/folder/FolderStore';
import { TypeFolderStore, TypeUserStore } from '../../../types/storeTypes';
import { STATUS, STUDY_SENDING_STATUS } from '../../../utils/constants';
import BmwButton from '../../../components/form/BmwButton';
import { useTranslation } from 'react-i18next';
import { restPatchHandler } from '../../../rest/RestClient';
import { FolderResponse } from '../../../types/model/folderResponse';
import { FOLDERS } from '../../../rest/apiPath';
import { ParsedResponse } from '../../../rest/ServerResponseParse';
import { handleParsedResponse } from '../../../utils/utils';
import { successToast } from '../../../utils/toast';
import { setFolder } from '../../../state/folder/FolderEvents';
import { UserStore } from '../../../state/user/UserStore';
import { PROFILES } from '../../../utils/rights';
import { TypeActor } from '../../../types/globalTypes';
import { ActorStore } from '../../../state/actor/ActorStore';

const StudyCommercial = () => {
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const { t } = useTranslation();
  const userStore = useStore<TypeUserStore>(UserStore);
  const actorStore = useStore<TypeActor[]>(ActorStore);

  const [isInvalidControlUpdate, setInvalidControlUpdate] =
    useState<boolean>(true);

  useEffect(() => {
    const val =
      actorStore.some(
        (actor) => actor.hasFeedbackToSubmit && actor.isMissingRefusalId,
      ) ||
      (folderStore.hasFeedbackToSubmit && folderStore.isMissingRefusalId) ||
      (!actorStore.some((actor) => actor.hasFeedbackToSubmit) &&
        !folderStore.hasFeedbackToSubmit);
    setInvalidControlUpdate(val);
  }, [actorStore, folderStore, isInvalidControlUpdate]);

  const processCallback = (): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onClick = (): void => {
    restPatchHandler<null, FolderResponse>(FOLDERS.FEEDBACK_SUBMIT)({
      id: folderStore.id,
      dto: null,
    })
      .then((result: ParsedResponse<FolderResponse>) =>
        handleParsedResponse(result)((data: FolderResponse) => {
          successToast(t);
          setFolder({ ...folderStore, ...data });
        }),
      )
      .then(processCallback);
  };

  const disabled = STUDY_SENDING_STATUS.includes(folderStore.status);

  return (
    <>
      {folderStore.onePDF ? (
        <StudyOneFile />
      ) : (
        <>
          <Study isInvalid={(file) => !file.uploaded} />
          {userStore.profiles.includes(PROFILES.UNRESTRICTED_DEALER) &&
            folderStore.status === STATUS.STUDY_SEND_CONTROL && (
              <div className="d-flex justify-content-end">
                <BmwButton
                  onClick={onClick}
                  disabled={disabled || isInvalidControlUpdate}
                  label={t('folder.validateControl')}
                />
              </div>
            )}
        </>
      )}
    </>
  );
};

export default StudyCommercial;
