import React, { ChangeEvent, useEffect, useState } from 'react';
import { TestLoginProfileEnum } from '../types/model/testLoginProfileEnum';
import BmwButton from '../components/form/BmwButton';
import { testLogin } from '../services/loginService';
import { TestLoginProfileEnumType } from '../types/globalTypes';
import BmwInput from '../components/form/BmwInput';

const localStorageItem = {
  EMAIL: 'email',
  DEALERSHIP_SGATE_IDS: 'dealershipSgateIds',
};

type Props = { path: string };

const TestLogin = (props: Props) => {
  const [profiles, setProfiles] = useState<TestLoginProfileEnum[]>([]);
  const [dealershipSgateIds, setDealershipSgateIds] = useState<string[]>(['']);
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    testLogin([], '', [], true).then((shouldInitialize: boolean) => {
      if (shouldInitialize) {
        if (!window.localStorage.getItem(localStorageItem.EMAIL)) {
          window.localStorage.setItem(localStorageItem.EMAIL, 'test@mail.fr');
        }
        if (
          !window.localStorage.getItem(localStorageItem.DEALERSHIP_SGATE_IDS)
        ) {
          window.localStorage.setItem(
            localStorageItem.DEALERSHIP_SGATE_IDS,
            '1;2;3',
          );
        }
      }
    });
  }, []);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as TestLoginProfileEnum;
    toggleProfileChange(value);
  };

  const toggleProfileChange = (value: TestLoginProfileEnum) => {
    if (profiles.includes(value)) {
      setProfiles((prevState) =>
        prevState.filter(
          (item) =>
            item !== value &&
            (item !== 'LEVEL_2' || value !== 'STUDY_OPERATOR'),
        ),
      );
    } else {
      setProfiles((prevState) =>
        value === TestLoginProfileEnum.LEVEL_2
          ? [...prevState, value, 'STUDY_OPERATOR']
          : [...prevState, value],
      );
    }
  };

  const onDealershipSgateIdsListChange = (key: number, value: string): void => {
    setDealershipSgateIds((prevState) =>
      prevState.map((id: string, index: number) =>
        index === key ? value : id,
      ),
    );
  };

  const autoFill = (profile: TestLoginProfileEnum): void => {
    if (profile === TestLoginProfileEnum.LEVEL_2) {
      setProfiles([profile, 'STUDY_OPERATOR']);
    } else {
      setProfiles([profile]);
    }
    setEmail(window.localStorage.getItem(localStorageItem.EMAIL) || '');
    setDealershipSgateIds(
      window.localStorage
        .getItem(localStorageItem.DEALERSHIP_SGATE_IDS)
        ?.split(';') || [''],
    );
  };

  return (
    <div id="test-login" className="content-body bg-login px-2 px-sm-0">
      <div className="custom-card p-4 d-flex flex-column align-items-center">
        <div className="header text-center">
          <h3>Connexion</h3>
          <p className="text-center">
            Système de connexion afin de faciliter la recette de l'application.
            <br />
            <i>
              Afin de configurer le remplissage automatique, il faut modifier
              <br /> le local storage en suivant l'exemple déjà présent.
            </i>
          </p>
        </div>
        <div className="d-flex flex-column flex-md-row">
          <div className="d-flex flex-column align-items-center">
            <ul className="cursor-pointer p-0">
              {(
                Object.keys(TestLoginProfileEnum) as TestLoginProfileEnumType[]
              ).map((key: TestLoginProfileEnumType) => {
                const value = TestLoginProfileEnum[key];
                return (
                  <div key={value} className="d-flex align-items-center">
                    <button onClick={() => autoFill(value)}>
                      Remplissage auto.
                    </button>
                    <li
                      style={{ listStyle: 'none' }}
                      className="m-3"
                      onClick={() => toggleProfileChange(value)}
                    >
                      <input
                        type="checkbox"
                        className="mr-2 cursor-pointer"
                        onChange={onChange}
                        value={value}
                        checked={profiles.includes(value)}
                      />
                      {value}
                    </li>
                  </div>
                );
              })}
            </ul>
          </div>
          <div className="d-flex flex-column ml-3 justify-content-between">
            <div>
              <label className="align-self-start">Email</label>
              <BmwInput
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <label className="align-self-start mt-3">
                Dealership sgate ids
              </label>
              {dealershipSgateIds.map(
                (dealershipSgateId: string, index: number) => (
                  <BmwInput
                    key={`input_${index}`}
                    className="mb-2"
                    onChange={(e) =>
                      onDealershipSgateIdsListChange(index, e.target.value)
                    }
                    value={dealershipSgateId}
                  />
                ),
              )}
              <button
                className="align-self-start"
                onClick={() =>
                  setDealershipSgateIds((prevState) => [...prevState, ''])
                }
              >
                <i className="fa fa-plus" /> Ajouter
              </button>
            </div>
            <BmwButton
              className="mt-3"
              label="Se connecter"
              onClick={() =>
                testLogin(
                  profiles,
                  email,
                  dealershipSgateIds.filter((value) => !!value),
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestLogin;
