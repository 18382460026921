/**
 * Sofiadoc back end API.
 * API endpoints for front application.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: bmwfinance-e-kyc@reeliant.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type DocumentTargetEnum =
  | 'ACTOR_OR_FOLDER'
  | 'LODGER'
  | 'CORPORATION'
  | 'SIGNATORY';

export const DocumentTargetEnum = {
  ACTORORFOLDER: 'ACTOR_OR_FOLDER' as DocumentTargetEnum,
  LODGER: 'LODGER' as DocumentTargetEnum,
  CORPORATION: 'CORPORATION' as DocumentTargetEnum,
  SIGNATORY: 'SIGNATORY' as DocumentTargetEnum,
};
