import { TypeUploadDocument } from '../../types/storeTypes';
import {
  CONTENT_TYPE,
  makeRestCall,
  restCreationHandlerNoContent,
  restPatchHandler,
} from '../../rest/RestClient';
import { TypeFileUploaded } from '../../types/globalTypes';
import { DocumentInformationUpdateRequest } from '../../types/model/documentInformationUpdateRequest';
import { DocumentResponse } from '../../types/model/documentResponse';
import { handleParsedResponse } from '../../utils/utils';
import { addToast } from '../toast/ToastEvents';
import i18next from 'i18next';
import { requestGetActorCompletion } from '../actor/ActorEffects';
import {
  requestGetDocumentActor,
  requestGetDocumentFolder,
} from '../documents/DocumentsEffects';
import { requestGetFolderCompletion } from '../folder/FolderEffects';
import { DOCUMENTS } from '../../rest/apiPath';
import { CompletionResponse } from '../../types/model/completionResponse';
import { ParsedResponse } from '../../rest/ServerResponseParse';
import { toggleModal } from '../modal/ModalEvents';
import { MODAL_KEYS } from '../../utils/constants';

export const requestUploadFile = (
  value: TypeUploadDocument,
): Promise<void | null> => {
  const formData = new FormData();
  formData.append(
    'fileIndex',
    (value.file as TypeFileUploaded).fileIndex.toString(),
  );
  formData.append('file', (value.file as TypeFileUploaded).file);
  return restCreationHandlerNoContent<FormData, null>(
    DOCUMENTS.UPLOAD,
    CONTENT_TYPE.FORM_DATA,
  )(formData, value.id).then((response) =>
    handleParsedResponse(response)(() => {
      return null;
    }),
  );
};

export const requestRemoveFile = (
  documentId: string,
  fileIndex: number,
): Promise<void> => {
  return makeRestCall(
    `${DOCUMENTS.UPLOAD}?fileIndex=${fileIndex}`.replace('{id}', documentId),
    'DELETE',
  ).then((response) => {
    if (response.responseCode === 200) {
      addToast({
        type: 'success',
        title: i18next.t('toast.update.title'),
        message: i18next.t('toast.update.message'),
      });
      return Promise.resolve();
    } else {
      addToast({
        title: i18next.t('toast.file.deleteLast.title'),
        message: i18next.t('toast.file.deleteLast.message'),
      });
      return Promise.reject();
    }
  });
};

export const requestUploadInfo = (
  value: TypeUploadDocument & {
    isClient: boolean;
    isFromUnbind?: boolean;
  },
): Promise<void> => {
  if (value.subtypeId) {
    return restPatchHandler<DocumentInformationUpdateRequest, DocumentResponse>(
      DOCUMENTS.UPDATE,
    )({
      id: value.id,
      dto: {
        subtypeId: value.subtypeId,
        ownerActorId: value.ownerId,
        isFromUnbind: value.isFromUnbind,
      },
    }).then((response) =>
      handleParsedResponse(response)(async (data: DocumentResponse) => {
        if (data.actorId) {
          await requestGetDocumentActor({
            id: data.actorId,
          });
          requestGetActorCompletion({
            id: data.actorId,
          }).then((res: ParsedResponse<CompletionResponse>) => {
            if (
              value.isClient &&
              res.data?.nbDocumentTotal === res.data?.nbDocumentValid
            ) {
              toggleModal(MODAL_KEYS.clientUploadComplete);
            }
          });
        } else if (data.folderId) {
          await requestGetDocumentFolder({
            id: data.folderId,
          });
          await requestGetFolderCompletion({
            id: data.folderId,
          });
        }
        addToast({
          type: 'success',
          title: i18next.t('toast.update.title'),
          message: i18next.t('toast.update.message'),
        });
        return;
      }),
    );
  }
  return Promise.resolve();
};
