import { ParsedResponse } from '../rest/ServerResponseParse';
import { addToast } from '../state/toast/ToastEvents';
import { DOCUMENT_SOURCE } from './constants';
import { TypeActor } from '../types/globalTypes';
import { ActorCategoryEnum } from '../types/model/actorCategoryEnum';
import React from 'react';
import { DocumentItemFileResponse } from '../types/model/documentItemFileResponse';

export const getQueryVariable = (variable: string) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return undefined;
};

export const getPathVariable = (rank: number) => {
  const query = window.location.pathname;
  const array = query.split('/');
  if (array.length - 1 < rank) {
    console.log(
      `Erreur lors de la récupération de la variable ${rank} dans ${query}`,
    );
  }
  return array[rank];
};

// digit: 1
// number: 12.365 => 12.4
// number: 12.00 => 12.0
export const formatNumber = (number: number, digit: number = 0): string => {
  return number
    .toFixed(digit)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

// digit: 1
// number: 12.365 => 12.4
// number: 12.00 => 12
export const roundNumber = (number?: number, digit: number = 0): string => {
  if (!number && number !== 0) {
    return '';
  }
  return (Math.round(number * digit * 10) / (digit * 10)).toString();
};

export const handleParsedResponse =
  (result: ParsedResponse<any>, showToast: boolean = true) =>
  (callback: (data: any) => void, errorCallback?: () => void) => {
    const unexpectedError =
      result.responseCode !== 403 && result.responseCode !== 404;
    if (result.ok) {
      return callback(result.data);
    } else {
      (showToast || unexpectedError) &&
        addToast({
          message: result.errorMessage,
        });
      errorCallback && errorCallback();
    }
  };

export const isMandatory = (source?: string) =>
  source === DOCUMENT_SOURCE.SOFIAWEB_MANDATORY ||
  source === DOCUMENT_SOURCE.PAYMENT ||
  source === DOCUMENT_SOURCE.OPERATOR_MANDATORY;

export const scrollAdapt = (id: string, delay: number = 310) =>
  setTimeout(() => {
    const element = document.querySelector(`#${id}`);
    const header = document.querySelector('header');
    if (element && header) {
      const elementRect = element.getBoundingClientRect();
      const headerRect = header.getBoundingClientRect();
      const offsetY = elementRect.y - headerRect.height - 2;
      window.scrollBy({ left: 0, top: offsetY, behavior: 'smooth' });
    }
  }, delay);

export const isMouseInside = (
  clientX: number,
  clientY: number,
  boundingClientRect: DOMRect,
): boolean => {
  const isInsideX =
    clientX >= boundingClientRect.x &&
    clientX <= boundingClientRect.x + boundingClientRect.width;
  const isInsideY =
    clientY >= boundingClientRect.y &&
    clientY <= boundingClientRect.y + boundingClientRect.height;
  return isInsideX && isInsideY;
};

export const getActorName = (actor?: TypeActor) =>
  actor !== undefined
    ? actor.category === ActorCategoryEnum.COMPANY
      ? actor.corporationName
      : `${actor.firstname} ${actor.lastname}`
    : '';

export const acceptedKeys =
  (accepted: string[]) =>
  (e: React.KeyboardEvent): void => {
    if (!accepted.includes(e.key)) {
      e.preventDefault();
    }
  };

export const setCookie = (name: string, value: string, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const getCookie = (name: string): string => {
  const cookies = document.cookie.split(';');
  if (cookies.length === 0) {
    return '';
  }
  const targetCookie = cookies.find((cookie) => {
    const data = cookie.split('=');
    return data.length > 0 && data[0] === name;
  });
  if (!targetCookie) {
    return '';
  }
  const data = targetCookie.split('=');
  if (data.length < 2) {
    return '';
  }
  return data[1];
};

export const generateArrayOfCharacters = (
  startCharacter: string,
  endCharacter: string,
): string[] => {
  const result = [];
  const start = startCharacter.charCodeAt(0);
  const end = endCharacter.charCodeAt(0);
  for (let i = start; i <= end; i++) {
    result.push(String.fromCharCode(i));
  }
  return result;
};

export const downloadFile = (filename: string, blob: Blob): void => {
  const a = document.createElement('a');
  document.body && document.body.appendChild(a);
  try {
    a.href = window.URL.createObjectURL(blob);
  } catch {
    (a as any).srcObject = blob;
  }
  a.download = filename;
  a.click();
  document.body.removeChild(a);
};

export const getTotalFileSize = (
  files: DocumentItemFileResponse[],
  excludeIndex?: number,
): number => {
  let computedFiles = files.filter((f) =>
    excludeIndex ? f.fileIndex !== excludeIndex : true,
  );
  return computedFiles.reduce((a, b) => a + b.fileSize, 0);
};
