import { createStore } from 'effector';
import { addDocuments, clearDocuments, setDocuments } from './DocumentsEvents';
import { TypeDocument } from '../../types/globalTypes';
import { DocumentTargetEnum } from '../../types/model/documentTargetEnum';

const sortDocument = (a: TypeDocument, b: TypeDocument) => {
  const specialTargets = [
    DocumentTargetEnum.LODGER,
    DocumentTargetEnum.SIGNATORY,
  ];
  if (specialTargets.includes(a.target)) {
    return specialTargets.includes(b.target) ? sortDocumentByTypeName(a, b) : 1;
  } else {
    return specialTargets.includes(b.target)
      ? -1
      : sortDocumentByTypeName(a, b);
  }
};

const sortDocumentByTypeName = (a: TypeDocument, b: TypeDocument): number => {
  return a.type.name > b.type.name ? 1 : -1;
};

export const DocumentsStore = createStore<TypeDocument[]>([])
  .on(setDocuments, (state: TypeDocument[], payload: TypeDocument[]) => {
    return [...payload].sort(sortDocument);
  })
  .on(addDocuments, (state: TypeDocument[], payload: TypeDocument[]) => {
    const newDocumentsList = state;
    payload.forEach((document) => {
      if (!state.some((d) => d.id === document.id))
        newDocumentsList.push(document);
    });
    return [...newDocumentsList].sort(sortDocument);
  })
  .on(clearDocuments, () => {
    return [];
  });
