import React from 'react';
import { useStore } from 'effector-react';
import { FolderStore } from '../../state/folder/FolderStore';
import { UserStore } from '../../state/user/UserStore';
import {
  ENDING_STATUS,
  PAYMENT_STATUS,
  STATUS,
  STUDY_SENDING_STATUS,
} from '../../utils/constants';
import { isDealer, PROFILES } from '../../utils/rights';
import StudyCommercial from './study/StudyCommercial';
import StudyOperator from './study/StudyOperator';
import StudyClient from './study/StudyClient';
import ControlOperator from './control/ControlOperator';
import RepriseCommercial from './reprise/RepriseCommercial';
import RepriseClient from './reprise/RepriseClient';
import Forbidden from '../error/Forbidden';
import { FolderResponse } from '../../types/model/folderResponse';
import { TypeUserStore } from '../../types/storeTypes';
import BmwAlert from '../../components/BmwAlert';
import { useTranslation } from 'react-i18next';
import PaymentContainer from './payment/PaymentContainer';
import { FolderStatusEnum } from '../../types/model/folderStatusEnum';

const FolderRouter = () => {
  const folderStore: FolderResponse = useStore(FolderStore);
  const userStore: TypeUserStore = useStore(UserStore);
  const { t } = useTranslation();

  if (
    ENDING_STATUS.includes(folderStore.status) &&
    folderStore.status !== FolderStatusEnum.PAYMENTFINAL
  ) {
    return (
      <BmwAlert label={t(`folder.alert.${folderStore.status}`)} type="info" />
    );
  }

  if (folderStore.status === STATUS.STUDY_SUPPLY_DOCUMENT) {
    if (userStore.profiles.includes(PROFILES.STUDY_OPERATOR)) {
      return <StudyOperator />;
    } else if (userStore.profiles.includes(PROFILES.PAYMENT_OPERATOR)) {
      //
      return <BmwAlert label={t('folder.alert.STUDY')} type="info" />;
    } else if (isDealer(userStore.profiles)) {
      return <StudyCommercial />;
    } else if (userStore.profiles.includes(PROFILES.CLIENT)) {
      return <StudyClient />;
    } else {
      return <BmwAlert label={t('folder.alert.STUDY')} type="info" />;
    }
  } else if (
    [...STUDY_SENDING_STATUS, STATUS.STUDY_SEND_CONTROL].includes(
      folderStore.status,
    )
  ) {
    if (userStore.profiles.includes(PROFILES.STUDY_OPERATOR)) {
      return <ControlOperator />;
    } else if (userStore.profiles.includes(PROFILES.PAYMENT_OPERATOR)) {
      return <BmwAlert label={t('folder.alert.STUDY')} type="info" />;
    } else if (isDealer(userStore.profiles)) {
      return <StudyCommercial />;
    } else if (userStore.profiles.includes(PROFILES.CLIENT)) {
      return <StudyClient />;
    }
  } else if (folderStore.status === STATUS.STUDY_RETAKE_SUPPLY) {
    if (userStore.profiles.includes(PROFILES.STUDY_OPERATOR)) {
      return <ControlOperator />;
    } else if (userStore.profiles.includes(PROFILES.PAYMENT_OPERATOR)) {
      return <BmwAlert label={t('folder.alert.STUDY')} type="info" />;
    } else if (isDealer(userStore.profiles)) {
      return <RepriseCommercial />;
    } else if (userStore.profiles.includes(PROFILES.CLIENT)) {
      return <RepriseClient />;
    }
  } else if (
    PAYMENT_STATUS.includes(folderStore.status) ||
    folderStore.status === FolderStatusEnum.PAYMENTFINAL
  ) {
    return <PaymentContainer />;
  }
  return <Forbidden />;
};

export default FolderRouter;
