import { createEffect } from 'effector';
import {
  restCreationHandler,
  restDetailsHandler,
  restIdListHandler,
  restPatchHandlerNoContent,
} from '../../rest/RestClient';
import { ActorItemResponse } from '../../types/model/actorItemResponse';
import { handleParsedResponse } from '../../utils/utils';
import { setActorCompletion, setActorReminder, setActors } from './ActorEvents';
import { ActorReminderUpdateRequest } from '../../types/model/actorReminderUpdateRequest';
import { CompletionResponse } from '../../types/model/completionResponse';
import { InvitationResponse } from '../../types/model/invitationResponse';
import { ACTORS, INVITATIONS } from '../../rest/apiPath';
import { ParsedResponse } from '../../rest/ServerResponseParse';

export const requestGetActors = createEffect({
  handler: restIdListHandler<ActorItemResponse>(ACTORS.GET),
});

export const requestGetActorCompletion = createEffect({
  handler: restDetailsHandler<CompletionResponse, void>(ACTORS.COMPLETION),
});

export const requestUpdateActorReminder = createEffect({
  handler: restPatchHandlerNoContent<
    ActorReminderUpdateRequest,
    ActorItemResponse
  >(ACTORS.REMINDER),
});

export const requestSendInvitation = createEffect({
  handler: restCreationHandler<
    { actorId: string },
    void,
    ParsedResponse<InvitationResponse>
  >(INVITATIONS.SEND),
});

export const requestGetLastInvitation = createEffect({
  handler: restDetailsHandler<InvitationResponse, void>(INVITATIONS.LAST),
});

requestGetActors.done.watch(({ result }) =>
  handleParsedResponse(
    result,
    false,
  )((data: ActorItemResponse[]) => {
    setActors(
      data.map((value: ActorItemResponse) => ({
        ...value,
        completed: false,
        nbDocumentTotal: 0,
        nbDocumentValid: 0,
        nbDocumentToUpload: 0,
        isMissingRefusalId: false,
        hasFeedbackToSubmit: false,
        shouldUpdateLastInvitation: true,
      })),
    );
  }),
);

requestGetActorCompletion.done.watch(({ result, params }) =>
  handleParsedResponse(
    result,
    false,
  )((data: CompletionResponse) => {
    setActorCompletion({ id: params.id, ...data });
    return data;
  }),
);

requestUpdateActorReminder.done.watch(({ result, params }) =>
  handleParsedResponse(result)(() => {
    setActorReminder(params);
  }),
);
