import { addToast } from '../state/toast/ToastEvents';
import { TFunction } from 'i18next';

export const successToast = (t: TFunction): void => {
  addToast({
    title: t('toast.success.title'),
    type: 'success',
    message: t('toast.success.message'),
  });
};
