import {
  TypeFolderAttributes,
  TypeReportingCurrentFolderAttributes,
  TypeReportingDealershipAttributes,
  TypeReportingFolderAttributes,
  TypeStatus,
} from '../types/globalTypes';
import { generateArrayOfCharacters } from './utils';
import { TypeModalStoreKey } from '../types/storeTypes';

export const BRAND = {
  BMW: 'BMW',
  ALPHERA: 'ALPHERA',
  MINI: 'MINI',
  BMW_MOTORRAD: 'BMW_MOTORRAD',
};

export const ORDER = {
  ASC: 'ASC' as 'ASC',
  DESC: 'DESC' as 'DESC',
};

export const FOLDER_ATTRIBUTES = {
  ID: 'id',
  STATUS: 'status',
  CREATION_DATE: 'creationDate',
  DEALER: 'dealer',
  CLIENT: 'client',
} as TypeFolderAttributes;

export const REPORTING_FOLDER_ATTRIBUTES = {
  FOLDER_ID: 'folderId',
  DEALERSHIP_ID: 'dealershipId',
  DEALERSHIP_NAME: 'dealershipName',
  END_DATE: 'endDate',
} as TypeReportingFolderAttributes;

export const REPORTING_CURRENT_FOLDER_ATTRIBUTES = {
  FOLDER_ID: 'folderId',
  DEALERSHIP_ID: 'dealershipId',
  DEALERSHIP_NAME: 'dealershipName',
  CREATION_DATE: 'creationDate',
  END_DATE: 'endDate',
} as TypeReportingCurrentFolderAttributes;

export const REPORTING_DEALERSHIP_ATTRIBUTES = {
  NAME: 'name',
} as TypeReportingDealershipAttributes;

export const STATUS = {
  // STUDY
  STUDY_SUPPLY_DOCUMENT: 'STUDY_SUPPLY_DOCUMENT',

  STUDY_LAUNCH_DOC_CONTROL: 'STUDY_LAUNCH_DOC_CONTROL',
  STUDY_CONTROL_DOCUMENT: 'STUDY_CONTROL_DOCUMENT',
  STUDY_ARCHIVE: 'STUDY_ARCHIVE',
  STUDY_SEND_MEDOC: 'STUDY_SEND_MEDOC',

  STUDY_SEND_CONTROL: 'STUDY_SEND_CONTROL',
  STUDY_RETAKE_SUPPLY: 'STUDY_RETAKE_SUPPLY',

  // PAYMENT
  PAYMENT_SURVEY: 'PAYMENT_SURVEY',
  PAYMENT_SUPPLY_DOCUMENT: 'PAYMENT_SUPPLY_DOCUMENT',

  PAYMENT_LAUNCH_DOC_CONTROL: 'PAYMENT_LAUNCH_DOC_CONTROL',
  PAYMENT_CONTROL_DOCUMENT: 'PAYMENT_CONTROL_DOCUMENT',
  PAYMENT_ARCHIVE: 'PAYMENT_ARCHIVE',
  PAYMENT_SEND_MEDOC: 'PAYMENT_SEND_MEDOC',

  PAYMENT_SEND_CONTROL: 'PAYMENT_SEND_CONTROL',
  PAYMENT_RETAKE_SUPPLY: 'PAYMENT_RETAKE_SUPPLY',

  // ENDING
  CANCELLED: 'CANCELLED',
  NO_FOLLOW: 'NO_FOLLOW',
  REFUSED: 'REFUSED',
  PAYMENT_REFUSED: 'PAYMENT_REFUSED',
  FINANCED: 'FINANCED',
  PAYMENT_FINAL: 'PAYMENT_FINAL',
} as TypeStatus;

export const MENU = {
  STUDY: 'STUDY',
  SURVEY: 'SURVEY',
  PAYMENT: 'PAYMENT',
};

export const TYPE = {
  BORROWING: 'borrowing',
  RENTAL: 'rental',
};

export const CATEGORY = {
  ACTOR: 'ACTOR',
  FOLDER: 'FOLDER',
  PAYMENT: 'PAYMENT',
};

export const DOCUMENT_STATUS = {
  PENDING: 'PENDING',
  UPLOADING: 'UPLOADING',
  UPLOADED: 'UPLOADED',
  IGNORED: 'IGNORED',
  REJECTED: 'REJECTED',
  UPDATED: 'UPDATED',
};

export type keyOfDocumentSource = keyof TypeDocumentSource;

interface TypeDocumentSource {
  SOFIAWEB_MANDATORY: keyOfDocumentSource;
  PAYMENT: keyOfDocumentSource;
  OPERATOR_MANDATORY: keyOfDocumentSource;
  OPERATOR_OPTIONAL: keyOfDocumentSource;
  DEALER_OPTIONAL: keyOfDocumentSource;
  CLIENT_OPTIONAL: keyOfDocumentSource;
}

export const DOCUMENT_SOURCE: TypeDocumentSource = {
  SOFIAWEB_MANDATORY: 'SOFIAWEB_MANDATORY',
  PAYMENT: 'PAYMENT',
  OPERATOR_MANDATORY: 'OPERATOR_MANDATORY',
  OPERATOR_OPTIONAL: 'OPERATOR_OPTIONAL',
  DEALER_OPTIONAL: 'DEALER_OPTIONAL',
  CLIENT_OPTIONAL: 'CLIENT_OPTIONAL',
};

export type keyOFDocumentSide = keyof TypeDocumentSide;

interface TypeDocumentSide {
  FRONT: keyOFDocumentSide;
  BACK: keyOFDocumentSide;
}

export const REFUSAL_TYPE = {
  STUDY_DOCUMENT: 'STUDY_DOCUMENT',
  STUDY_ONE_PDF: 'STUDY_ONE_PDF',
  PAYMENT_DOCUMENT: 'PAYMENT_DOCUMENT',
};

export const FILE_SIZE = {
  IMAGE: 10 * 1024 * 1024,
  COMPRESSED_IMAGE: 2 * 1024 * 1024,
  PDF: 10 * 1024 * 1024,
  ONE_PDF: 10 * 1024 * 1024,
  ONE_MB: 1024 * 1024,
};

export const FORMAT = {
  PDF: 'application/pdf',
  IMAGE: ['image/png', 'image/jpeg'],
  ALL: ['application/pdf', 'image/png', 'image/jpeg'],
  CSV: ['text/csv'],
};

export const MAX_IMAGES_PER_DOCUMENT = 5;

export const TOAST_DURATION = 6000; // ms

export const BACK_OFFICE_LENGTH = {
  NAME: 100,
  DESCRIPTION: 1000,
};

export const ENDING_STATUS = [
  STATUS.CANCELLED,
  STATUS.NO_FOLLOW,
  STATUS.REFUSED,
  STATUS.PAYMENT_REFUSED,
  STATUS.FINANCED,
  STATUS.PAYMENT_FINAL,
];

export const STUDY_SENDING_STATUS = [
  STATUS.STUDY_LAUNCH_DOC_CONTROL,
  STATUS.STUDY_CONTROL_DOCUMENT,
  STATUS.STUDY_ARCHIVE,
  STATUS.STUDY_SEND_MEDOC,
];

export const PAYMENT_SENDING_STATUS = [
  STATUS.PAYMENT_CONTROL_DOCUMENT,
  STATUS.PAYMENT_LAUNCH_DOC_CONTROL,
  STATUS.PAYMENT_ARCHIVE,
  STATUS.PAYMENT_SEND_MEDOC,
];

export const STUDY_STATUS = [
  STATUS.STUDY_SUPPLY_DOCUMENT,

  STATUS.STUDY_LAUNCH_DOC_CONTROL,
  STATUS.STUDY_CONTROL_DOCUMENT,
  STATUS.STUDY_ARCHIVE,
  STATUS.STUDY_SEND_MEDOC,

  STATUS.STUDY_SEND_CONTROL,
  STATUS.STUDY_RETAKE_SUPPLY,
];

export const PAYMENT_STATUS = [
  STATUS.PAYMENT_SURVEY,
  STATUS.PAYMENT_SUPPLY_DOCUMENT,

  STATUS.PAYMENT_CONTROL_DOCUMENT,
  STATUS.PAYMENT_ARCHIVE,
  STATUS.PAYMENT_SEND_MEDOC,

  STATUS.PAYMENT_SEND_CONTROL,
  STATUS.PAYMENT_RETAKE_SUPPLY,
];

export const LOCAL_STORAGE = {
  COOKIE: 'COOKIE',
};

export const COOKIES = {
  SOFIADOC_AUTH: 'sofiadocAuth',
  PROFILES: 'profiles',
};

export const CHARACTERS = {
  NUMBERS: generateArrayOfCharacters('0', '9'),
  ALPHABET_LOWERCASE: generateArrayOfCharacters('a', 'z'),
  ALPHABET_UPPERCASE: generateArrayOfCharacters('A', 'Z'),
};

export const MODAL_KEYS = {
  clientUploadComplete: 'clientUploadComplete' as TypeModalStoreKey,
};
