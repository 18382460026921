import { isDealer, isOperator, PROFILES } from '../../../utils/rights';
import { Accordion, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BmwAccordion from '../../../components/folder/BmwAccordion';
import React, { useCallback, useEffect, useState } from 'react';
import FileContainer from '../../../components/folder/study/FileContainer';
import AddFile from '../../../components/folder/study/AddFile';
import Invitation from '../../../components/folder/study/Invitation';
import { useStore } from 'effector-react';
import { FolderStore } from '../../../state/folder/FolderStore';
import { UserStore } from '../../../state/user/UserStore';
import { useTranslation } from 'react-i18next';
import { TypeActor, TypeDocument } from '../../../types/globalTypes';
import { DocumentsStore } from '../../../state/documents/DocumentsStore';
import { ActorStore } from '../../../state/actor/ActorStore';
import { FolderResponse } from '../../../types/model/folderResponse';
import { CATEGORY, DOCUMENT_SOURCE } from '../../../utils/constants';
import {
  requestGetDocumentActor,
  requestGetDocumentFolder,
} from '../../../state/documents/DocumentsEffects';
import { TypeFolderStore, TypeUserStore } from '../../../types/storeTypes';
import { getActorName, isMandatory } from '../../../utils/utils';
import {
  getNextActor,
  isFolderComplete,
} from '../../../services/folderService';
import ButtonLaunchControl from '../../../components/folder/study/ButtonLaunchControl';
import { requestGetActorCompletion } from '../../../state/actor/ActorEffects';
import { clearDocuments } from '../../../state/documents/DocumentsEvents';
import BmwButton from '../../../components/form/BmwButton';
import BmwUnbindModal from '../../../components/modal/BmwUnbindModal';
import { requestGetFolderCompletion } from '../../../state/folder/FolderEffects';
import ReactHtmlParser from 'react-html-parser';

type Props = {
  isError: (file: TypeDocument) => boolean;
};

function useForceUpdate() {
  // eslint-disable-next-line
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

const Reprise = (props: Props) => {
  const [activeKey, setActiveKey] = useState<string>('');
  const [initialActorStore, setInitialActor] = useState<TypeActor[]>([]);
  const [showUnbindModal, setShowUnbindModal] = useState<boolean>(false);
  const [unbindDocumentName, setUnbindDocumentName] = useState<
    string | undefined
  >(undefined);

  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const userStore = useStore<TypeUserStore>(UserStore);
  const documentsStore = useStore<TypeDocument[]>(DocumentsStore);
  const actorStore = useStore<TypeActor[]>(ActorStore);

  const { isError } = props;
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();

  const getTitle = (
    actor?: TypeActor,
    folder?: FolderResponse,
    total: number = 0,
    error: number = 0,
  ) => {
    if (!actor) {
      return (
        <span className="font-weight-bold">
          {t('folder.files.documents')} :{' '}
          {` ${error !== 0 ? ' ' + error : ''} ${t(
            `folder.files.${error !== 0 ? 'changed' : 'accepted'}`,
            { count: error !== 0 ? error : total },
          )}`}
        </span>
      );
    }
    return (
      <>
        <span className="font-weight-bold">
          {t(`client.${folder?.category}.${actor.type.toLowerCase()}.label`)} :{' '}
        </span>
        {getActorName(actor)}
        <span className="font-weight-bold">{` -${
          error !== 0 ? ' ' + error : ''
        } ${t(`folder.files.${error !== 0 ? 'changed' : 'accepted'}`, {
          count: error !== 0 ? error : total,
        })}`}</span>
      </>
    );
  };

  const computeNextActor = useCallback(
    (actualActor: TypeActor | null, updateState = true) => {
      const nextActor = getNextActor(actualActor, userStore, initialActorStore);
      if (updateState || userStore.profiles.includes(PROFILES.CLIENT)) {
        if (nextActor) {
          setActiveKey(`actor_${nextActor.id}`);
        } else {
          if (!folderStore.completed && folderStore.nbDocumentTotal !== 0) {
            setActiveKey(folderKey);
          } else {
            setActiveKey('');
          }
        }
      }
    },
    [
      userStore,
      initialActorStore,
      folderStore.completed,
      folderStore.nbDocumentTotal,
    ],
  );

  const getActorCompletion = useCallback(() => {
    actorStore.forEach((actor: TypeActor) => {
      if (
        (!userStore.profiles.includes(PROFILES.CLIENT) ||
          actor.id === userStore.actorId) &&
        !actor.completionUpdated
      ) {
        requestGetActorCompletion({
          id: actor.id,
        });
      }
    });
  }, [actorStore, userStore.profiles, userStore.actorId]);

  useEffect(getActorCompletion, [getActorCompletion]);

  useEffect(() => {
    if (
      initialActorStore.length === 0 &&
      actorStore.length !== 0 &&
      !actorStore.find(
        (a) =>
          !a.completionUpdated &&
          (a.id === userStore.actorId ||
            !userStore.profiles.includes(PROFILES.CLIENT)),
      )
    ) {
      setInitialActor(actorStore);
    }
  }, [actorStore, initialActorStore, userStore.actorId, userStore.profiles]);

  useEffect(() => {
    if (actorStore.find((a) => a.completed)) {
      setInitialActor(actorStore);
    }
  }, [actorStore]);

  useEffect(() => {
    if (activeKey !== '') {
      if (activeKey.split('_')[0] === folderKey) {
        requestGetDocumentFolder({
          id: folderStore.id,
        });
      } else {
        requestGetDocumentActor({
          id: activeKey.split('_')[1],
        });
      }
    }
  }, [activeKey, folderStore]);

  useEffect(() => computeNextActor(null, false), [computeNextActor]);

  const processCallback = (): void => {
    setActiveKey('');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const folderKey = 'folder';

  return (
    <>
      <div id="study" className="accordion mb-5 pt-4">
        {!userStore.profiles.includes(PROFILES.CLIENT) && (
          <h4>{t('folder.pieces')}</h4>
        )}
        <Accordion activeKey={activeKey}>
          {actorStore
            .filter(
              (actor) =>
                !userStore.profiles.includes(PROFILES.CLIENT) ||
                actor.id === userStore.actorId,
            )
            .map((actor) => {
              const key = `actor_${actor.id}`;
              return (
                <BmwAccordion
                  hideAccordion={userStore.profiles.includes(PROFILES.CLIENT)}
                  complete={actor.completed}
                  error={actor.nbDocumentTotal - actor.nbDocumentValid}
                  key={key}
                  id={key}
                  actorId={actor.id}
                  title={getTitle(
                    actor,
                    folderStore,
                    actor.nbDocumentTotal,
                    actor.nbDocumentTotal - actor.nbDocumentValid,
                  )}
                  activeKey={activeKey}
                  onClick={() => {
                    if (activeKey === key) {
                      setActiveKey('');
                    } else {
                      setActiveKey(key);
                    }
                  }}
                  validate={() => {
                    clearDocuments();
                    computeNextActor(actor);
                  }}
                  sendInvitation={
                    !actor.completed &&
                    !userStore.profiles.includes(PROFILES.CLIENT) &&
                    actor.hasEmailAddress
                  }
                  hideValidate={
                    userStore.profiles.includes(PROFILES.CLIENT) &&
                    userStore.profiles.length === 1
                  }
                >
                  <h6>{t('folder.askedFiles')}</h6>
                  <p className="description">{t('folder.description')}</p>
                  <div className="mb-5">
                    {documentsStore
                      .filter(
                        (value) =>
                          isMandatory(value.source) &&
                          (isOperator(userStore.profiles) || !value.ignored),
                      )
                      .map((file: TypeDocument) => (
                        <FileContainer
                          valid={!isError(file)}
                          error={isError(file)}
                          id={`file_${file.id}_actor_${actor.id}`}
                          key={`file_${file.id}_actor_${actor.id}`}
                          file={file}
                          category={CATEGORY.ACTOR}
                        />
                      ))}
                  </div>
                  <AddFile
                    title={t('folder.addFile.other.title')}
                    description={t('folder.addFile.other.description')}
                    button={t('folder.addFile.other.button')}
                    actor={actor}
                    source={
                      isDealer(userStore.profiles)
                        ? DOCUMENT_SOURCE.DEALER_OPTIONAL
                        : DOCUMENT_SOURCE.CLIENT_OPTIONAL
                    }
                  />
                  <div className="mb-5">
                    {documentsStore
                      .filter(
                        (value) =>
                          !isMandatory(value.source) &&
                          (isOperator(userStore.profiles) || !value.ignored),
                      )
                      .map((file: TypeDocument) => (
                        <FileContainer
                          valid={!isError(file)}
                          error={isError(file)}
                          id={`file_${file.id}_actor_${actor.id}`}
                          key={`file_${file.id}_actor_${actor.id}`}
                          file={file}
                          category={CATEGORY.ACTOR}
                          mandatory={false}
                        />
                      ))}
                  </div>
                  {!userStore.profiles.includes(PROFILES.CLIENT) && (
                    <Invitation
                      actorId={actor.id}
                      checked={actor.autoReminder}
                      sendInvitation={!actor.completed && actor.hasEmailAddress}
                    />
                  )}
                </BmwAccordion>
              );
            })}
          {!userStore.profiles.includes(PROFILES.CLIENT) && (
            <BmwAccordion
              sendInvitation={false}
              id={folderKey}
              title={getTitle(
                undefined,
                undefined,
                folderStore.nbDocumentTotal,
                folderStore.nbDocumentTotal - folderStore.nbDocumentValid,
              )}
              error={folderStore.nbDocumentTotal - folderStore.nbDocumentValid}
              complete={folderStore.completed}
              activeKey={activeKey}
              onClick={() => {
                if (activeKey === folderKey) {
                  setActiveKey('');
                } else {
                  setActiveKey(folderKey);
                }
              }}
              validate={() => {
                clearDocuments();
                setActiveKey('');
              }}
            >
              <h6>{t('folder.askedFiles')}</h6>
              <p className="description">{t('folder.description')}</p>
              <div className="mb-5">
                {documentsStore
                  .filter(
                    (value) =>
                      isMandatory(value.source) &&
                      (isOperator(userStore.profiles) || !value.ignored),
                  )
                  .map((file: TypeDocument) => (
                    <FileContainer
                      valid={!isError(file)}
                      error={isError(file)}
                      id={`file_${file.id}_folder`}
                      key={`file_${file.id}_folder`}
                      file={file}
                      category={CATEGORY.FOLDER}
                    />
                  ))}
              </div>
              <AddFile
                title={t('folder.addFile.other.title')}
                description={t('folder.addFile.other.description')}
                button={t('folder.addFile.other.button')}
                folderId={folderStore.id}
                source={
                  isDealer(userStore.profiles)
                    ? DOCUMENT_SOURCE.DEALER_OPTIONAL
                    : DOCUMENT_SOURCE.CLIENT_OPTIONAL
                }
              />
              <div className="mb-5">
                {documentsStore
                  .filter(
                    (value) =>
                      !isMandatory(value.source) &&
                      (isOperator(userStore.profiles) || !value.ignored),
                  )
                  .map((file: TypeDocument) => (
                    <FileContainer
                      valid={!isError(file)}
                      error={isError(file)}
                      id={`file_${file.id}_folder`}
                      key={`file_${file.id}_folder`}
                      file={file}
                      category={CATEGORY.FOLDER}
                      mandatory={false}
                    />
                  ))}
              </div>
            </BmwAccordion>
          )}
        </Accordion>
      </div>
      <div className="unbind-card">
        <BmwButton
          label={ReactHtmlParser(
            unbindDocumentName
              ? t('folder.unbind.resume-button', {
                  documentName: unbindDocumentName,
                })
              : t('folder.unbind.start-button'),
          )}
          type="primary"
          onClick={() => {
            setShowUnbindModal(true);
            setActiveKey('');
          }}
        />
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              {t('folder.unbind.description')}
            </Tooltip>
          }
        >
          <div className={'infoIcon'}></div>
        </OverlayTrigger>
      </div>
      <BmwUnbindModal
        onUnbindDocument={(documentName) => {
          setUnbindDocumentName(documentName);
        }}
        closeUnbindModal={() => {
          setShowUnbindModal(false);
          forceUpdate();
          getActorCompletion();
          if (userStore.profiles.includes(PROFILES.CLIENT)) {
            if (userStore.actorId) {
              setActiveKey(`actor_${userStore.actorId}`);
            }
          } else {
            requestGetFolderCompletion({
              id: folderStore.id,
            });
          }
        }}
        show={showUnbindModal}
        title={t('folder.unbind.modal.title')}
      />
      {isDealer(userStore.profiles) && (
        <ButtonLaunchControl
          callback={processCallback}
          disabled={!isFolderComplete(actorStore, folderStore)}
        />
      )}
    </>
  );
};
export default Reprise;
