import React, { ReactNode } from 'react';

type Props = {
  title: string;
  description: string;
  variant?: string;
  children?: ReactNode;
};

const BmwBloc = (props: Props) => {
  const { title, description, children, variant = 'warning' } = props;
  return (
    <div className="custom-card p-4 mb-5">
      <h4>{title}</h4>
      <p className={`description mb-3 ${variant}`}>{description}</p>
      {children}
    </div>
  );
};

export default BmwBloc;
