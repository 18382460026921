import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActorStore } from '../../../state/actor/ActorStore';
import { TypeActor } from '../../../types/globalTypes';
import { useStore } from 'effector-react';
import { FolderStore } from '../../../state/folder/FolderStore';
import { TypeFolderStore, TypeUserStore } from '../../../types/storeTypes';
import BmwCheckbox from '../../form/BmwCheckbox';
import {
  requestGetLastInvitation,
  requestUpdateActorReminder,
} from '../../../state/actor/ActorEffects';
import { addToast } from '../../../state/toast/ToastEvents';
import { InvitationResponse } from '../../../types/model/invitationResponse';
import { handleParsedResponse } from '../../../utils/utils';
import {
  DATE_FORMAT_SHORT_WITH_TIME,
  DATE_INPUT_FORMAT_LONG,
  formatDate,
} from '../../../utils/date';
import { setShouldUpdateLastInvitation } from '../../../state/actor/ActorEvents';
import { ParsedResponse } from '../../../rest/ServerResponseParse';
import BmwButton from '../../form/BmwButton';
import { isDealer } from '../../../utils/rights';
import { UserStore } from '../../../state/user/UserStore';
import BmwMailModal from '../../modal/BmwMailModal';
import BmwMailingList from '../../modal/BmwMailingList';

type Props = {
  checked?: boolean;
  actorId: string;
  disabled?: boolean;
  sendInvitation?: boolean;
};

const Invitation = (props: Props) => {
  const actorStore = useStore<TypeActor[]>(ActorStore);
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const userStore = useStore<TypeUserStore>(UserStore);
  const [loading, setLoading] = useState<boolean>(false);
  const [last, setLast] = useState<InvitationResponse>();
  const [showMailModal, setShowMailModal] = useState<boolean>(false);
  const [showMailingListModal, setShowMailingListModal] =
    useState<boolean>(false);
  const [color, setColor] = React.useState('#0542a5');
  const { checked = false, actorId, disabled = false, sendInvitation } = props;
  const { t } = useTranslation();
  const actor = actorStore.find((a) => a.id === actorId) as TypeActor;
  const name = t(
    `client.${folderStore?.category}.${actor?.type.toLowerCase()}.label`,
  )
    .toLowerCase()
    .replace(' principal', '');
  const article = t(
    `client.${folderStore?.category}.${actor?.type.toLowerCase()}.article`,
  );
  const articlePoss = t(
    `client.${folderStore?.category}.${actor?.type.toLowerCase()}.articlePoss`,
  );

  const getLastInvitation = useCallback(
    () =>
      requestGetLastInvitation({
        id: actorId,
      })
        .then((response: ParsedResponse<InvitationResponse>) => {
          handleParsedResponse(response)((data: InvitationResponse) =>
            setLast(data),
          );
          setShouldUpdateLastInvitation({ id: actorId, value: false });
        })
        .catch(() =>
          setShouldUpdateLastInvitation({ id: actorId, value: false }),
        ),
    [actorId],
  );

  useEffect(() => {
    if (actor.shouldUpdateLastInvitation) {
      getLastInvitation();
    }
  }, [actor.shouldUpdateLastInvitation, getLastInvitation]);

  const onSendInvitationClick = (): void => {
    setShowMailModal(true);
  };

  const isCheckboxDisabled =
    disabled || loading || !isDealer(userStore.profiles);

  const hasEmail = !!actor.email;
  return (
    <>
      <BmwMailModal
        handleClose={() => setShowMailModal(false)}
        show={showMailModal}
        setLoading={setLoading}
        actor={actor}
      />
      <BmwMailingList
        handleClose={() => setShowMailingListModal(false)}
        show={showMailingListModal}
        folderStore={folderStore}
      />
      <div className="custom-card-white p-4 mb-5">
        <h6>{t('folder.invitation.title', { articlePoss, name })}</h6>
        <p className="description">
          {t('folder.invitation.description', { article, name })}
        </p>
        <p className="description">{t('folder.invitation.descriptionMail')}</p>
        <ul className="description">
          {hasEmail && (
            <li>{`${t('folder.invitation.email')}${actor.email}`}</li>
          )}
        </ul>
        <p className="description">{t('folder.invitation.descriptionLink')}</p>
        <ul className="description">
          <li>{`${t('folder.invitation.link')}${actor.link}`}</li>
        </ul>
        {sendInvitation && (
          <BmwButton
            loading={loading}
            disabled={disabled}
            className="ml-auto mb-3"
            type="tertiary"
            label={
              <>
                <i className="fa fa-envelope pr-2" />
                {t('folder.invitation.sendInvitation')}
              </>
            }
            onClick={onSendInvitationClick}
          />
        )}
        <p className="description">
          {`${t('folder.invitation.diffusionList', { article, name })}`}{' '}
          {folderStore.mailingList}
          <br />
          <label
            style={{ cursor: 'pointer', color: color }}
            onMouseEnter={() => setColor('#0653b6')}
            onMouseLeave={() => setColor('#0542a5')}
            onClick={() => setShowMailingListModal(true)}
          >
            {t('mailing-list.title')}
          </label>
        </p>
        {hasEmail && (
          <>
            <BmwCheckbox
              disabled={isCheckboxDisabled}
              className={isCheckboxDisabled ? 'pe-none' : 'cursor-pointer'}
              id="invitationCheck"
              label={t('folder.invitation.check', { name })}
              onClick={() => {
                setLoading(true);
                requestUpdateActorReminder({
                  id: actorId,
                  dto: { reminder: !checked },
                })
                  .then(() => {
                    addToast({
                      type: 'success',
                      title: t('toast.actor.reminder.title'),
                      message: t('toast.actor.reminder.message'),
                    });
                  })
                  .finally(() => setLoading(false));
              }}
              checked={checked}
            />
            <p className="description">
              {last?.creationDate
                ? t('folder.invitation.last', {
                    date: formatDate(
                      last.creationDate.toString(),
                      DATE_FORMAT_SHORT_WITH_TIME,
                      DATE_INPUT_FORMAT_LONG,
                      false,
                    ),
                  })
                : t('folder.invitation.none')}
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default Invitation;
