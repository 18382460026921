import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportingTable from '../../components/reporting/ReportingTable';
import ReportingFilter from '../../components/reporting/ReportingFilter';
import { CONTENT_TYPE, restHandler } from '../../rest/RestClient';
import { EXPORTS, REPORTINGS } from '../../rest/apiPath';
import { ParsedResponse } from '../../rest/ServerResponseParse';
import {
  downloadFile,
  getQueryVariable,
  handleParsedResponse,
} from '../../utils/utils';
import {
  ORDER,
  REPORTING_CURRENT_FOLDER_ATTRIBUTES,
} from '../../utils/constants';
import { TypeSort } from '../../types/tableTypes';
import { TypeReportingCurrentFolderAttribute } from '../../types/globalTypes';
import Pagination from '../../components/Pagination';
import {
  DATE_FORMAT_SHORT,
  DATE_INPUT_FORMAT,
  formatDate,
} from '../../utils/date';
import { CONF_REPORTING_PAGE_SIZE } from '../../utils/configuration';
import BmwButton from '../../components/form/BmwButton';
import { PageReportingCurrentFolder } from '../../types/model/pageReportingCurrentFolder';
import { ReportingCurrentFolderResponse } from '../../types/model/reportingCurrentFolderResponse';
import ReportingHeader from '../../components/reporting/ReportingHeader';

type Props = {
  path: string;
};

type Data = {
  id: string;
  folderId?: string;
  dealershipId?: string;
  dealershipName?: string;
  vehicleBrand?: string;
  actorName?: string;
  studyProcessTime?: string;
  paymentProcessTime?: string;
  totalProcessTime?: string;
  refusalCountIllegible?: string;
  refusalCountMissing?: string;
  refusalCountIncorrect?: string;
  refusalCountExpired?: string;
  studyGoBackCount?: string;
  paymentGoBackCount?: string;
  actorsCount?: string;
  studyLastMedocSent?: string;
  paymentLastMedocSent?: string;
  creationDate?: string;
  endDate?: string;
  status?: string;
  cniLastUpload?: string;
  cniMean?: string;
  passportLastUpload?: string;
  passportMean?: string;
  ribLastUpload?: string;
  ribMean?: string;
  jddLastUpload?: string;
  jddMean?: string;
  sejourLastUpload?: string;
  bs1Mean?: string;
  bs1LastUpload?: string;
  bs2Mean?: string;
  bs2LastUpload?: string;
  sejourMean?: string;
  kycReportLastUpload?: string;
  kycReportMean?: string;
  taxLastUpload?: string;
  taxMean?: string;
};

interface Column {
  name: string;
  label: string;
  className?: string;
  accessor: (data: any) => any;
}

interface Filter {
  startDate?: string;
  endDate?: string;
  dealershipIds: string[];
  district?: string;
  folderId?: string;
}

const CurrentFoldersView = (props: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [maxPage, setMaxPage] = useState<number>(1);
  const [sort, setSort] = useState<TypeSort>({
    name: REPORTING_CURRENT_FOLDER_ATTRIBUTES.CREATION_DATE,
    order: ORDER.DESC,
  });
  const [data, setData] = useState<ReportingCurrentFolderResponse[]>([]);
  const [filter, setFilter] = useState({
    dealershipIds: getQueryVariable('id') ? [getQueryVariable('id')] : [],
  } as Filter);

  const convertColumnName = (
    name: TypeReportingCurrentFolderAttribute,
  ): string => {
    switch (name) {
      case 'folderId':
        return 'ID';
      case 'dealershipId':
        return 'DEALERSHIP_ID';
      case 'dealershipName':
        return 'DEALERSHIP_NAME';
      case 'creationDate':
        return 'CREATION_DATE';
      case 'endDate':
        return 'END_DATE';
    }
  };

  const buildSearchQueryParams = useCallback((): string => {
    return `?${filter.district ? `district=${filter.district}&` : ''}${
      filter.startDate ? `startDate=${filter.startDate}&` : ''
    }${filter.endDate ? `endDate=${filter.endDate}&` : ''}${
      filter.folderId ? `folderId=${filter.folderId}&` : ''
    }${filter.dealershipIds
      .map((dealershipId: string) => `dealershipIds=${dealershipId}&`)
      .join(
        '',
      )}pageIndex=${page}&pageSize=${CONF_REPORTING_PAGE_SIZE}&sort=${convertColumnName(
      sort.name as TypeReportingCurrentFolderAttribute,
    )}&direction=${sort.order}`;
  }, [filter, sort, page]);

  const splitDocuments = (content?: ReportingCurrentFolderResponse[]) => {
    if (!content) return undefined;
    return content.map((data: ReportingCurrentFolderResponse) => {
      if (!data.documentsData) return data;
      var newContent = data;
      let documentsStr: string[] = data.documentsData.split(';');
      for (const docStr of documentsStr) {
        const documentData = docStr.split(',');
        let name = documentData[0];
        let date = documentData[1];
        let rating = documentData[2];
        switch (name) {
          case 'STATE_ID':
            newContent.cniLastUpload = date;
            newContent.cniMean = Number(rating);
            break;
          case 'PASSPORT':
            newContent.passportLastUpload = date;
            newContent.passportMean = Number(rating);
            break;
          case 'RESIDENCE_PERMIT':
            newContent.sejourLastUpload = date;
            newContent.sejourMean = Number(rating);
            break;
          case 'BANK_ID':
            newContent.ribLastUpload = date;
            newContent.ribMean = Number(rating);
            break;
          case 'PAYSLIP':
            newContent.bs1LastUpload = date;
            newContent.bs1Mean = Number(rating);
            break;
          case 'PAYSLIP_2':
            newContent.bs2LastUpload = date;
            newContent.bs2Mean = Number(rating);
            break;
          case 'RESIDENCE_PROOF':
            newContent.jddLastUpload = date;
            newContent.jddMean = Number(rating);
            break;
          case 'TAX_ASSESSMENT':
            newContent.taxLastUpload = date;
            newContent.taxMean = Number(rating);
            break;
          case 'KYC_REPORT':
            newContent.kycLastUpload = date;
            newContent.kycMean = Number(rating);
            break;
          default:
            break;
        }
      }
      return newContent;
    });
  };

  const search = useCallback(() => {
    setLoading(true);
    restHandler<PageReportingCurrentFolder>(
      REPORTINGS.CURRENT_FOLDER,
      buildSearchQueryParams(),
    )()
      .then((res: ParsedResponse<PageReportingCurrentFolder>) =>
        handleParsedResponse(res)((data: PageReportingCurrentFolder) => {
          const processedContent = splitDocuments(data.content);
          setData(processedContent || []);
          setPage(data.pageIndex || 0);
          setMaxPage(data.maxPageIndex || 1);
        }),
      )
      .finally(() => setLoading(false));
  }, [buildSearchQueryParams]);

  useEffect(() => {
    search();
  }, [search]);

  useEffect(() => {
    const query = getQueryVariable('search');
    if (query) {
      setFilter((prevState) => ({ ...prevState, dealershipIds: [query] }));
    }
  }, []);

  const columns = [
    {
      label: t('reporting.currentFolder.table.id'),
      name: 'folderId',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.currentFolder.table.creationDate'),
      name: 'creationDate',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 150,
    },
    {
      label: t('reporting.currentFolder.table.endDate'),
      name: 'endDate',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 150,
    },
    {
      label: t('reporting.currentFolder.table.type'),
      name: 'type',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.currentFolder.table.customerName'),
      name: 'actorName',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.currentFolder.table.vehicleBrand'),
      name: 'vehicleBrand',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.currentFolder.table.district'),
      name: 'district',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.currentFolder.table.concessionId'),
      name: 'dealershipId',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.currentFolder.table.concessionName'),
      name: 'dealershipName',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.currentFolder.table.studyLastMedocSent'),
      name: 'studyLastMedocSent',
      className: 'custom-bg-blue',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 150,
    },
    {
      label: t('reporting.currentFolder.table.paymentLastMedocSent'),
      name: 'paymentLastMedocSent',
      className: 'custom-bg-blue',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 150,
    },
    {
      label: t('reporting.currentFolder.table.durationStudy'),
      name: 'studyProcessTime',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.durationPayment'),
      name: 'paymentProcessTime',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.durationGlobal'),
      name: 'totalProcessTime',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.nbGobackStudy'),
      name: 'studyGoBackCount',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 160,
    },
    {
      label: t('reporting.currentFolder.table.nbGobackPayment'),
      name: 'paymentGoBackCount',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 160,
    },
    {
      label: t('reporting.currentFolder.table.status'),
      name: 'status',
      className: 'custom-bg-blue',
      accessor: (data: string) => t(`status.${data}`),
      width: 160,
    },
    {
      label: t('reporting.currentFolder.table.refusalCountIllegible'),
      name: 'refusalCountIllegible',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.refusalCountMissing'),
      name: 'refusalCountMissing',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.refusalCountIncorrect'),
      name: 'refusalCountIncorrect',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.refusalCountExpired'),
      name: 'refusalCountExpired',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.lastSubmission'),
      name: 'kycLastUpload',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.rating'),
      name: 'kycMean',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.lastSubmission'),
      name: 'cniLastUpload',
      className: 'custom-bg-blue',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.rating'),
      name: 'cniMean',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 90,
    },
    {
      label: t('reporting.currentFolder.table.lastSubmission'),
      name: 'passportLastUpload',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.rating'),
      name: 'passportMean',
      accessor: (data: string) => data,
      width: 90,
    },
    {
      label: t('reporting.currentFolder.table.lastSubmission'),
      name: 'sejourLastUpload',
      className: 'custom-bg-blue',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.rating'),
      name: 'sejourMean',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.lastSubmission'),
      name: 'jddLastUpload',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.rating'),
      name: 'jddMean',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.lastSubmission'),
      name: 'ribLastUpload',
      className: 'custom-bg-blue',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.rating'),
      name: 'ribMean',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.lastSubmission'),
      name: 'bs1LastUpload',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.rating'),
      name: 'bs1Mean',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.lastSubmission'),
      name: 'bs2LastUpload',
      className: 'custom-bg-blue',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.rating'),
      name: 'bs2Mean',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.lastSubmission'),
      name: 'taxLastUpload',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 130,
    },
    {
      label: t('reporting.currentFolder.table.rating'),
      name: 'taxMean',
      accessor: (data: string) => data,
      width: 90,
    },
  ] as Column[];

  const mapToData = (value: ReportingCurrentFolderResponse): Data => {
    const documentData: ReportingCurrentFolderResponse[] | undefined =
      splitDocuments([value]);
    return {
      ...value,
      id: value.folderId || '',
      studyProcessTime:
        value.studyProcessTime || value.studyProcessTime === 0
          ? `${value.studyProcessTime}j`
          : '',
      paymentProcessTime:
        value.paymentProcessTime || value.paymentProcessTime === 0
          ? `${value.paymentProcessTime}j`
          : '',
      totalProcessTime:
        value.totalProcessTime || value.totalProcessTime === 0
          ? `${value.totalProcessTime}j`
          : '',
      vehicleBrand: value.vehicleBrand,
      actorName: value.actorName,
      actorsCount: value.actorsCount?.toString(),
      studyGoBackCount: value.studyGoBackCount?.toString(),
      paymentGoBackCount: value.paymentGoBackCount?.toString(),
      studyLastMedocSent:
        value.studyLastMedocSent || value.studyLastMedocSent === null
          ? `${value.studyLastMedocSent}j`
          : '',
      paymentLastMedocSent:
        value.paymentLastMedocSent || value.paymentLastMedocSent === null
          ? `${value.paymentLastMedocSent}j`
          : '',
      creationDate:
        value.creationDate || value.creationDate === null
          ? `${value.creationDate}j`
          : '',
      endDate:
        value.endDate || value.endDate === null ? `${value.endDate}j` : '',
      status: value.status || '',
      refusalCountIllegible: value.refusalCountIllegible?.toString() || '',
      refusalCountMissing: value.refusalCountMissing?.toString() || '',
      refusalCountIncorrect: value.refusalCountIncorrect?.toString() || '',
      refusalCountExpired: value.refusalCountExpired?.toString() || '',
      cniLastUpload: documentData ? documentData[0].cniLastUpload : '',
      cniMean: documentData ? documentData[0].cniMean?.toString() : '',
      passportLastUpload: documentData
        ? documentData[0].passportLastUpload
        : '',
      passportMean: documentData
        ? documentData[0].passportMean?.toString()
        : '',
      ribLastUpload: documentData ? documentData[0].ribLastUpload : '',
      ribMean: documentData ? documentData[0].ribMean?.toString() : '',
      jddLastUpload: documentData ? documentData[0].jddLastUpload : '',
      jddMean: documentData ? documentData[0].jddMean?.toString() : '',
      bs1LastUpload: documentData ? documentData[0].bs1LastUpload : '',
      bs1Mean: documentData ? documentData[0].bs1Mean?.toString() : '',
      bs2LastUpload: documentData ? documentData[0].bs2LastUpload : '',
      bs2Mean: documentData ? documentData[0].bs2Mean?.toString() : '',
      sejourLastUpload: documentData ? documentData[0].sejourLastUpload : '',
      sejourMean: documentData ? documentData[0].sejourMean?.toString() : '',
      kycReportLastUpload: documentData ? documentData[0].kycLastUpload : '',
      kycReportMean: documentData ? documentData[0].kycMean?.toString() : '',
      taxLastUpload: documentData ? documentData[0].taxLastUpload : '',
      taxMean: documentData ? documentData[0].taxMean?.toString() : '',
    };
  };

  const onExport = useCallback(() => {
    setLoading(true);
    restHandler<string>(
      EXPORTS.CURRENT_FOLDER,
      buildSearchQueryParams(),
      undefined,
      CONTENT_TYPE.EXCEL,
    )()
      .then((res: ParsedResponse<string>) => {
        handleParsedResponse(res)((blob: Blob) =>
          downloadFile(res.filename || '', blob),
        );
      })
      .finally(() => setLoading(false));
  }, [buildSearchQueryParams]);

  const supHeader = (
    <div className="custom-tr">
      <div className="custom-th not-sortable" style={{ flex: '1 0 25%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.currentFolder.table.dossier')}
        </div>
      </div>
      <div className="custom-th not-sortable" style={{ flex: '1 0 29%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.currentFolder.table.folderTreatment')}
        </div>
      </div>
      <div className="custom-th not-sortable" style={{ flex: '1 0 4%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.currentFolder.table.kyc')}
        </div>
      </div>
      <div className="custom-th not-sortable" style={{ flex: '1 0 3.75%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.currentFolder.table.cni')}
        </div>
      </div>
      <div className="custom-th not-sortable" style={{ flex: '1 0 3.5%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.currentFolder.table.passport')}
        </div>
      </div>
      <div className="custom-th not-sortable" style={{ flex: '1 0 3.75%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.currentFolder.table.sejour')}
        </div>
      </div>
      <div className="custom-th not-sortable" style={{ flex: '1 0 3.75%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.currentFolder.table.jdd')}
        </div>
      </div>
      <div className="custom-th not-sortable" style={{ flex: '1 0 4%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.currentFolder.table.rib')}
        </div>
      </div>
      <div className="custom-th not-sortable" style={{ flex: '1 0 4%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.currentFolder.table.bs1')}
        </div>
      </div>
      <div className="custom-th not-sortable" style={{ flex: '1 0 3.75%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.currentFolder.table.bs2')}
        </div>
      </div>
      <div className="custom-th not-sortable" style={{ flex: '1 0 3.75%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.currentFolder.table.tax')}
        </div>
      </div>
    </div>
  );

  return (
    <div id="reporting" className="content-body container-fluid px-2 px-sm-0">
      <div className="container">
        <ReportingHeader />
        <div className="mb-5 d-flex flex-row flex-wrap align-items-stretch justify-content-between">
          <h2 className="title">
            {t('reporting.currentFolder.title.first')}
            <br />
            {t('reporting.currentFolder.title.second')}
          </h2>
          <BmwButton
            type="primary"
            className="export"
            label={t('reporting.export')}
            onClick={onExport}
            disabled={loading}
          />
        </div>
        <div className="mt-3">
          <ReportingFilter
            filter={filter}
            setFilter={setFilter}
            setPage={setPage}
            disabled={loading}
            folderIdFilter
          />
        </div>
      </div>
      <div className="my-3 overflow-auto">
        <ReportingTable
          loading={loading}
          setLoading={setLoading}
          columns={columns}
          data={data.map((reporting: ReportingCurrentFolderResponse) =>
            mapToData(reporting),
          )}
          sortableColumns={[
            'folderId',
            'dealershipId',
            'dealershipName',
            'creationDate',
            'endDate',
          ]}
          supHeader={supHeader}
          minWidth="1775px"
          setSort={setSort}
          sort={sort}
        />
      </div>
      <Pagination
        maxPage={maxPage}
        changePage={(newPage: number) => {
          setPage(newPage - 1);
        }}
        disabled={loading}
        currentPage={page + 1}
        minPage={1}
        size={7}
      />
    </div>
  );
};

export default CurrentFoldersView;
