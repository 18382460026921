/**
 * Sofiadoc back end API.
 * API endpoints for front application.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: bmwfinance-e-kyc@reeliant.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ActorCategoryEnum =
  | 'PRIVATE_INDIVIDUAL'
  | 'PROFESSIONAL'
  | 'COMPANY';

export const ActorCategoryEnum = {
  PRIVATEINDIVIDUAL: 'PRIVATE_INDIVIDUAL' as ActorCategoryEnum,
  PROFESSIONAL: 'PROFESSIONAL' as ActorCategoryEnum,
  COMPANY: 'COMPANY' as ActorCategoryEnum,
};
