/**
 * Sofiadoc back end API.
 * API endpoints for front application.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: bmwfinance-e-kyc@reeliant.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type FolderStatusEnum =
  | 'STUDY_SUPPLY_DOCUMENT'
  | 'STUDY_SEND_CONTROL'
  | 'STUDY_ARCHIVE'
  | 'STUDY_SEND_MEDOC'
  | 'STUDY_LAUNCH_DOC_CONTROL'
  | 'STUDY_CONTROL_DOCUMENT'
  | 'STUDY_RETAKE_SUPPLY'
  | 'PAYMENT_SURVEY'
  | 'PAYMENT_SUPPLY_DOCUMENT'
  | 'PAYMENT_SEND_CONTROL'
  | 'PAYMENT_ARCHIVE'
  | 'PAYMENT_SEND_MEDOC'
  | 'PAYMENT_CONTROL_DOCUMENT'
  | 'PAYMENT_RETAKE_SUPPLY'
  | 'PAYMENT_FINAL'
  | 'CANCELLED'
  | 'NO_FOLLOW'
  | 'REFUSED'
  | 'PAYMENT_REFUSED'
  | 'FINANCED';

export const FolderStatusEnum = {
  STUDYSUPPLYDOCUMENT: 'STUDY_SUPPLY_DOCUMENT' as FolderStatusEnum,
  STUDYSENDCONTROL: 'STUDY_SEND_CONTROL' as FolderStatusEnum,
  STUDYARCHIVE: 'STUDY_ARCHIVE' as FolderStatusEnum,
  STUDYSENDMEDOC: 'STUDY_SEND_MEDOC' as FolderStatusEnum,
  STUDYLAUNCHDOCCONTROL: 'STUDY_LAUNCH_DOC_CONTROL' as FolderStatusEnum,
  STUDYCONTROLDOCUMENT: 'STUDY_CONTROL_DOCUMENT' as FolderStatusEnum,
  STUDYRETAKESUPPLY: 'STUDY_RETAKE_SUPPLY' as FolderStatusEnum,
  PAYMENTSURVEY: 'PAYMENT_SURVEY' as FolderStatusEnum,
  PAYMENTSUPPLYDOCUMENT: 'PAYMENT_SUPPLY_DOCUMENT' as FolderStatusEnum,
  PAYMENTSENDCONTROL: 'PAYMENT_SEND_CONTROL' as FolderStatusEnum,
  PAYMENTARCHIVE: 'PAYMENT_ARCHIVE' as FolderStatusEnum,
  PAYMENTSENDMEDOC: 'PAYMENT_SEND_MEDOC' as FolderStatusEnum,
  PAYMENTCONTROLDOCUMENT: 'PAYMENT_CONTROL_DOCUMENT' as FolderStatusEnum,
  PAYMENTRETAKESUPPLY: 'PAYMENT_RETAKE_SUPPLY' as FolderStatusEnum,
  PAYMENTFINAL: 'PAYMENT_FINAL' as FolderStatusEnum,
  CANCELLED: 'CANCELLED' as FolderStatusEnum,
  NOFOLLOW: 'NO_FOLLOW' as FolderStatusEnum,
  REFUSED: 'REFUSED' as FolderStatusEnum,
  PAYMENTREFUSED: 'PAYMENT_REFUSED' as FolderStatusEnum,
  FINANCED: 'FINANCED' as FolderStatusEnum,
};
