import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../utils/utils';
import BmwButton from './form/BmwButton';

type Props = {
  currentPage: number;
  minPage: number;
  maxPage: number;
  disabled?: boolean;
  changePage: Function;
  size?: number;
};

function Pagination(props: Props) {
  const {
    currentPage,
    minPage,
    maxPage,
    disabled = false,
    changePage,
    size = 5,
  } = props;
  const { t } = useTranslation();
  const [pages, setPages] = useState<number[]>([]);

  const getNumbers = useCallback(
    (limit: number, number: number, lower: boolean) => {
      const numbers = [];
      if (lower) {
        for (let i = number; i >= 1; i--) {
          if (currentPage - i >= limit) {
            numbers.push(currentPage - i);
          }
        }
      } else {
        for (let i = 1; i <= number; i++) {
          if (currentPage + i <= limit) {
            numbers.push(currentPage + i);
          }
        }
      }
      return numbers;
    },
    [currentPage],
  );

  const getNeighborhoodNumbers = useCallback(() => {
    // Nombre de chiffres à afficher à gauche de la page actuelle
    const numberLeftSide =
      Math.floor(size / 2) +
      (maxPage - currentPage < Math.floor(size / 2)
        ? Math.floor(size / 2) - maxPage + currentPage
        : 0);
    const lowerNumbers = getNumbers(minPage, numberLeftSide, true);

    // Nombre de chiffres à afficher à droite de la page actuelle
    const numberRightSide = size - 1 - lowerNumbers.length;
    const upperNumbers = getNumbers(maxPage, numberRightSide, false);

    // Liste des nombres
    const numbers = [...lowerNumbers, currentPage, ...upperNumbers];

    // On ajuste les extrémités
    if (!numbers.includes(minPage)) {
      if (!numbers.includes(minPage + 1) && maxPage !== minPage) {
        if (numbers.includes(minPage + 2)) {
          numbers.unshift(minPage + 1);
        } else {
          numbers.unshift(0);
        }
      }
      numbers.unshift(minPage);
    }
    if (!numbers.includes(maxPage)) {
      if (!numbers.includes(maxPage - 1) && maxPage !== minPage) {
        if (numbers.includes(maxPage - 2)) {
          numbers.push(maxPage - 1);
        } else {
          numbers.push(0);
        }
      }
      numbers.push(maxPage);
    }
    return numbers;
  }, [currentPage, maxPage, minPage, size, getNumbers]);

  const getPage = (page: number, index: number) => (
    <div
      key={`page_element_${index}`}
      className={`page${currentPage === page ? ' active' : ''}${
        disabled ? ' disabled' : ''
      }`}
      onClick={() => {
        if (!disabled && page !== 0) {
          changePage(page);
        }
      }}
    >
      <span className="number">{page === 0 ? '...' : formatNumber(page)}</span>
    </div>
  );

  useEffect(() => {
    setPages(getNeighborhoodNumbers());
  }, [currentPage, maxPage, getNeighborhoodNumbers]);

  return (
    <div className="pagination">
      <BmwButton
        label={t('dashboard.table.previous')}
        type="tertiary"
        disabled={currentPage === 1 || disabled}
        className="previous"
        onClick={() => changePage(currentPage - 1)}
      />
      <div className="pages">
        {pages.map((page, index) => getPage(page, index))}
      </div>
      <BmwButton
        label={t('dashboard.table.next')}
        type="tertiary"
        disabled={currentPage === maxPage || disabled}
        className="next"
        onClick={() => changePage(currentPage + 1)}
      />
    </div>
  );
}

export default Pagination;
