import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import {
  ROUTE_REPORTING_AGENCY,
  ROUTE_REPORTING_CURRENT_FOLDER,
  ROUTE_REPORTING_DEALERSHIP,
  ROUTE_REPORTING_FOLDER,
  ROUTE_REPORTING_IMPORT_FOLDER_STATUS,
} from '../../utils/routes';

type Props = {
  path: string;
};

const Reporting = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className="content-body container px-2 px-sm-0">
      <div className="mb-5 d-flex flex-row flex-wrap align-items-stretch justify-content-between">
        <h2 className="title">{t('reporting.title')}</h2>
      </div>
      <div className="shortcuts d-flex flex-row flex-wrap align-items-stretch justify-content-between pt-1 pb-4 mb-1 gap-4">
        <Link to={ROUTE_REPORTING_AGENCY} className="shortcut">
          <div className="card shadow text-center h-100 p-5">
            <div className="card-header d-flex flex-row align-items-center justify-content-center">
              {t('reporting.agence.title.first')}
            </div>
            <div className="card-body">
              {t('reporting.agence.title.second')}
            </div>
          </div>
        </Link>
        <Link to={ROUTE_REPORTING_DEALERSHIP} className="shortcut">
          <div className="card shadow text-center h-100 p-5">
            <div className="card-header d-flex flex-row align-items-center justify-content-center">
              {t('reporting.concession.title.first')}
            </div>
            <div className="card-body">
              {t('reporting.concession.title.second')}
            </div>
          </div>
        </Link>
        <Link to={ROUTE_REPORTING_FOLDER} className="shortcut">
          <div className="card shadow text-center h-100 p-5">
            <div className="card-header d-flex flex-row align-items-center justify-content-center">
              {t('reporting.dossier.title.first')}
            </div>
            <div className="card-body">
              {t('reporting.dossier.title.second')}
            </div>
          </div>
        </Link>
        <Link to={ROUTE_REPORTING_CURRENT_FOLDER} className="shortcut">
          <div className="card shadow text-center h-100 p-5">
            <div className="card-header d-flex flex-row align-items-center justify-content-center">
              {t('reporting.currentFolder.title.first')}
            </div>
            <div className="card-body">
              {t('reporting.currentFolder.title.second')}
            </div>
          </div>
        </Link>
        <Link to={ROUTE_REPORTING_IMPORT_FOLDER_STATUS} className="shortcut">
          <div className="card shadow text-center h-100 p-5">
            <div className="card-header d-flex flex-row align-items-center justify-content-center">
              {t('reporting.importFolderStatus.title.first')}
            </div>
            <div className="card-body">
              {t('reporting.importFolderStatus.title.second')}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Reporting;
