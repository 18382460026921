import BmwButton from '../form/BmwButton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Display from './Display';
import Edit from './Edit';
import { LabelItemResponse } from '../../types/model/labelItemResponse';
import { BACK_OFFICE_LENGTH } from '../../utils/constants';

type Props = {
  data: LabelItemResponse;
  onChange: (newData: LabelItemResponse) => void;
  resetEdit: boolean;
};

const Label = (props: Props) => {
  const { data, onChange, resetEdit } = props;
  const [edit, setEdit] = useState(false as boolean);
  const { t } = useTranslation();

  useEffect(() => setEdit(false), [resetEdit]);

  return (
    <div className="label my-5">
      <div className="border-bottom">
        <h4>{`${data.id} - ${data.name}`}</h4>
      </div>
      <div className="btn-toolbar my-3">
        {edit ? (
          <Edit data={data} onChange={onChange} />
        ) : (
          <Display data={data} />
        )}
        <div className="ml-auto button">
          <BmwButton
            disabled={
              data.labelName.length > BACK_OFFICE_LENGTH.NAME ||
              data.labelDescription.length > BACK_OFFICE_LENGTH.DESCRIPTION
            }
            type={`${edit ? 'primary' : 'tertiary'}` as 'primary' | 'tertiary'}
            onClick={() => setEdit(!edit)}
            label={
              <>
                {t('actions.edit')} <i className="fa fa-pencil" />
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Label;
