declare global {
  interface Window {
    /* eslint-disable @typescript-eslint/naming-convention */
    REACT_APP_ENVIRONMENT?: string;
    REACT_APP_ENVIRONMENT_SMART_PUSH_BASE_URL?: string;
    REACT_APP_ENVIRONMENT_OPEN_BANKING_ACTIVATED?: boolean;
    /* eslint-enable @typescript-eslint/naming-convention */
  }
}

interface ConfigInterface {
  environment: string;
  smartPushUrl: string;
  openBankingActivated: boolean;
}

const configuration: ConfigInterface = {
  environment:
    process.env.REACT_APP_ENVIRONMENT ||
    window.REACT_APP_ENVIRONMENT ||
    'MISSING_REACT_APP_ENVIRONMENT',
  smartPushUrl:
    process.env.REACT_APP_ENVIRONMENT_SMART_PUSH_BASE_URL ||
    window.REACT_APP_ENVIRONMENT_SMART_PUSH_BASE_URL ||
    'MISSING_SMART_PUSH_BASE_URL',
  openBankingActivated:
    process.env.REACT_APP_ENVIRONMENT_OPEN_BANKING_ACTIVATED !== undefined
      ? process.env.REACT_APP_ENVIRONMENT_OPEN_BANKING_ACTIVATED === 'true'
      : window.REACT_APP_ENVIRONMENT_OPEN_BANKING_ACTIVATED !== undefined
      ? window.REACT_APP_ENVIRONMENT_OPEN_BANKING_ACTIVATED
      : true,
};

export default configuration;
