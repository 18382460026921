import { useTranslation } from 'react-i18next';
import BmwYesNoModal from './BmwYesNoModal';
import React from 'react';
import { TypeFolderStore } from '../../types/storeTypes';
import { updateFolderStatus } from '../../state/folder/FolderEffects';
import { FolderStatusFrontUpdateEnum } from '../../types/model/folderStatusFrontUpdateEnum';

type Props = {
  show: boolean;
  handleClose: () => void;
  folderStore: TypeFolderStore;
  setLoading: (loading: boolean) => void;
};

const BmwStudyPhaseModal = (props: Props) => {
  const { show, handleClose, folderStore, setLoading } = props;
  const { t } = useTranslation();
  return (
    <BmwYesNoModal
      show={show}
      handleClose={handleClose}
      title={t('studyPhase.modal.title')}
      yesButton={{
        label: t('studyPhase.modal.yes'),
        onClick: async () => {
          await updateFolderStatus(
            folderStore,
            FolderStatusFrontUpdateEnum.STUDY,
            setLoading,
          );
          handleClose();
        },
      }}
      noButton={{ label: t('studyPhase.modal.no'), onClick: handleClose }}
    >
      {t('studyPhase.modal.description')}
    </BmwYesNoModal>
  );
};

export default BmwStudyPhaseModal;
