import * as React from 'react';
import { ChangeEvent } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';

type Props = {
  type?: 'text' | 'number' | 'date';
  as?: 'textarea';
  rows?: number;
  step?: string;
  prepend?: string;
  append?: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onClick?: (
    e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  value?: string;
  error?: string;
  valid?: boolean;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  onKeyPress?: (e: React.KeyboardEvent) => void;
};

const BmwInput = (props: Props) => {
  const {
    onKeyPress,
    disabled,
    onChange,
    type = 'text',
    step = '1',
    rows = 5,
    as,
    className = '',
    containerClassName = '',
    prepend,
    append,
    value = '',
    error,
    valid,
    onClick,
  } = props;
  return (
    <InputGroup className={containerClassName}>
      {prepend && (
        <InputGroup.Prepend>
          <InputGroup.Text>{prepend}</InputGroup.Text>
        </InputGroup.Prepend>
      )}
      {as ? (
        <FormControl
          onKeyPress={onKeyPress}
          disabled={disabled}
          onClick={onClick}
          as={as}
          rows={rows}
          isInvalid={!!error}
          isValid={valid}
          className={`${className} ${error ? 'error' : ''}`}
          type={type}
          onChange={onChange}
          value={value}
        />
      ) : (
        <FormControl
          onKeyPress={onKeyPress}
          disabled={disabled}
          onClick={onClick}
          min={0}
          as={as}
          rows={rows}
          isInvalid={!!error}
          isValid={valid}
          className={`${className} ${error ? 'error' : ''}`}
          step={step}
          type={type}
          onChange={onChange}
          value={value}
        />
      )}
      {append && (
        <InputGroup.Append>
          <InputGroup.Text>{append}</InputGroup.Text>
        </InputGroup.Append>
      )}
      {error && (
        <FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
      )}
    </InputGroup>
  );
};

export default BmwInput;
