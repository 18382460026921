import React from 'react';
import { Toast } from 'react-bootstrap';
import { useStore } from 'effector-react';
import { ToastStore } from '../state/toast/ToastStore';
import { TypeToastStore } from '../types/storeTypes';
import { removeToast } from '../state/toast/ToastEvents';
import { useTranslation } from 'react-i18next';

const BmwToaster = () => {
  const { t } = useTranslation();
  const toastStore = useStore(ToastStore);
  return (
    <div id="toast-container">
      {toastStore.map((toast: TypeToastStore, index: number) => {
        let { type = 'danger', title = '', message = '' } = toast;
        if (!title || !message) {
          title = title || t(`toast.${type}.title`);
          message = message || t(`toast.${type}.message`);
        }
        return (
          <Toast key={`toast_${index}`} show onClose={() => removeToast()}>
            <Toast.Header>
              <strong className={`text-${type} mr-auto`}>{title}</strong>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
          </Toast>
        );
      })}
    </div>
  );
};

export default BmwToaster;
