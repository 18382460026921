import { createEffect } from 'effector';
import { restIdListHandler } from '../../rest/RestClient';
import { RefusalItemResponse } from '../../types/model/refusalItemResponse';
import { handleParsedResponse } from '../../utils/utils';
import { addRefusals } from './RefusalEvents';
import { REFUSALS } from '../../rest/apiPath';

export const requestGetRefusals = createEffect({
  handler: restIdListHandler<RefusalItemResponse>(REFUSALS.GET),
});

requestGetRefusals.done.watch(({ result }) =>
  handleParsedResponse(result)((data: RefusalItemResponse[]) =>
    addRefusals(data),
  ),
);
