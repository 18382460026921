import React, { Dispatch, SetStateAction } from 'react';
import { ORDER } from '../../utils/constants';
import BmwLoader from '../BmwLoader';
import { TypeSort } from '../../types/tableTypes';
import { TypeReportingFolderAttribute } from '../../types/globalTypes';

interface Column {
  name: string;
  label: string;
  className?: string;
  tooltip?: string;
  accessor: (data: any) => any;
  width?: number;
}

interface Data {
  id: string;
}

type Props = {
  columns: Column[];
  data: Data[];
  total?: Data | null;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  sortableColumns?: string[];
  supHeader?: JSX.Element | any;
  minWidth?: string | null;
  horizontal?: boolean;
  setSort: Dispatch<SetStateAction<TypeSort>>;
  sort: TypeSort;
  headerMarginAuto?: boolean;
  onClick?: (row: Data) => void;
};

const ReportingTable = (props: Props) => {
  const {
    columns,
    data,
    total = null,
    loading,
    sortableColumns = [],
    supHeader = null,
    minWidth = null,
    horizontal = false,
    sort,
    setSort,
    headerMarginAuto = true,
    onClick,
  } = props;

  const onSortChange = (column: Column) => {
    if (sort.name === column.name && sort.order === ORDER.ASC) {
      setSort({
        name: column.name as TypeReportingFolderAttribute,
        order: ORDER.DESC,
      });
    } else {
      setSort({
        name: column.name as TypeReportingFolderAttribute,
        order: ORDER.ASC,
      });
    }
  };

  return (
    <div
      className={`custom-container-table reporting-table ${
        horizontal ? 'horizontal' : ''
      }`}
      style={{ minWidth: minWidth ? minWidth : 'auto' }}
    >
      <div className="custom-table striped">
        {loading && <BmwLoader background />}
        <div className="custom-thead">
          {supHeader}
          <div className="custom-tr">
            {columns.map((column: Column) => {
              const sortable =
                sortableColumns &&
                sortableColumns.length &&
                (sortableColumns.includes(column.name) ||
                  sortableColumns.includes('all'));
              return (
                <div
                  key={column.name}
                  onClick={sortable ? () => onSortChange(column) : () => {}}
                  className={`custom-th wrap d-flex ${
                    sortable ? '' : 'not-sortable'
                  } ${
                    sort.name === column.name
                      ? sort.order === ORDER.ASC
                        ? 'asc'
                        : 'desc'
                      : ''
                  } ${column.className ? column.className : ''}`}
                  style={
                    !!column.width
                      ? {
                          maxWidth: `${column.width}px`,
                          minWidth: `${column.width}px`,
                        }
                      : {}
                  }
                >
                  <div
                    className={`custom-content ${
                      headerMarginAuto ? 'm-auto' : ''
                    }`}
                    title={column.tooltip}
                  >
                    {column.label}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="custom-tbody">
          {data.map((d: Data) => (
            <div
              key={d.id}
              className={`custom-tr-group ${!onClick ? 'no-pointer' : ''}`}
              onClick={() => {
                onClick && onClick(d);
              }}
            >
              <div className="custom-tr">
                {columns.map((column: Column) => (
                  <div
                    key={`${column.name}_${d.id}`}
                    className={`custom-td position-relative ${
                      column.className ? column.className : ''
                    }`}
                    style={
                      !!column.width
                        ? {
                            maxWidth: `${column.width}px`,
                            minWidth: `${column.width}px`,
                          }
                        : {}
                    }
                  >
                    <div className="custom-content">
                      {column.accessor(d[column.name as keyof Data])}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
          {total && (
            <div className="custom-tr-group total-tr no-pointer">
              <div className="custom-tr">
                {columns.map((column: Column) => (
                  <div
                    key={`${column.name}_${total.id}`}
                    className={`custom-td position-relative ${
                      column.className ? column.className : ''
                    }`}
                    style={
                      !!column.width
                        ? {
                            maxWidth: `${column.width}px`,
                            minWidth: `${column.width}px`,
                          }
                        : {}
                    }
                  >
                    <div className="custom-content font-weight-bold">
                      {column.accessor(total[column.name as keyof Data])}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportingTable;
