import { TypeUserStore } from '../types/storeTypes';
import {
  PUBLIC_ROUTES,
  ROUTE_BACK_OFFICE,
  ROUTE_DASHBOARD,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_PREFIX,
  ROUTE_REPORTING,
} from '../utils/routes';
import {
  requestGetUser,
  requestPostLogin,
  requestPostLogout,
  requestPostTestLogin,
} from '../state/user/UserEffets';
import { getQueryVariable, setCookie } from '../utils/utils';
import { navigate } from '@reach/router';
import { setUser } from '../state/user/UserEvents';
import { MeInformationResponse } from '../types/model/meInformationResponse';
import { USERS } from '../rest/apiPath';
import { TestLoginProfileEnum } from '../types/model/testLoginProfileEnum';
import { COOKIES } from '../utils/constants';
import {
  getDashboardProfiles,
  hasOneProfileMatch,
  PROFILES,
} from '../utils/rights';
import { TypeProfile } from '../types/rightTypes';

const SERVER_HOST = '';

export const getUserInformation = (
  userStore: TypeUserStore,
): Promise<MeInformationResponse | undefined> => {
  const currentRoute = window.location.pathname;
  if (!PUBLIC_ROUTES.includes(currentRoute)) {
    return requestGetUser().then(async (res) => {
      const id = getQueryVariable('id');
      if (!res.ok || (userStore.profiles && userStore.profiles.length === 0)) {
        // Unauthorized
        if (id && currentRoute === ROUTE_LOGIN) {
          // CLIENT => Ok
        } else {
          // OPERATOR / DEALER => redirect to back end SAML Login
          window.location.href = `${SERVER_HOST}${USERS.SAML}`;
        }
      } else if (res.data) {
        setUser(res.data);
        if (res.data && res.data.folderId) {
          await navigate(ROUTE_PREFIX + '/folder/' + res.data.folderId);
        }
        return res.data;
      }
    });
  }
  return Promise.resolve(undefined);
};

export const login = async (
  date: string,
  id: string | undefined,
  setShowError: Function,
) => {
  if (id) {
    const payload = {
      token: id,
      birthdate: date,
    };
    const response = await requestPostLogin(payload);
    if (response.ok) {
      const me = await requestGetUser();
      await navigate(
        me.data
          ? ROUTE_PREFIX + '/folder/' + me.data.folderId
          : ROUTE_DASHBOARD,
      );
    } else {
      setShowError(true);
    }
  }
};

export const testLogin = (
  profiles: TestLoginProfileEnum[],
  email: string,
  dealershipSgateIds: string[],
  debug: boolean = false,
): Promise<boolean> =>
  requestPostTestLogin({ profiles, email, dealershipSgateIds }).then(
    async (value) => {
      if (value.ok && !debug) {
        const token = value.data?.token;
        setCookie(COOKIES.SOFIADOC_AUTH, token || '', 1);
        setCookie(COOKIES.PROFILES, profiles.join(';'), 1);
        const me = await requestGetUser();
        if (me.data) {
          const folderId = me.data.folderId;
          const profiles = me.data.profiles as TypeProfile[];
          await navigate(
            folderId
              ? ROUTE_PREFIX + '/folder/' + folderId
              : hasOneProfileMatch(getDashboardProfiles(), profiles)
              ? ROUTE_DASHBOARD
              : profiles.includes(PROFILES.BACK_OFFICE_REPORTING)
              ? ROUTE_REPORTING
              : profiles.includes(PROFILES.BACK_OFFICE_LABEL)
              ? ROUTE_BACK_OFFICE
              : ROUTE_LOGIN,
          );
        }
      } else if (!value.ok) {
        navigate(ROUTE_LOGIN);
      } else {
        return true;
      }
      return false;
    },
  );

export const logout = async () => {
  const response = await requestPostLogout({});
  if (response.ok) {
    window.sessionStorage.removeItem('id');
    await navigate(ROUTE_LOGOUT);
  }
};
