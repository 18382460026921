import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import BmwButton from '../components/form/BmwButton';
import { useTranslation } from 'react-i18next';
import { getQueryVariable } from '../utils/utils';
import { login } from '../services/loginService';
import HtmlParser from 'react-html-parser';

type Props = {
  path: string;
};

export const Login = (props: Props) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<string>('');
  const [showError, setShowError] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setDate(e.target.value);
  };

  const haveToken = !!getQueryVariable('id');
  if (haveToken) {
    window.sessionStorage.setItem('id', getQueryVariable('id') || '');
  }
  return (
    <div
      id="login"
      className={`content-body bg-login px-2 px-sm-0${
        haveToken ? '' : ' align-items-start'
      }`}
    >
      {haveToken ? (
        <div className="custom-card p-4">
          <h2>{t('login.title')}</h2>
          <div>{HtmlParser(t('login.description'))}</div>
          <Form>
            <Form.Group>
              <Form.Label>{t('login.birthdate')}</Form.Label>
              <Form.Control type="date" value={date} onChange={onChange} />
            </Form.Group>
            {showError && (
              <div className="alert alert-danger">{t('login.error')}</div>
            )}
            <BmwButton
              disabled={date === ''}
              onClick={async () => {
                await login(date, getQueryVariable('id'), setShowError);
              }}
              label={t('login.button')}
            />
          </Form>
        </div>
      ) : (
        <div className="logout custom-card-transparent d-flex flex-column align-items-center">
          <h2 className="title">{t('login.title')}</h2>
          <p>{HtmlParser(t('login.noToken'))}</p>
        </div>
      )}
    </div>
  );
};

export default Login;
