import { createStore } from 'effector';
import { TypeHeaderStore, TypeMenu } from '../../types/storeTypes';
import { setBrand, setMenu } from './HeaderEvents';
import { CONF_BRAND } from '../../utils/configuration';
import { TypeBrand } from '../../types/globalTypes';

export const HeaderStore = createStore<TypeHeaderStore>({
  menu: 'STUDY',
  brand: CONF_BRAND,
})
  .on<TypeMenu>(setMenu, (state: TypeHeaderStore, payload: TypeMenu) => ({
    ...state,
    menu: payload,
  }))
  .on<TypeBrand>(setBrand, (state: TypeHeaderStore, payload: TypeBrand) => ({
    ...state,
    brand: payload,
  }));
