import React, { ChangeEvent, useState } from 'react';
import {
  FILE_SIZE,
  FORMAT,
  MAX_IMAGES_PER_DOCUMENT,
} from '../../../utils/constants';
import { addToast } from '../../../state/toast/ToastEvents';
import { useTranslation } from 'react-i18next';
import {
  requestRemoveFile,
  requestUploadFile,
  requestUploadInfo,
} from '../../../state/uploadDocuments/UploadDocumentsEffects';
import { formatNumber, getTotalFileSize } from '../../../utils/utils';
import { TypeUserStore } from '../../../types/storeTypes';
import { useStore } from 'effector-react';
import BmwLoader from '../../BmwLoader';
import { UserStore } from '../../../state/user/UserStore';
import { isOnlyClientAndOptionalBackOffice } from '../../../utils/rights';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { reduceFileSize } from '../../../services/imageCompressionService';
import { DocumentItemFileResponse } from '../../../types/model/documentItemFileResponse';

type Props = {
  loading: boolean;
  files: DocumentItemFileResponse[];
  fileId: string;
  title: string;
  description?: string;
  className?: string;
  disabled?: boolean;
  isUploaded: boolean;
  subtypeId?: string;
  ownerId?: string;
  error?: boolean;
  fileIndex: number;
  excludePdf?: boolean;
  isExtraButton?: boolean;
  isOnePdf?: boolean;
  onAddFile?: (fileData: DocumentItemFileResponse) => void;
  onRemoveFile?: (fileIndex: number) => void;
  isDoubleSided?: boolean;
};

const FileItem = (props: Props) => {
  const userStore = useStore<TypeUserStore>(UserStore);
  const {
    files,
    fileId,
    title,
    description,
    className = '',
    disabled = false,
    isUploaded,
    subtypeId,
    ownerId,
    error,
    loading,
    fileIndex,
    excludePdf = false,
    isExtraButton = false,
    isOnePdf = false,
    onAddFile = () => {},
    onRemoveFile = () => {},
    isDoubleSided = false,
  } = props;
  const { t } = useTranslation();

  const [uploading, setUploading] = useState<boolean>(false);

  const validateFileSize = (
    fileArray: DocumentItemFileResponse[],
    newFileSize: number,
    type: string,
  ): boolean => {
    if (excludePdf) {
      // Multiple images were added to the document and will be merged as a pdf later on
      const totalFileSize =
        getTotalFileSize(fileArray, fileIndex) + newFileSize;
      if (
        totalFileSize >
        FILE_SIZE.COMPRESSED_IMAGE * MAX_IMAGES_PER_DOCUMENT
      ) {
        addToast({
          title: t('toast.file.totalSize.title'),
          message: t('toast.file.totalSize.message', {
            size: formatNumber(totalFileSize / FILE_SIZE.ONE_MB),
            maxSize: formatNumber(
              (FILE_SIZE.COMPRESSED_IMAGE * MAX_IMAGES_PER_DOCUMENT) /
                FILE_SIZE.ONE_MB,
            ),
          }),
        });
        return false;
      }
    } else {
      let maxSize;
      if (isOnePdf) maxSize = FILE_SIZE.ONE_PDF;
      else {
        if (type === FORMAT.PDF) maxSize = FILE_SIZE.PDF;
        else if (type === 'COMPRESSED_IMAGE')
          maxSize = FILE_SIZE.COMPRESSED_IMAGE;
        else maxSize = FILE_SIZE.IMAGE;
      }

      if (newFileSize > maxSize) {
        addToast({
          title: t('toast.file.size.title'),
          message: t('toast.file.size.message', {
            size: formatNumber(newFileSize / FILE_SIZE.ONE_MB),
            maxSize: formatNumber(maxSize / FILE_SIZE.ONE_MB),
          }),
        });
        return false;
      }
    }
    return true;
  };

  const optimizeFilesize = (data: File, callback: Function) => {
    let maxFileSize = FILE_SIZE.COMPRESSED_IMAGE;
    if (FORMAT.IMAGE.includes(data.type) && data.size > maxFileSize) {
      reduceFileSize(data, maxFileSize, 2400, undefined, (res: Blob) => {
        let compressedFile = new File([res], data.name, { type: data.type });
        callback(compressedFile);
      });
    } else {
      callback(data);
    }
  };

  const onFileUpload = (e: ChangeEvent<HTMLInputElement>): void => {
    const targetFiles = e.target.files;
    if (targetFiles && targetFiles.length > 0) {
      const data = targetFiles[0];
      if (acceptedTypes.includes(data.type)) {
        if (validateFileSize(files, data.size, data.type)) {
          setUploading(true);
          const callback = (res: File) => {
            if (
              validateFileSize(
                files,
                res.size,
                res.type === FORMAT.PDF ? FORMAT.PDF : 'COMPRESSED_IMAGE',
              )
            ) {
              requestUploadFile({
                id: fileId,
                file: {
                  fileIndex: fileIndex,
                  file: res,
                },
              })
                .then(() => {
                  requestUploadInfo({
                    id: fileId,
                    subtypeId: subtypeId,
                    ownerId: ownerId,
                    isClient: isOnlyClientAndOptionalBackOffice(
                      userStore.profiles,
                    ),
                  })
                    .then(() => setUploading(false))
                    .catch(() => setUploading(false));
                  onAddFile({
                    name: data.name,
                    fileIndex: fileIndex,
                    fileSize: res.size,
                  });
                })
                .catch(() => setUploading(false));
            } else {
              setUploading(false);
            }
          };
          optimizeFilesize(data, callback);
        }
      } else {
        if (!acceptedTypes.includes(data.type)) {
          addToast({
            title: t('toast.file.type-extra.title'),
            message: t('toast.file.type-extra.message', { type: data.type }),
          });
        }
      }
    }
  };

  const deleteFile = (): void => {
    setUploading(true);
    requestRemoveFile(fileId, fileIndex)
      .then(() => {
        setUploading(false);
        onRemoveFile(fileIndex);
      })
      .catch(() => {
        setUploading(false);
      });
  };

  const acceptedTypes = excludePdf ? FORMAT.IMAGE : FORMAT.ALL;

  interface IConditionalWrapperProps {
    condition: boolean;
    wrapper: (children: JSX.Element) => JSX.Element;
    children: JSX.Element;
  }

  const ConditionalWrapper = ({
    condition,
    wrapper,
    children,
  }: React.PropsWithChildren<IConditionalWrapperProps>) =>
    condition ? wrapper(children) : children;

  if (isExtraButton)
    return (
      <div className="right-align-container">
        <ConditionalWrapper
          condition={disabled}
          wrapper={(children) => (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  {t('folder.tooltip.extraFiles', {
                    maxFiles: MAX_IMAGES_PER_DOCUMENT,
                  })}
                </Tooltip>
              }
            >
              {children}
            </OverlayTrigger>
          )}
        >
          <div
            className={`extra-file ${className} ${isUploaded ? 'valid' : ''} ${
              error ? 'error' : ''
            }`}
          >
            <label className={`extra-file-label ${disabled ? 'disabled' : ''}`}>
              {uploading ? <BmwLoader /> : title}
            </label>
            <input
              disabled={disabled || uploading || loading}
              type="file"
              className={`extra-file-input ${
                disabled ? 'pe-none' : null
              } cursor-pointer${uploading || loading ? ' loading' : ''}`}
              accept={acceptedTypes.join(',')}
              onChange={onFileUpload}
            />
          </div>
        </ConditionalWrapper>
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              {t('folder.tooltip.loadPdf')}
            </Tooltip>
          }
        >
          <div className={'infoContainer'}>
            <div className={'infoIcon'}></div>
          </div>
        </OverlayTrigger>
      </div>
    );
  else
    return (
      <div className={'custom-file-container mb-3'}>
        {description && <p className="description mb-1">{description}</p>}
        <div
          className={`custom-file ${className} ${isUploaded ? 'valid' : ''} ${
            error ? 'error' : ''
          }`}
        >
          <input
            disabled={disabled || uploading || loading}
            type="file"
            className={`custom-file-input cursor-pointer ${
              uploading || loading ? 'loading' : ''
            }`}
            accept={acceptedTypes.join(',')}
            onChange={onFileUpload}
          />
          <label className="custom-file-label d-flex py-0 align-items-center break-content">
            {uploading || loading ? <BmwLoader /> : title}
          </label>
        </div>
        {!isDoubleSided && files.length > 1 && (
          <div
            className={'deleteIcon'}
            onClick={() => {
              if (!loading && !uploading) deleteFile();
            }}
          ></div>
        )}
      </div>
    );
};

export default FileItem;
