import React, { ReactNode } from 'react';
import BmwLoader from '../BmwLoader';

type Props = {
  className?: string;
  label: string | ReactNode;
  type?: 'primary' | 'secondary' | 'tertiary' | 'primary-outlined';
  disabled?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  loading?: boolean;
};

const BmwButton = (props: Props) => {
  const {
    label,
    type = 'primary',
    disabled,
    className = '',
    onClick = () => {},
    loading,
  } = props;
  return (
    <button
      type="button"
      className={`btn btn-${type} ${className}`}
      disabled={disabled || loading}
      onClick={(e: React.SyntheticEvent) => {
        e.stopPropagation();
        if (!loading) {
          onClick(e);
        }
      }}
    >
      {loading ? <BmwLoader /> : label}
    </button>
  );
};

export default BmwButton;
