import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { TypeFilter } from '../../types/tableTypes';
import BmwDropdown, { TypeItem } from '../form/BmwDropdown';
import { TypeDealershipStore, TypeUserStore } from '../../types/storeTypes';
import { useStore } from 'effector-react';
import { DealershipStore } from '../../state/dealership/DealershipStore';
import { DealershipItemResponse } from '../../types/model/dealershipItemResponse';
import { UserStore } from '../../state/user/UserStore';
import { PROFILES } from '../../utils/rights';
import { PAYMENT_STATUS } from '../../utils/constants';

type Props = {
  filter: TypeFilter;
  setFilter: (filter: TypeFilter) => void;
  setPage: (page: number) => void;
  disabled: boolean;
  setActionNeeded: (actionNeeded: boolean) => void;
};

const Filter = (props: Props) => {
  const dealershipStore = useStore<TypeDealershipStore>(DealershipStore);
  const userStore = useStore<TypeUserStore>(UserStore);
  const [search, setSearch] = useState<string>('');
  const [dealerships, setDealerships] = useState([] as TypeItem[]);
  const { filter, setFilter, setPage, disabled, setActionNeeded } = props;
  const { t } = useTranslation();

  const items = [
    { id: '', label: t('dashboard.allStatus') },
    { id: 'STUDY_SUPPLY_DOCUMENT', label: t('status.STUDY_SUPPLY_DOCUMENT') },
    { id: 'STUDY_SEND_CONTROL', label: t('status.STUDY_SEND_CONTROL') },
    { id: 'STUDY_RETAKE_SUPPLY', label: t('status.STUDY_RETAKE_SUPPLY') },
    { id: 'PAYMENT_TO_ARCHIVE', label: t('status.PAYMENT_TO_ARCHIVE') },
    { id: 'PAYMENT_FINAL', label: t('status.PAYMENT_FINAL') },
    { id: 'CANCELLED', label: t('status.CANCELLED') },
    { id: 'NO_FOLLOW', label: t('status.NO_FOLLOW') },
    { id: 'REFUSED', label: t('status.REFUSED') },
    { id: 'PAYMENT_REFUSED', label: t('status.PAYMENT_REFUSED') },
    { id: 'FINANCED', label: t('status.FINANCED') },
  ] as TypeItem[];

  useEffect(() => {
    setDealerships([
      { id: '', label: t('dashboard.allDealerships') },
      ...dealershipStore.dealerships.map((value: DealershipItemResponse) => ({
        id: value.id,
        label: `${value.name}`,
      })),
    ]);
  }, [dealershipStore.dealerships, t]);

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      setFilter({ ...filter, search: (e.target as HTMLInputElement).value });
      setPage(0);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  };

  return (
    <div className="d-flex flex-row align-items-stretch flex-wrap mb-5">
      {!userStore.profiles.includes(PROFILES.RESTRICTED_DEALER) && (
        <div className="mr-3">
          <BmwDropdown
            disabled={disabled}
            onSelect={(key: string | null) => {
              setFilter({ ...filter, dealership: key || '' });
              setPage(0);
            }}
            items={dealerships}
            label={
              filter.dealership
                ? dealerships.find(
                    (value: TypeItem) => value.id === filter.dealership,
                  )?.label
                : t('dashboard.dealership')
            }
          />
        </div>
      )}
      <div className="mr-3">
        <BmwDropdown
          disabled={disabled}
          onSelect={(key: string | null) => {
            if (key === 'PAYMENT_TO_ARCHIVE') {
              setFilter({ ...filter, status: PAYMENT_STATUS });
            } else {
              setFilter({ ...filter, status: !!key ? [key] : [] });
            }
            setPage(0);
            setActionNeeded(false);
          }}
          items={items}
          label={
            filter.status.length > 0
              ? `${t('dashboard.status')} : ${
                  filter.status.length > 1
                    ? t('dashboard.selectedStatus', {
                        count: filter.status.length,
                      })
                    : t(`status.${filter.status}`)
                }`
              : t('dashboard.status')
          }
        />
      </div>
      <div className="flex-grow-1">
        <InputGroup className="mb-0">
          <Form.Control
            disabled={disabled}
            onChange={onChange}
            onKeyPress={onKeyPress}
            type="text"
            placeholder={t('dashboard.searchFile')}
          />
          <InputGroup.Append>
            <Button
              disabled={disabled}
              variant="secondary"
              onClick={() => {
                setFilter({ ...filter, search: search });
                setPage(0);
              }}
            >
              <i className="fa fa-search" />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </div>
    </div>
  );
};

export default Filter;
