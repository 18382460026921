import { TypeModalStore, TypeModalStoreKey } from '../../types/storeTypes';
import { createStore } from 'effector';
import { toggleModal } from './ModalEvents';

export const ModalStore = createStore<TypeModalStore>({}).on(
  toggleModal,
  (state: TypeModalStore, payload: TypeModalStoreKey) => {
    return { ...state, [payload]: !state[payload] };
  },
);
