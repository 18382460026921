import { RefusalItemResponse } from '../../types/model/refusalItemResponse';
import { createStore } from 'effector';
import { addRefusals, clearRefusals } from './RefusalEvents';

export const RefusalStore = createStore<RefusalItemResponse[]>([])
  .on(
    addRefusals,
    (state: RefusalItemResponse[], payload: RefusalItemResponse[]) => [
      ...state,
      ...payload,
    ],
  )
  .on(clearRefusals, () => []);
