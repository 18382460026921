import { TypeDealershipStore } from '../../types/storeTypes';
import { createStore } from 'effector';
import { selectDealership, setDealerships } from './DealershipEvents';
import { DealershipItemResponse } from '../../types/model/dealershipItemResponse';

export const DealershipStore = createStore<TypeDealershipStore>({
  selected: '',
  dealerships: [],
})
  .on(
    setDealerships,
    (state: TypeDealershipStore, payload: DealershipItemResponse[]) => ({
      selected: !state.selected && payload[0] ? payload[0].id : state.selected,
      dealerships: payload,
    }),
  )
  .on(selectDealership, (state: TypeDealershipStore, payload: string) => ({
    ...state,
    selected: payload,
  }));
