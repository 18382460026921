import { TypeActor } from '../types/globalTypes';
import { TypeFolderStore, TypeUserStore } from '../types/storeTypes';
import { CLIENT_TYPE, PROFILES } from '../utils/rights';

export const isFolderComplete = (
  actors: TypeActor[],
  folder: TypeFolderStore,
): boolean =>
  !actors.find((actor: TypeActor) => !actor.completed) && folder.completed;

export const isActorAfter = (
  actor: TypeActor,
  actualActor: TypeActor | null,
): boolean => {
  switch (actualActor?.type) {
    case undefined:
      return !actor.completed;
    case CLIENT_TYPE.PRIMARY:
      return (
        !actor.completed &&
        (actor.type === CLIENT_TYPE.SECONDARY ||
          actor.type === CLIENT_TYPE.TERTIARY)
      );
    case CLIENT_TYPE.SECONDARY:
      return !actor.completed && actor.type === CLIENT_TYPE.TERTIARY;
    default:
      return false;
  }
};

export const getNextActor = (
  actualActor: TypeActor | null,
  userStore: TypeUserStore,
  initialActorStore: TypeActor[],
): TypeActor | undefined => {
  if (userStore.profiles.includes(PROFILES.CLIENT)) {
    return initialActorStore.find((a) => a.id === userStore.actorId);
  } else {
    return initialActorStore.find((a) => isActorAfter(a, actualActor));
  }
};
