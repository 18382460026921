import React, { useEffect, useState } from 'react';
import BmwButton from '../components/form/BmwButton';
import { useTranslation } from 'react-i18next';
import Label from '../components/back-office/Label';
import { restListHandler, restListPatchHandler } from '../rest/RestClient';
import { ParsedResponse } from '../rest/ServerResponseParse';
import { LabelItemResponse } from '../types/model/labelItemResponse';
import { handleParsedResponse } from '../utils/utils';
import { addToast } from '../state/toast/ToastEvents';
import { BACK_OFFICE_LENGTH } from '../utils/constants';
import { LABELS } from '../rest/apiPath';

type Props = {
  path: string;
};

const BackOffice = (props: Props) => {
  const { t } = useTranslation();
  const [initialData, setInitialData] = useState<LabelItemResponse[]>([]);
  const [datas, setDatas] = useState<LabelItemResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [resetEdit, setResetEdit] = useState<boolean>(false);

  useEffect(() => {
    restListHandler<LabelItemResponse>(LABELS.GET)()
      .then((res: ParsedResponse<LabelItemResponse[]>) =>
        handleParsedResponse(res)((data: LabelItemResponse[]) => {
          setInitialData(data);
          setDatas(data);
        }),
      )
      .finally(() => setLoading(false));
  }, []);

  const save = () => {
    setLoading(true);
    setResetEdit(!resetEdit);
    restListPatchHandler<LabelItemResponse, LabelItemResponse>(LABELS.UPDATE)(
      datas,
    )
      .then((res: ParsedResponse<LabelItemResponse[]>) =>
        handleParsedResponse(res)((data: LabelItemResponse[]) => {
          setInitialData(data);
          addToast({
            type: 'success',
            title: t('toast.update.title'),
            message: t('toast.update.message'),
          });
        }),
      )
      .finally(() => setLoading(false));
  };

  const cancel = () => {
    setResetEdit(!resetEdit);
    setDatas(initialData);
  };

  const isEqual = JSON.stringify(initialData) === JSON.stringify(datas);

  const hasError = datas.find(
    (data) =>
      data.labelName.length > BACK_OFFICE_LENGTH.NAME ||
      data.labelDescription.length > BACK_OFFICE_LENGTH.DESCRIPTION,
  );

  return (
    <div className="content-body container px-2 px-sm-0">
      <div id="back-office">
        <h2 className="title">{t('back-office.labels.title')}</h2>
        {datas.map((data: LabelItemResponse, index: number) => (
          <Label
            key={`label_${index}`}
            data={data}
            onChange={(newData: LabelItemResponse) =>
              setDatas(
                datas.map((d) => {
                  if (d.id === newData.id) {
                    return newData;
                  }
                  return d;
                }),
              )
            }
            resetEdit={resetEdit}
          />
        ))}
        <div className="d-flex justify-content-center buttons">
          <BmwButton
            loading={loading}
            onClick={save}
            disabled={isEqual || !!hasError}
            className="mr-5"
            label={t('actions.save')}
          />
          <BmwButton
            onClick={cancel}
            disabled={isEqual}
            type="secondary"
            label={t('actions.cancel')}
          />
        </div>
      </div>
    </div>
  );
};

export default BackOffice;
