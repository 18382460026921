import React from 'react';
import { formatNumber } from '../../utils/utils';

type Props = {
  icon: string;
  count: number;
  label: string;
  onClick: () => void;
  disabled: boolean;
  selected: boolean;
};

const Shortcut = (props: Props) => {
  const { icon, count, label, onClick, disabled, selected } = props;
  return (
    <div className={`shortcut${disabled ? ' disabled' : ''}`}>
      <div
        onClick={() => onClick && !disabled && onClick()}
        className={`card shadow text-center h-100${selected ? ' active' : ''}`}
      >
        <div className="card-header d-flex flex-row align-items-center justify-content-center">
          <i className={icon} />
          <div className="pl-3 pr-3">
            <span className="text-warning text-nowrap">
              {formatNumber(count)}
            </span>
          </div>
        </div>
        <div className="card-body">
          <span>{label}</span>
        </div>
      </div>
    </div>
  );
};

export default Shortcut;
