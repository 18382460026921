import { createStore } from 'effector';
import { addDocumentType, clearDocumentType } from './DocumentTypeEvents';
import { DocumentTypeItemResponse } from '../../types/model/documentTypeItemResponse';

export const DocumentTypeStore = createStore<DocumentTypeItemResponse[]>([])
  .on(
    addDocumentType,
    (
      state: DocumentTypeItemResponse[],
      payload: DocumentTypeItemResponse[],
    ) => [...state, ...payload],
  )
  .on(clearDocumentType, () => []);
