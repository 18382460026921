const PREFIX = '/api/front';

const FOLDER_PREFIX = 'folders';
const ACTOR_PREFIX = 'actors';
const INVITATION_PREFIX = 'invitations';
const DOCUMENT_PREFIX = 'documents';
const DOCUMENT_TYPE_PREFIX = 'documentTypes';
const DOCUMENT_CLASSIFY_PREFIX = 'classifier';
const DEALERSHIP_PREFIX = 'dealerships';
const REFUSAL_PREFIX = 'refusals';
const LABEL_PREFIX = 'labels';
const AUTHENTICATION_PREFIX = 'authentication';
const USER_PREFIX = 'users';
const REPORTING_PREFIX = 'reportings';
const EXPORT_PREFIX = 'exports';
const SMART_PUSH_PREFIX = 'smart-push';

export const FOLDERS = {
  GET: `${PREFIX}/${FOLDER_PREFIX}`,
  COUNT: `${PREFIX}/${FOLDER_PREFIX}/count`,
  FOLDER: `${PREFIX}/${FOLDER_PREFIX}/{id}`,
  ONE_PDF: `${PREFIX}/${FOLDER_PREFIX}/{id}/onePdf`,
  STATUS: `${PREFIX}/${FOLDER_PREFIX}/{id}/status`,
  MAILING_LIST: `${PREFIX}/${FOLDER_PREFIX}/{id}/mailingList`,
  COMPLETION: `${PREFIX}/${FOLDER_PREFIX}/{id}/completion`,
  STUDY_PROCESS: `${PREFIX}/${FOLDER_PREFIX}/{id}/study/process`,
  FEEDBACK_SUBMIT: `${PREFIX}/${FOLDER_PREFIX}/{id}/feedbackSubmit`,
  IMPORT_STATUS: `${PREFIX}/${FOLDER_PREFIX}/import-status`,
};

export const ACTORS = {
  GET: `${PREFIX}/${ACTOR_PREFIX}?folderId={id}`,
  COMPLETION: `${PREFIX}/${ACTOR_PREFIX}/{id}/completion`,
  REMINDER: `${PREFIX}/${ACTOR_PREFIX}/{id}/reminder`,
};

export const INVITATIONS = {
  SEND: `${PREFIX}/${INVITATION_PREFIX}`,
  COUNT: `${PREFIX}/${INVITATION_PREFIX}/count`,
  LAST: `${PREFIX}/${INVITATION_PREFIX}/last?actorId={id}`,
};

export const DOCUMENTS = {
  GET_ACTORS: `${PREFIX}/${DOCUMENT_PREFIX}?actorId={id}`,
  GET_FOLDERS: `${PREFIX}/${DOCUMENT_PREFIX}?folderId={id}`,
  CREATE: `${PREFIX}/${DOCUMENT_PREFIX}`,
  PAYMENT: `${PREFIX}/${DOCUMENT_PREFIX}/payment?folderId={id}`,
  UPDATE: `${PREFIX}/${DOCUMENT_PREFIX}/{id}`,
  UPLOAD: `${PREFIX}/${DOCUMENT_PREFIX}/{id}/file`,
  UPDATE_REFUSAL: `${PREFIX}/${DOCUMENT_PREFIX}/{id}/refusalId`,
  UPDATE_IGNORE: `${PREFIX}/${DOCUMENT_PREFIX}/{id}/ignore`,
  UPDATE_MANDATORY: `${PREFIX}/${DOCUMENT_PREFIX}/{id}/mandatory`,
  THRESHOLDS: `${PREFIX}/${DOCUMENT_PREFIX}/thresholds`,
};

export const DOCUMENT_TYPES = {
  GET: `${PREFIX}/${DOCUMENT_TYPE_PREFIX}?category={id}`,
};

export const DOCUMENT_CLASSIFY = {
  POST: `${PREFIX}/${DOCUMENT_CLASSIFY_PREFIX}?asynchronous=true`,
  GET: `${PREFIX}/${DOCUMENT_CLASSIFY_PREFIX}/{id}`,
};

export const DEALERSHIPS = {
  GET: `${PREFIX}/${DEALERSHIP_PREFIX}`,
};

export const REFUSALS = {
  GET: `${PREFIX}/${REFUSAL_PREFIX}?type={id}`,
};

export const LABELS = {
  GET: `${PREFIX}/${LABEL_PREFIX}`,
  UPDATE: `${PREFIX}/${LABEL_PREFIX}`,
};

export const USERS = {
  GET: `${PREFIX}/${USER_PREFIX}/me`,
  SAML: `${PREFIX}/${USER_PREFIX}/saml`,
  AUTHENTICATE: `${PREFIX}/${AUTHENTICATION_PREFIX}/client`,
  LOGOUT: `${PREFIX}/${USER_PREFIX}/logout`,
  TEST_LOGIN: `${PREFIX}/testLogin`,
};

export const REPORTINGS = {
  FOLDER: `${PREFIX}/${REPORTING_PREFIX}/folder`,
  CURRENT_FOLDER: `${PREFIX}/${REPORTING_PREFIX}/current-folder`,
  DEALERSHIP: `${PREFIX}/${REPORTING_PREFIX}/dealership`,
  AGENCY: `${PREFIX}/${REPORTING_PREFIX}/agency`,
};

export const EXPORTS = {
  FOLDER: `${PREFIX}/${EXPORT_PREFIX}/folder`,
  CURRENT_FOLDER: `${PREFIX}/${EXPORT_PREFIX}/current-folder`,
  DEALERSHIP: `${PREFIX}/${EXPORT_PREFIX}/dealership`,
  AGENCY: `${PREFIX}/${EXPORT_PREFIX}/agency`,
};

export const SMART_PUSH = {
  AUTH: `${PREFIX}/${SMART_PUSH_PREFIX}/{id}`,
};
