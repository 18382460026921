/**
 * Sofiadoc back end API.
 * API endpoints for front application.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: bmwfinance-e-kyc@reeliant.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type FolderStatusFrontUpdateEnum = 'STUDY';

export const FolderStatusFrontUpdateEnum = {
  STUDY: 'STUDY' as FolderStatusFrontUpdateEnum,
};
