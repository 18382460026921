import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import BmwButton from '../form/BmwButton';
import { useTranslation } from 'react-i18next';
import UnbindPage from '../folder/study/UnbindPage';
import {
  SubtypeOption,
  TypeUnbindInstanceIdentifier,
  TypeUnbindPage,
  TypeUnbindPageInstance,
  TypeUnbindPageInstanceSubmit,
  TypeUnbindPageModifications,
  TypeUnbindPageSubmit,
} from '../../types/unbindTypes';
import {
  requestClassifyFile,
  requestGetClassifyResult,
} from '../../state/documentClassify/DocumentClassifyEffects';
import { DocumentClassifyResponse } from '../../types/model/documentClassifyResponse';
import { FORMAT } from '../../utils/constants';
import { addToast } from '../../state/toast/ToastEvents';
import { useStore } from 'effector-react/effector-react.cjs';
import { DocumentTypeItemResponse } from '../../types/model/documentTypeItemResponse';
import { DocumentTypeStore } from '../../state/documentType/DocumentTypeStore';
import { TypeActor, TypeDocument } from '../../types/globalTypes';
import { ActorStore } from '../../state/actor/ActorStore';
import { DocumentsStore } from '../../state/documents/DocumentsStore';
import { clearDocuments } from '../../state/documents/DocumentsEvents';
import {
  requestAddDocument,
  requestGetDocumentActorAppend,
  requestGetDocumentFolderAppend,
} from '../../state/documents/DocumentsEffects';
import BmwYesNoModal from './BmwYesNoModal';
import ReactHtmlParser from 'react-html-parser';
import { PDFDocument } from 'pdf-lib';
import {
  requestRemoveFile,
  requestUploadFile,
  requestUploadInfo,
} from '../../state/uploadDocuments/UploadDocumentsEffects';
import { TypeItem } from '../form/BmwDropdown';
import { getActorName, isMandatory } from '../../utils/utils';
import { DocumentSourceEnum } from '../../types/model/documentSourceEnum';
import { TypeFolderStore, TypeUserStore } from '../../types/storeTypes';
import {
  isDealer,
  isOnlyClientAndOptionalBackOffice,
  isOperator,
  PROFILES,
} from '../../utils/rights';
import { UserStore } from '../../state/user/UserStore';
import { ActorCategoryEnum } from '../../types/model/actorCategoryEnum';
import { DocumentTargetEnum } from '../../types/model/documentTargetEnum';
import i18next from 'i18next';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { FolderStore } from '../../state/folder/FolderStore';
import { ParsedResponse } from '../../rest/ServerResponseParse';

type Props = {
  show: boolean;
  closeUnbindModal: () => void;
  onUnbindDocument: (documentName?: string) => void;
  title: string;
};

const BmwUnbindModal = (props: Props) => {
  const { show, closeUnbindModal, title, onUnbindDocument } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [lastSelectedIndex, setLastSelectedIndex] = useState<
    number | undefined
  >(undefined);
  const [isConfirmModalVisible, setIsConfirmModalVisible] =
    useState<boolean>(false);
  const [isConfirmRestartModalVisible, setIsConfirmRestartModalVisible] =
    useState<boolean>(false);
  const [documentName, setDocumentName] = useState<string | undefined>(
    undefined,
  );
  const [pages, setPages] = useState<TypeUnbindPage[]>([]);
  const [declassifiedPages, setDeclassifiedPages] = useState<TypeUnbindPage[]>(
    [],
  );
  const [images, setImages] = useState<string[]>([]);
  const [fileData, setFileData] = useState<any>();
  const [errors, setErrors] = useState<string[]>([]);
  const [pageCount, setPageCount] = useState<number | undefined>(undefined);
  const [thumbnailIndex, setThumbnailIndex] = useState<number>(0);
  const [thumbnailProgressValue, setThumbnailProgressValue] =
    useState<number>(0);
  const [displayedProgressValue, setDisplayedProgressValue] =
    useState<number>(0);
  const canvasRef: React.LegacyRef<any> = useRef(undefined);
  const [fetchAttempts, setFetchAttempts] = useState<number>(0);
  const fetchIntervalId = useRef<NodeJS.Timeout | null>(null);
  const [
    thumbnailGenerationTimeByPercent,
    setThumbnailGenerationTimeByPercent,
  ] = useState<number | undefined>(undefined);
  const progressBarIntervalId = useRef<NodeJS.Timeout | number | null>(null);
  const { t } = useTranslation();

  const documentTypeStore =
    useStore<DocumentTypeItemResponse[]>(DocumentTypeStore);
  const actorStore = useStore<TypeActor[]>(ActorStore);
  const documentsStore = useStore<TypeDocument[]>(DocumentsStore);
  const userStore = useStore<TypeUserStore>(UserStore);
  const folderStore = useStore<TypeFolderStore>(FolderStore);

  const checkProgressBar = useCallback(() => {
    if (
      displayedProgressValue &&
      displayedProgressValue >= thumbnailProgressValue
    ) {
      stopProgressBarInterval();
    }
  }, [displayedProgressValue, thumbnailProgressValue]);

  const resetData = (resetAll?: boolean) => {
    if (resetAll) {
      setDeclassifiedPages([]);
      setPages([]);
      setDocumentName(undefined);
      setFileData(undefined);
      setImages([]);
      onUnbindDocument(undefined);
    } else {
      setPages(declassifiedPages);
      onUnbindDocument(undefined);
    }
    setErrors([]);
    setThumbnailIndex(0);
    setLastSelectedIndex(undefined);
    setSubmitting(false);
    setLoading(false);
    setFetchAttempts(0);
    setThumbnailProgressValue(0);
    setDisplayedProgressValue(0);
    stopFetchInterval();
    setThumbnailGenerationTimeByPercent(undefined);
    progressBarIntervalId.current = null;
    fetchIntervalId.current = null;
  };

  useEffect(() => {
    if (show) {
      clearDocuments();
      if (userStore.profiles.includes(PROFILES.CLIENT)) {
        if (userStore.actorId) {
          requestGetDocumentActorAppend({
            id: userStore.actorId,
          });
        }
      } else {
        actorStore.forEach((actor) => {
          requestGetDocumentActorAppend({
            id: actor.id,
          });
        });
        requestGetDocumentFolderAppend({
          id: folderStore.id,
        });
      }
    }
  }, [show, actorStore, folderStore.id, userStore.actorId, userStore.profiles]);

  useEffect(() => {
    if (fetchAttempts > 24) {
      stopFetchInterval();
      resetData(true);
      closeUnbindModal();
      addToast({
        type: 'danger',
        title: i18next.t('toast.danger.title'),
        message: i18next.t('folder.unbind.toast.danger.message'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAttempts, closeUnbindModal]);

  useEffect(() => {
    if (pages && documentName) {
      onUnbindDocument(documentName);
    }
  }, [pages, onUnbindDocument, documentName]);

  useEffect(() => {
    if (
      !progressBarIntervalId.current &&
      thumbnailGenerationTimeByPercent !== undefined &&
      thumbnailProgressValue
    ) {
      progressBarIntervalId.current = setInterval(
        updateDisplayedProgress,
        thumbnailGenerationTimeByPercent,
      );
    }
  }, [thumbnailProgressValue, thumbnailGenerationTimeByPercent]);

  useEffect(() => {
    checkProgressBar();
  }, [displayedProgressValue, checkProgressBar]);

  const updateDisplayedProgress = () => {
    setDisplayedProgressValue((value) => value + 1);
  };

  const fetchClassifyResults = (
    result: ParsedResponse<DocumentClassifyResponse>,
  ) => {
    const id = JSON.parse(result.data as unknown as string)?.id;
    setFetchAttempts((attempts) => attempts + 1);

    if (id) {
      requestGetClassifyResult({
        id: id,
        query: {
          folderId: folderStore.id,
        },
      })
        .then((result) => {
          if (result && result.ok && result.data) {
            if (result.data.pages && result.data.pages.length) {
              handleClassifyResponse(result.data);
            }
          } else {
            setLoading(false);
            addToast({
              type: 'danger',
              title: i18next.t('toast.danger.title'),
              message: i18next.t('folder.unbind.toast.danger.message'),
            });
            resetData(true);
            closeUnbindModal();
          }
        })
        .catch((error) => {});
    }
  };

  const handleClassifyResponse = async (response: DocumentClassifyResponse) => {
    stopFetchInterval();

    var pageList: TypeUnbindPage[] = [];
    for (const pageData of response.pages) {
      const page: TypeUnbindPage = {
        pageNumber: pageData.index,
        data: pageData.data,
        id: pageData.index.toString(),
        instances: [{ id: 0 }],
      };
      if (pageData.type !== undefined) {
        if (documentTypeOptions.some((type) => type.id === pageData.type)) {
          page.instances[0].type = pageData.type;
          page.instances[0].subType = pageData.subtype;
        }
      }
      pageList.push(page);
    }
    pageList = pageList.sort((a, b) => (a.pageNumber > b.pageNumber ? 1 : -1));
    setPages(pageList);
    setDeclassifiedPages(JSON.parse(JSON.stringify(pageList)));
    setLoading(false);
  };

  const stopFetchInterval = () => {
    if (fetchIntervalId.current) clearInterval(fetchIntervalId.current);
  };

  const stopProgressBarInterval = () => {
    if (progressBarIntervalId.current)
      clearInterval(progressBarIntervalId.current as NodeJS.Timeout);
    progressBarIntervalId.current = null;
    setThumbnailGenerationTimeByPercent(0);
  };

  const requestClassifyDocuments = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folderId', folderStore.id);

    requestClassifyFile(formData).then((result) => {
      if (result && result.ok && result.data) {
        fetchIntervalId.current = setInterval(() => {
          fetchClassifyResults(result);
        }, 5000);
      } else {
        setLoading(false);
        addToast({
          type: 'danger',
          title: i18next.t('toast.danger.title'),
          message: i18next.t('folder.unbind.toast.danger.message'),
        });
        resetData(true);
        closeUnbindModal();
      }
    });
  };

  const handleSubmit = () => {
    const validatedPages = pages.filter((page) => page.validated);
    let validatedPageInstances: TypeUnbindPageInstanceSubmit[] = [];
    validatedPages.forEach((p) =>
      p.instances.forEach((i) => {
        validatedPageInstances.push({
          id: p.id,
          instanceId: i.id,
          pageNumber: p.pageNumber,
          data: p.data,
          type: i.type,
          subType: i.subType,
          actor: i.actor,
        } as TypeUnbindPageInstanceSubmit);
      }),
    );
    const processedPages: TypeUnbindInstanceIdentifier[] = [];
    const promises: Promise<void>[] = [];
    validatedPageInstances.forEach((page) => {
      if (
        !processedPages.find(
          (item) => item.id === page.id && item.instanceId === page.instanceId,
        )
      ) {
        const existingDocument = findExistingDocument(page);
        let pagesToSave: TypeUnbindPageInstanceSubmit[] =
          validatedPageInstances.filter(
            (i) =>
              i.subType === page.subType &&
              i.type === page.type &&
              i.actor === page.actor,
          );
        pagesToSave.forEach((p) =>
          processedPages.push({ id: p.id, instanceId: p.instanceId }),
        );
        promises.push(prepareDocument(existingDocument, page, pagesToSave));
      }
    });

    Promise.all(promises)
      .then(() => {
        resetData();
        closeUnbindModal();
      })
      .catch((e) => {
        setSubmitting(false);
        addToast({
          type: 'danger',
          title: i18next.t('toast.danger.title'),
          message: e.message,
        });
      });
  };

  const prepareDocument = async (
    document: TypeDocument | undefined | null,
    page: TypeUnbindPageSubmit,
    pagesToSave: TypeUnbindPageSubmit[],
  ) => {
    return new Promise<void>((success, failure) => {
      if (document && document?.id) {
        if (document?.subtype !== page.subType) {
          const type = documentTypeStore.find((t) => t.id === page.type);
          const subtype = type?.subtypes.find((s) => s.id === page.subType);
          const actorId = page.actor ? JSON.parse(page.actor).id : null;
          const ownerId = subtype?.possibleOtherOwner ? actorId : null;
          requestUploadInfo({
            id: document.id,
            ownerId: ownerId,
            subtypeId: page.subType,
            isClient: isOnlyClientAndOptionalBackOffice(userStore.profiles),
          })
            .then(() => {
              removeDocumentFiles(document, () => {
                addFiles(
                  document.id,
                  page.subType,
                  ownerId,
                  pagesToSave,
                  success,
                  failure,
                );
              });
            })
            .catch(() => {
              failure();
            });
        }
      } else {
        addDocument(page, (documentId: string, ownerId: string) => {
          addFiles(
            documentId,
            page.subType,
            ownerId,
            pagesToSave,
            success,
            failure,
          );
        });
      }
    });
  };

  const checkTypeDuplicates = (pagesToCheck: TypeUnbindPageSubmit[]) => {
    const groupedTypeSubtype: Map<string, string[]> = pagesToCheck.reduce(
      (entryMap, e) => {
        const typeActor = JSON.stringify({
          type: e.type,
          actor: e.actor ? JSON.parse(e.actor).id : undefined,
        });
        if (
          entryMap &&
          entryMap.get &&
          !entryMap.get(typeActor)?.includes(e.subType)
        )
          return entryMap.set(typeActor, [
            ...(entryMap.get(typeActor) || []),
            e.subType,
          ]);
        return entryMap;
      },
      new Map(),
    );
    var errors: string[] = [];

    groupedTypeSubtype.forEach((subtypes, typeActor) => {
      if (subtypes.length > 1) {
        const typeActorObj: { type: string; actor: string | undefined } =
          JSON.parse(typeActor);
        const typeData = documentTypeStore.find(
          (d) => d.id === typeActorObj.type,
        );
        const actorData = actorStore.find((d) => d.id === typeActorObj.actor);
        errors.push(
          t('folder.unbind.confirm.errors.description', {
            subtypeLabels: subtypes
              .map(
                (sub) =>
                  typeData?.subtypes.find((s) => s.id === sub)?.labelName,
              )
              .join(', '),
            typeLabel: documentTypeOptions.find(
              (d) => d.id === typeActorObj.type,
            )?.label,
            actorStr: actorData
              ? " pour l'acteur " + getActorName(actorData)
              : '',
          }),
        );
      }
    });
    setErrors(errors);
  };

  const mergeFilesData = async (pagesToSave: TypeUnbindPageSubmit[]) => {
    const pdfDoc = await PDFDocument.create();

    const donorPdfDoc = await PDFDocument.load(fileData);
    for (const page of pagesToSave) {
      const [donorPage] = await pdfDoc.copyPages(donorPdfDoc, [
        page.pageNumber,
      ]);
      pdfDoc.addPage(donorPage);
    }
    const byteArray: Uint8Array[] = [];
    const data: Uint8Array = await pdfDoc.save();
    byteArray.push(data);
    const blob: Blob = new Blob(byteArray, { type: 'application/pdf' });
    return blob;
  };

  const addFiles = async (
    documentId: string,
    subtypeId: string | undefined,
    actorId: string | undefined,
    pagesToSave: TypeUnbindPageSubmit[],
    onFilesAdded: Function,
    onFail: Function,
  ) => {
    const data: Blob = await mergeFilesData(pagesToSave);
    const file: File = new File(
      [data],
      `${documentName?.replace(/\.[^/.]+$/, '') ?? 'declassifier'}_${
        pagesToSave[0].type
      }_${pagesToSave[0].subType}.pdf`,
    );

    requestUploadFile({
      id: documentId,
      file: {
        fileIndex: 0,
        file: file,
      },
    })
      .then(() => {
        requestUploadInfo({
          id: documentId,
          subtypeId: subtypeId,
          ownerId: actorId,
          isClient: isOnlyClientAndOptionalBackOffice(userStore.profiles),
        });
        onFilesAdded();
      })
      .catch(() => {
        onFail();
      });
  };

  const addDocument = (page: TypeUnbindPageSubmit, callback: Function) => {
    if (page.type && page.actor) {
      var source: DocumentSourceEnum;
      if (isDealer(userStore.profiles))
        source = DocumentSourceEnum.DEALEROPTIONAL;
      else if (isOperator(userStore.profiles))
        source = DocumentSourceEnum.OPERATOROPTIONAL;
      else source = DocumentSourceEnum.CLIENTOPTIONAL;

      const actorId = JSON.parse(page.actor).id;
      const target = JSON.parse(page.actor).target;

      const type = documentTypeStore.find((t) => t.id === page.type);
      const subtype = type?.subtypes.find((s) => s.id === page.subType);
      const ownerId = subtype?.possibleOtherOwner ? actorId : null;

      requestAddDocument({
        typeId: page?.type,
        actorId: actorId,
        source: source,
        target: target,
      }).then((result) => {
        if (result.ok && result.data) {
          callback(result.data.id, ownerId);
        } else {
          setLoading(false);
          if (result.errorMessage) {
            addToast({
              type: 'danger',
              title: i18next.t('toast.danger.title'),
              message: result.errorMessage,
            });
          }
        }
      });
    }
  };

  const removeDocumentFiles = (
    document: TypeDocument,
    createFiles: Function,
  ) => {
    var promises: Promise<void>[] = [];
    document.files.forEach((file) => {
      if (file.fileIndex > 0)
        promises.push(requestRemoveFile(document.id, file.fileIndex));
    });

    Promise.all(promises).then(() => {
      createFiles(document.id, () => {});
    });
  };

  const isUncheckEnabled: boolean = useMemo(() => {
    return pages && pages.some((page) => page.checked);
  }, [pages]);

  const isDocumentClassified = useCallback(
    (document: TypeDocument, excludePageId: string | undefined): boolean => {
      for (const page of pages) {
        const foundClassifiedDocumentIndex = page.instances.findIndex(
          (i) =>
            i.id.toString() !== excludePageId &&
            i.type === document.type.id &&
            (!document.actorId ||
              (i.actor &&
                JSON.parse(i.actor).id === document.actorId &&
                JSON.parse(i.actor).target === document.target)),
        );
        if (foundClassifiedDocumentIndex !== -1) {
          return true;
        }
      }
      return false;
    },
    [pages],
  );

  const documentTypeOptions: TypeItem[] = useMemo(() => {
    if (documentTypeStore) {
      const newTypeOptions: TypeItem[] = documentTypeStore
        .filter(
          (type) =>
            (type.category === 'ACTOR' && type.isAvailableClassify) ||
            documentsStore.some((doc) => doc.type.id === type.id),
        )
        .map((type) => {
          return { label: type.labelName ?? '', id: type.id } as TypeItem;
        });
      newTypeOptions.forEach((option, index) => {
        const existingDocuments = documentsStore.filter(
          (doc) =>
            doc.type?.id === option.id &&
            doc.pending &&
            isMandatory(doc.source),
        );
        if (existingDocuments.length > 0) {
          // There are mandatory documents left to upload
          const remainingToClassifyCount = existingDocuments.filter(
            (doc) => !isDocumentClassified(doc, undefined),
          ).length;
          if (remainingToClassifyCount > 0) {
            // There are mandatory documents left to upload that haven't been classified yet
            newTypeOptions[index].optionLabel = `${
              newTypeOptions[index].label
            } (${remainingToClassifyCount} manquant${
              remainingToClassifyCount > 1 ? 's' : ''
            })`;
            newTypeOptions[index].className = 'dropdown-item-danger';
          } else {
            // All mandatory documents left to upload have already been classified
            newTypeOptions[index].className = 'dropdown-item-primary';
          }
        }
      });
      return newTypeOptions;
    }
    return [];
  }, [documentTypeStore, documentsStore, isDocumentClassified]);

  const computeSubtypeOptions = (typeId?: string) => {
    if (typeId) {
      const type: DocumentTypeItemResponse | undefined = documentTypeStore.find(
        (type) => typeId === type.id,
      );
      if (type) {
        const newSubtypeOptions: SubtypeOption[] = [];
        type.subtypes.forEach((subtype) => {
          newSubtypeOptions.push({
            label: subtype.labelName ?? '',
            id: subtype.id,
          } as TypeItem);
        });
        return newSubtypeOptions;
      }
    }
    return [];
  };

  const computeActorOptions = (pageId: string, typeId?: string) => {
    if (typeId) {
      const relatedDocuments = documentsStore.filter(
        (doc) => doc.type.id === typeId,
      );
      const documentType = documentTypeStore.find((doc) => doc.id === typeId);
      let filteredActorOptions: TypeItem[] = JSON.parse(
        JSON.stringify(actorOptions),
      );
      if (relatedDocuments.length) {
        // Not a new document
        filteredActorOptions = filteredActorOptions.filter(
          (option) =>
            documentType?.isAvailableClassify ||
            relatedDocuments.some(
              (a) =>
                a.actorId === JSON.parse(option.id).id &&
                a.target === JSON.parse(option.id).target,
            ),
        );
      }
      filteredActorOptions.forEach((option, index) => {
        filteredActorOptions[index].className = '';
      });

      let index = 0;
      for (const option of filteredActorOptions) {
        const documentForActorAndType = documentsStore.find(
          (doc) =>
            doc.type?.id === typeId &&
            doc.actorId === JSON.parse(option.id).id &&
            doc.target === JSON.parse(option.id).target,
        );
        if (
          documentForActorAndType &&
          documentForActorAndType.pending &&
          isMandatory(documentForActorAndType.source)
        ) {
          if (isDocumentClassified(documentForActorAndType, pageId)) {
            filteredActorOptions[index].className = 'dropdown-item-primary';
          } else {
            filteredActorOptions[index].className = 'dropdown-item-danger';
          }
        }
        index++;
      }
      return filteredActorOptions;
    }
    return [];
  };

  const actorOptions: TypeItem[] = useMemo(() => {
    const newActorOptions: TypeItem[] = [];
    const filteredActorStore = userStore.profiles.includes(PROFILES.CLIENT)
      ? actorStore.filter((actor) => actor.id === userStore.actorId)
      : actorStore;

    filteredActorStore.forEach((actor) => {
      const target =
        actor?.category === ActorCategoryEnum.COMPANY
          ? DocumentTargetEnum.CORPORATION
          : DocumentTargetEnum.ACTORORFOLDER;
      newActorOptions.push({
        label: getActorName(actor) ?? '',
        id: JSON.stringify({ id: actor.id, target: target }),
      } as TypeItem);
      if (target === DocumentTargetEnum.CORPORATION)
        newActorOptions.push({
          label: t('folder.unbind.modal.signatory') + getActorName(actor) ?? '',
          id: JSON.stringify({ id: actor.id, target: 'SIGNATORY' }),
        } as TypeItem);
    });

    const actorsWithLodger = documentsStore.map((document) => {
      if (document.target === 'LODGER') return document.actorId;
      return null;
    });
    actorsWithLodger
      .filter((item, index) => actorsWithLodger.indexOf(item) === index)
      .forEach((actorId) => {
        if (actorId) {
          const actor = filteredActorStore.find((a) => a.id === actorId);
          const actorIndex = newActorOptions.findIndex(
            (a) => JSON.parse(a.id).id === actorId,
          );
          newActorOptions.splice(actorIndex + 1, 0, {
            label: t('folder.unbind.modal.lodger') + getActorName(actor) ?? '',
            id: JSON.stringify({ id: actorId, target: 'LODGER' }),
          } as TypeItem);
        }
      });

    return newActorOptions;
  }, [actorStore, documentsStore, t, userStore.actorId, userStore.profiles]);

  const findExistingDocument = (
    page: TypeUnbindPageSubmit | TypeUnbindPageModifications,
  ) => {
    const actorId = page.actor ? JSON.parse(page.actor).id : '';
    const target = page.actor
      ? JSON.parse(page.actor).target
      : 'ACTOR_OR_FOLDER';

    // Folder documents in study phase have no actorId
    return documentsStore.find(
      (doc) =>
        doc.type?.id === page.type &&
        doc.target === target &&
        (doc.folderId || doc.actorId === actorId),
    );
  };

  const unselectAllPages = () => {
    const newPages = pages;
    for (const page of newPages) {
      page.checked = false;
    }
    setPages([...newPages]);
  };

  const resetPage = (pageId: string) => {
    let newPages = [...pages];

    let checkedPages = pages.filter((page) => page.checked); // GET ALL CURRENTLY SELECTED PAGES
    if (
      checkedPages.findIndex((page) => page.id === pageId.toString()) !== -1
    ) {
      checkedPages.forEach((page) => {
        const pageIndex = newPages.findIndex(
          (p) => p.id === page.id.toString(),
        );

        newPages[pageIndex] = {
          ...newPages[pageIndex],
          ...{ validated: false },
          ...{ checked: false },
          ...{ instances: [{ id: 0 }] },
        };
      });
    } else {
      const pageIndex = newPages.findIndex((p) => p.id === pageId.toString());

      newPages[pageIndex] = {
        ...newPages[pageIndex],
        ...{ validated: false },
        ...{ checked: false },
        ...{ instances: [{ id: 0 }] },
      };
    }
    setPages([...newPages]);
  };

  const deletePageInstance = (pageId: string, instanceId: number) => {
    let newPages = [...pages];

    const pageIndex = pages.indexOf(
      pages.filter((page) => page.id === pageId)[0],
    );
    const data = newPages[pageIndex];

    newPages[pageIndex] = {
      ...data,
      ...{
        instances: data.instances.filter(
          (instance) => instance.id !== instanceId,
        ),
      },
    };
    setPages([...newPages]);
  };

  const isDocumentReplaced = (instance: TypeUnbindPageInstance): boolean => {
    const page = {
      type: instance.type,
      subType: instance.subType,
      actor: instance.actor,
    } as TypeUnbindPageSubmit;
    if (page.actor) {
      // CHECK IF A DOCUMENT EXISTS FOR THE LAST MODIFIED PAGE INSTANCE
      const existingDocument = findExistingDocument(page);
      if (existingDocument?.files?.length) {
        return true;
      }
    }
    return false;
  };

  const setPage = (
    id: string,
    modifications: TypeUnbindPageModifications,
    isUpdateChecked: boolean,
    isShiftPressed: boolean,
    isUpdateValidated: boolean,
  ) => {
    let checkedPages = pages.filter((page) => page.checked); // GET ALL CURRENTLY SELECTED PAGES

    if (!isUpdateChecked) {
      // UPDATE PAGE(s)

      let currentPageIndex = pages.findIndex((page) => page.id === id);
      let currentPage = pages[currentPageIndex];
      let updatedPages = [...pages];

      if (modifications.instanceId > currentPage?.instances.length - 1) {
        // NEW PAGE INSTANCE
        let currentPageInstances = currentPage.instances;
        currentPageInstances.push({
          id: currentPageInstances.length,
          type: currentPageInstances[0].type,
          subType: currentPageInstances[0].subType,
        });
        currentPage.validated = false;

        currentPage.instances = currentPageInstances;
        updatedPages[currentPageIndex] = currentPage;
        setPages([...updatedPages]);
      } else {
        // UPDATE OF A PAGE THAT ISN'T SELECTED : UNSELECT ALL BEFORE UPDATE
        if (
          checkedPages.length > 0 &&
          !checkedPages.some((p) => p.pageNumber.toString() === id)
        ) {
          for (const pageToUncheck of checkedPages) {
            const pageIndex = updatedPages.findIndex(
              (p) => p.pageNumber === pageToUncheck.pageNumber,
            );
            updatedPages[pageIndex] = {
              ...updatedPages[pageIndex],
              ...{ checked: false },
            };
          }
          checkedPages = [];
        }

        let selectedPages = checkedPages;
        if (selectedPages.length === 0) {
          const page = pages.find((page) => page.id === id);
          selectedPages = page ? [page] : [];
        }

        selectedPages.forEach((selectedPage) => {
          const checkedPageIndex = pages.indexOf(selectedPage);
          let newPage = updatedPages[checkedPageIndex];
          if (isUpdateValidated)
            newPage.validated = modifications.validated; // VALIDATION UPDATE
          else if (isUpdateChecked)
            newPage.checked = modifications.checked; // CHECKED UPDATE
          else {
            // UPDATE OF AN EXISTING PAGE INSTANCE
            let newPageInstances = newPage.instances;

            newPageInstances[modifications.instanceId].actor =
              modifications.actor;

            // Update type and subtype for all instances
            if (
              newPageInstances[modifications.instanceId].type !==
              modifications.type
            ) {
              newPageInstances[modifications.instanceId].type =
                modifications.type;
              newPageInstances[modifications.instanceId].subType =
                modifications.subType ?? undefined;
              newPageInstances[modifications.instanceId].actor =
                modifications.actor ?? undefined;
              // Type changed : delete other instances
              newPageInstances = newPageInstances.filter(
                (instance) => instance.id === modifications.instanceId,
              );
            } else {
              newPageInstances.forEach((instance) => {
                instance.subType = modifications.subType;
                // Check existence of a document
                instance.replaceAlert = isDocumentReplaced(instance);
              });
            }
            newPage.instances = newPageInstances;
          }
          updatedPages[checkedPageIndex] = newPage;
        });
        setPages([...updatedPages]);
      }
    } else {
      // UPDATE SELECTION
      const pageIndex = pages.indexOf(
        pages.filter((page) => page.id === id)[0],
      );
      const newlyCheckedPages = [pageIndex];
      if (
        lastSelectedIndex !== undefined &&
        isUpdateChecked &&
        modifications.checked &&
        isShiftPressed
      ) {
        // UPDATE PAGE SELECTION (shift pressed)
        const start = Math.min(lastSelectedIndex, pageIndex);
        const end = Math.max(lastSelectedIndex, pageIndex);
        Array.from({ length: end - start }, (v, k) => k + start).forEach(
          (index) => {
            newlyCheckedPages.push(index);
          },
        );
        setLastSelectedIndex(pageIndex);
      } else {
        // UPDATE PAGE SELECTION (shift not pressed)
        if (isUpdateChecked) {
          if (modifications.checked) setLastSelectedIndex(pageIndex);
          else setLastSelectedIndex(undefined);
        }
      }
      let newPages = [...pages];

      for (const pageToCheck of newlyCheckedPages) {
        newPages[pageToCheck] = {
          ...newPages[pageToCheck],
          ...{ checked: modifications.checked },
        };
      }
      setPages([...newPages]);
    }
  };

  const onFileUpload = (e: ChangeEvent<HTMLInputElement>): void => {
    const targetFiles = e.target.files;
    if (targetFiles && targetFiles.length > 0) {
      const data = targetFiles[0];
      setDocumentName(data.name);
      if (data.type === FORMAT.PDF) {
        setLoading(true);
        setFileData(data.arrayBuffer());
        setThumbnailIndex(1);
        setImages([]);
        savePdfAsByteArray(data);
        requestClassifyDocuments(data);
      } else {
        if (data.type !== FORMAT.PDF) {
          addToast({
            title: t('toast.file.type-classify.title'),
            message: t('toast.file.type-classify.message', { type: data.type }),
          });
        }
      }
    }
  };

  const savePdfAsByteArray = async (data: any) => {
    const arrayBuffer = await data.arrayBuffer();
    setFileData(arrayBuffer);
  };

  const handleRestart = () => {
    resetData(true);
  };

  const handleValidate = () => {
    const validatedPages = pages.filter((page) => page.validated);
    checkTypeDuplicates(validatedPages);
    setIsConfirmModalVisible(true);
  };

  const getThumbnailProgress = (): number => {
    if (pageCount) {
      if (thumbnailIndex === pageCount)
        return Math.floor((100 * (thumbnailIndex - 1)) / pageCount);
      return Math.floor((100 * thumbnailIndex) / pageCount);
    }
    return 0;
  };

  return (
    <Modal
      show={show}
      onHide={closeUnbindModal}
      centered
      className={`modal-unbind ${pages.length > 0 ? 'with-pages' : ''}`}
    >
      <Modal.Header className="pt-4 px-4 pb-0">
        <Modal.Title>{title}</Modal.Title>
        <button
          onClick={() => {
            closeUnbindModal();
          }}
          className="close outline-none shadow-none"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body className="pt-3 px-4 pb-4">
        <>
          <BmwYesNoModal
            show={isConfirmModalVisible}
            handleClose={() => {
              setIsConfirmModalVisible(false);
            }}
            title={t('folder.unbind.confirm.title')}
            yesButton={
              errors && errors.length
                ? undefined
                : {
                    label: t('folder.unbind.confirm.yes'),
                    onClick: () => {
                      handleSubmit();
                      setIsConfirmModalVisible(false);
                      setSubmitting(true);
                    },
                  }
            }
            noButton={{
              label: t('folder.unbind.confirm.no'),
              onClick: () => {
                setIsConfirmModalVisible(false);
              },
            }}
          >
            {errors && errors.length ? (
              <p>
                <span>{t('folder.unbind.confirm.errors.introduction')}</span>
                <ul>
                  {errors.map((error) => (
                    <li>{ReactHtmlParser(error)}</li>
                  ))}
                </ul>
              </p>
            ) : (
              ReactHtmlParser(
                t('folder.unbind.confirm.description', {
                  createdCount: pages.filter((page) => page.validated).length,
                  ignoredCount: pages.filter((page) => !page.validated).length,
                }),
              )
            )}
          </BmwYesNoModal>
          <BmwYesNoModal
            show={isConfirmRestartModalVisible}
            handleClose={() => {
              setIsConfirmRestartModalVisible(false);
            }}
            title={t('folder.unbind.confirmRestart.title')}
            yesButton={
              errors && errors.length
                ? undefined
                : {
                    label: t('folder.unbind.confirmRestart.yes'),
                    onClick: () => {
                      handleRestart();
                      setIsConfirmRestartModalVisible(false);
                    },
                  }
            }
            noButton={{
              label: t('folder.unbind.confirmRestart.no'),
              onClick: () => {
                setIsConfirmRestartModalVisible(false);
              },
            }}
          >
            {ReactHtmlParser(t('folder.unbind.confirmRestart.description'))}
          </BmwYesNoModal>
          {loading ? (
            <div>
              {t('folder.unbind.modal.uploadingDescription', {
                documentName: documentName,
              })}
            </div>
          ) : pages.length > 0 ? (
            <>
              <div>{t('folder.unbind.modal.uploadedDescription')}</div>
              <div className="unbind-pages-container mt-4">
                {pages.map((page, index) => {
                  const actors = computeActorOptions(
                    page.id,
                    page.instances[0].type,
                  );
                  return (
                    <UnbindPage
                      key={index}
                      data={fileData}
                      thumbnail={images[index]}
                      page={page}
                      totalPages={pages.length}
                      setPage={setPage}
                      resetPage={resetPage}
                      deletePageInstance={deletePageInstance}
                      actorOptions={actors}
                      subtypeOptions={computeSubtypeOptions(
                        page.instances[0].type,
                      )}
                      typeOptions={documentTypeOptions}
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <div>{t('folder.unbind.modal.uploadDescription')}</div>
          )}
        </>
      </Modal.Body>
      <Modal.Footer className="pt-0 px-4 pb-4">
        {!!thumbnailIndex && (
          <Document
            file={{ data: fileData as Uint8Array }}
            className={'d-none'}
            onLoadSuccess={(pdf) => {
              setPageCount(pdf.numPages);
            }}
          >
            <Page
              pageNumber={thumbnailIndex}
              width={100}
              canvasRef={canvasRef}
              onRenderSuccess={() => {
                if (canvasRef) {
                  const canvas: HTMLCanvasElement =
                    canvasRef?.current! as HTMLCanvasElement;
                  setImages([...images, canvas.toDataURL()]);
                  const progress =
                    getThumbnailProgress() - thumbnailProgressValue;
                  if (progress && !progressBarIntervalId.current) {
                    if (
                      !thumbnailGenerationTimeByPercent &&
                      !progressBarIntervalId.current
                    ) {
                      setThumbnailGenerationTimeByPercent(750);
                    }
                  } else if (progress > displayedProgressValue) {
                    setDisplayedProgressValue(thumbnailProgressValue);
                  }
                  setThumbnailProgressValue(progress);
                  if (pageCount && thumbnailIndex < pageCount)
                    setThumbnailIndex(thumbnailIndex + 1);
                  else {
                    setThumbnailIndex(0);
                  }
                }
              }}
            />
          </Document>
        )}
        {pages.length > 0 ? (
          <div className="flex-grow-1 d-flex flex-column flex-sm-row align-items-center justify-content-between flex-wrap flex-md-nowrap m-0">
            <BmwButton
              disabled={!isUncheckEnabled}
              label={t('folder.unbind.modal.unselectAllButton')}
              type="primary-outlined"
              className="unbind-modal-btn order-1 order-md-0 mb-3 mb-sm-0"
              onClick={unselectAllPages}
            />
            <div className="unbind-progress-bar d-flex flex-column align-items-center justify-content-center mx-md-4 mb-3 mb-md-0 order-0 order-md-1">
              <div>{`${
                pages.filter(
                  (page) =>
                    page.instances[0].subType && page.instances[0].actor,
                ).length
              }/${pages.length} ${
                pages.length > 1
                  ? t('folder.unbind.modal.identifiedPages')
                  : t('folder.unbind.modal.identifiedPage')
              }`}</div>
              <ProgressBar
                now={
                  (pages.filter(
                    (page) =>
                      page.instances[0].subType && page.instances[0].actor,
                  ).length /
                    pages.length) *
                  100
                }
              />
            </div>
            <BmwButton
              label={t('folder.unbind.modal.restartUnbindButton')}
              loading={submitting}
              type="primary-outlined"
              className="unbind-modal-btn order-2 mr-3"
              onClick={() => {
                setIsConfirmRestartModalVisible(true);
              }}
            />
            <BmwButton
              label={t('folder.unbind.modal.confirmUnbindButton')}
              loading={submitting}
              type="primary"
              className="unbind-modal-btn order-2"
              disabled={pages.filter((page) => page.validated).length < 1}
              onClick={handleValidate}
            />
          </div>
        ) : (
          <div className="flex-grow-1 d-flex justify-content-center m-0">
            <div
              className={`btn btn-primary primary-file-btn ${
                loading ? 'pe-none' : ''
              }`}
            >
              <label className={`${loading ? 'disabled' : ''} w-100 px-3`}>
                {loading ? (
                  <div className="d-flex justify-content-between align-items-center">
                    <span>{documentName}</span>
                    <span className="ml-3">{displayedProgressValue} %</span>
                  </div>
                ) : (
                  t('folder.unbind.modal.uploadButton')
                )}
              </label>
              <input
                disabled={loading}
                type="file"
                className={`extra-file-input ${
                  loading ? 'pe-none' : null
                } cursor-pointer${loading ? ' loading' : ''}`}
                accept={FORMAT.PDF}
                onChange={onFileUpload}
              />
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default BmwUnbindModal;
