import React from 'react';
import Study from './Study';
import { FolderStore } from '../../../state/folder/FolderStore';
import { useStore } from 'effector-react';
import { TypeFolderStore } from '../../../types/storeTypes';
import Forbidden from '../../error/Forbidden';

const StudyClient = () => {
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  if (folderStore.onePDF) {
    return <Forbidden className="mt-0" />;
  }
  return <Study isInvalid={(file) => !file.uploaded && !file.ignored} />;
};

export default StudyClient;
