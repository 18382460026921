import { Form } from 'react-bootstrap';
import React from 'react';

type Props = {
  id: string;
  className?: string;
  label: string;
  checked?: boolean;
  onClick: (checked: boolean) => void;
  disabled?: boolean;
};

const BmwCheckbox = (props: Props) => {
  const {
    id,
    className = '',
    label,
    checked = false,
    onClick,
    disabled,
  } = props;

  return (
    <Form.Check custom className={`d-flex align-items-center ${className}`}>
      <input
        type="checkbox"
        id={id}
        className="custom-control-input"
        onChange={() => {
          if (!disabled) {
            onClick(!checked);
          }
        }}
        checked={checked}
        disabled={disabled}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </Form.Check>
  );
};

export default BmwCheckbox;
