import React, { useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import {
  requestAuthenticationOpenBanking,
  requestSaveOpenBankingDocument,
} from '../../../state/smartpush/SmartPushEffects';
import { handleParsedResponse } from '../../../utils/utils';
import configuration from '../../../utils/Config';
import { addToast } from '../../../state/toast/ToastEvents';
import { requestGetActorCompletion } from '../../../state/actor/ActorEffects';
import { ParsedResponse } from '../../../rest/ServerResponseParse';
import { CompletionResponse } from '../../../types/model/completionResponse';
import { toggleModal } from '../../../state/modal/ModalEvents';
import { MODAL_KEYS } from '../../../utils/constants';
import { isOnlyClientAndOptionalBackOffice } from '../../../utils/rights';
import { useStore } from 'effector-react/effector-react.cjs';
import { TypeUserStore } from '../../../types/storeTypes';
import { UserStore } from '../../../state/user/UserStore';
import { useLocation } from '@reach/router';

export interface OpenBankingIframeButtonProps {
  onCloseModal: Function;
  show: boolean;
  documentId: string;
  actorId?: string;
}

export interface Encrypted {
  encrypted: string;
}

export interface Ready {
  ready: boolean;
}

export interface Done {
  finished: boolean;
}
const OpenBankingIframeButton: React.FunctionComponent<
  OpenBankingIframeButtonProps
> = ({ show, onCloseModal, documentId, actorId }) => {
  const userStore = useStore<TypeUserStore>(UserStore);
  const location = useLocation();

  const isJson = (str: string): boolean => {
    try {
      const obj = JSON.parse(str);
      if (obj && typeof obj === `object`) {
        return true;
      }
    } catch (err) {
      return false;
    }
    return false;
  };

  const authByActorId = useCallback(
    (actorId: string, event: MessageEvent) => {
      void requestAuthenticationOpenBanking({
        id: actorId,
      }).then((response) => {
        if (!response.ok) {
          console.error(response.errorMessage);
          onCloseModal();
          addToast({
            type: 'danger',
            title: 'Une erreur est survenue',
            message:
              "Impossible d'initialiser l'agrégation des comptes. Veuillez réessayer.",
          });
        } else {
          handleParsedResponse(
            response,
            false,
          )((result: Encrypted) => {
            // @ts-ignore
            event.source.postMessage(result.encrypted, event.origin);
          });
        }
      });
    },
    [onCloseModal],
  );

  const saveDocument = useCallback(
    (actorId: string, event: MessageEvent) => {
      console.log('start save document with actorId', actorId);
      void requestSaveOpenBankingDocument({
        id: actorId,
        body: { encrypted: event.data.toString(), documentId },
      }).then((result) => {
        if (!result.ok) {
          console.error(result.errorMessage);
          onCloseModal();
          addToast({
            type: 'danger',
            title: 'Une erreur est survenue',
            message:
              "Impossible de finaliser l'agrégation des comptes. Veuillez réessayer.",
          });
        } else {
          requestGetActorCompletion({
            id: actorId,
          }).then((res: ParsedResponse<CompletionResponse>) => {
            if (
              isOnlyClientAndOptionalBackOffice(userStore.profiles) &&
              res.data?.nbDocumentTotal === res.data?.nbDocumentValid
            ) {
              toggleModal(MODAL_KEYS.clientUploadComplete);
            }
          });
        }
      });
    },
    [documentId, onCloseModal, userStore.profiles],
  );

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      console.log('event received !');
      if (event.origin === configuration.smartPushUrl) {
        if (isJson(event.data)) {
          console.error('unknown object', event.data);
        } else {
          if (event.source && (event.data as Ready).ready) {
            authByActorId(actorId || '', event);
          } else if (event.source && (event.data as Done).finished) {
            onCloseModal();
          } else if (event.source && event.data) {
            saveDocument(actorId || '', event);
          } else {
            console.error('event without source or data', event);
          }
        }
      }
    },
    [authByActorId, actorId, onCloseModal, saveDocument],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [receiveMessage]);

  return (
    <>
      <Modal show={show} centered className="openBanking-modal">
        <Modal.Header>
          <button
            onClick={() => {
              onCloseModal();
            }}
            className="close outline-none shadow-none"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <iframe
            title="openBankingIframe"
            className="openBanking-iframe"
            src={`${
              configuration.smartPushUrl
            }?callback_url=${encodeURIComponent(
              `${location.origin}${location.pathname}?openBankingModal=file_${documentId}_actor_${actorId}`,
            )}`}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OpenBankingIframeButton;
