import { createEffect } from 'effector';
import {
  CONTENT_TYPE,
  restDetailsHandler,
  restPostHandler,
} from '../../rest/RestClient';
import { SMART_PUSH } from '../../rest/apiPath';

export const requestAuthenticationOpenBanking = createEffect({
  handler: restDetailsHandler<string, null>(SMART_PUSH.AUTH),
});

export const requestSaveOpenBankingDocument = createEffect({
  handler: restPostHandler<
    { encrypted: string; documentId: string },
    null,
    Blob
  >(SMART_PUSH.AUTH, CONTENT_TYPE.JSON, CONTENT_TYPE.PDF),
});
