import { Accordion, Card } from 'react-bootstrap';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BmwButton from '../form/BmwButton';
import { clearDocuments } from '../../state/documents/DocumentsEvents';
import { scrollAdapt } from '../../utils/utils';
import BmwMailModal from '../modal/BmwMailModal';
import { TypeActor } from '../../types/globalTypes';
import { useStore } from 'effector-react';
import { ActorStore } from '../../state/actor/ActorStore';

type Props = {
  complete?: boolean;
  key?: string;
  id: string;
  title: ReactNode | string;
  children: ReactNode;
  onClick?: () => void;
  activeKey: string;
  sendInvitation?: boolean;
  validate?: () => void;
  hideAccordion?: boolean;
  error?: number;
  hideValidate?: boolean;
  disabled?: boolean;
  actorId?: string;
  disableScroll?: boolean;
};

const BmwAccordion = (props: Props) => {
  const {
    complete,
    id,
    title,
    children,
    onClick = () => {},
    activeKey,
    sendInvitation = true,
    validate = () => {},
    hideAccordion = false,
    error = 0,
    hideValidate = false,
    disabled = false,
    actorId,
    disableScroll = false,
  } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMailModal, setShowMailModal] = useState<boolean>(false);
  const actorStore = useStore<TypeActor[]>(ActorStore);
  const actor = actorStore.find((a) => a.id === actorId) as TypeActor;

  useEffect(() => {
    if (activeKey === id) {
      !disableScroll && scrollAdapt(id);
    }
  }, [disableScroll, activeKey, id]);

  const onSendInvitationClick = (e: React.SyntheticEvent): void => {
    setShowMailModal(true);
    e.stopPropagation();
  };

  return (
    <Card
      className={`overflow-visible ${complete && error === 0 ? 'valid' : ''} ${
        error !== 0 ? 'error' : ''
      }`}
    >
      <BmwMailModal
        handleClose={() => setShowMailModal(false)}
        show={showMailModal}
        setLoading={setLoading}
        actor={actor}
      />
      <Accordion.Toggle
        as={Card.Header}
        eventKey={id}
        id={id}
        onClick={() => {
          if (!hideAccordion) {
            clearDocuments();
            onClick();
          }
        }}
      >
        <h5 className="mb-0 d-flex align-items-center justify-content-between">
          <span className="title">
            {!hideAccordion &&
              (activeKey === id ? (
                <i className="fa fa-chevron-up" />
              ) : (
                <i className="fa fa-chevron-down" />
              ))}
            <span className={hideAccordion ? '' : 'pl-4'}>{title}</span>
          </span>
          {sendInvitation && (
            <BmwButton
              loading={loading}
              disabled={disabled}
              className="ml-auto"
              type="tertiary"
              label={
                <>
                  <i className="fa fa-envelope pr-2" />
                  {t('folder.invitation.sendInvitation')}
                </>
              }
              onClick={onSendInvitationClick}
            />
          )}
          {complete && error === 0 && (
            <span className="icon">
              <span className="check pr-2">{t('folder.files.complete')}</span>
              <i className="fa fa-check" />
            </span>
          )}
          {error !== 0 && <i className="fa fa-times ml-3 icon" />}
        </h5>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={id}>
        <Card.Body>
          {children}
          {!hideValidate && (
            <div className="d-flex justify-content-end">
              <BmwButton
                disabled={disabled}
                label={t('folder.nextStep')}
                onClick={validate}
              />
            </div>
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default BmwAccordion;
