import React from 'react';
import BmwCheckbox from '../../form/BmwCheckbox';
import { useTranslation } from 'react-i18next';
import BmwBloc from '../../layout/BmwBloc';

type Props = {
  checked?: boolean;
  onClick?: (checked: boolean) => void;
  disabled?: boolean;
};

const OneFile = (props: Props) => {
  const { checked = false, onClick = () => {}, disabled = false } = props;
  const { t } = useTranslation();

  return (
    <BmwBloc
      title={t('folder.oneFile.title')}
      description={t('folder.oneFile.description')}
    >
      <BmwCheckbox
        id="onFileCheck"
        label={t('folder.oneFile.check')}
        checked={checked}
        onClick={onClick}
        disabled={disabled}
      />
    </BmwBloc>
  );
};

export default OneFile;
