import React from 'react';
import { navigate } from '@reach/router';
import { getDefaultRoute } from '../../utils/routes';
import BmwButton from '../../components/form/BmwButton';
import { TypeUserStore } from '../../types/storeTypes';
import { UserStore } from '../../state/user/UserStore';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

type Props = {
  default?: boolean;
  path?: string;
};

const NotFound = (props: Props) => {
  const userStore = useStore<TypeUserStore>(UserStore);
  const { t } = useTranslation();

  const onBackClick = (): void => {
    navigate(getDefaultRoute(userStore));
  };

  return (
    <div className="content-body container px-2 px-sm-0">
      <h2>{t('error.404.title')}</h2>
      <p>{t('error.404.message')}</p>
      <div className="mt-5">
        <BmwButton
          className="w-100-m"
          label={t('error.404.button')}
          onClick={onBackClick}
        />
      </div>
    </div>
  );
};

export default NotFound;
