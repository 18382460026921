import Study from './Study';
import ButtonLaunchControl from '../../../components/folder/study/ButtonLaunchControl';
import React, { useEffect, useState } from 'react';
import StudyOneFile from './StudyOneFile';
import { useStore } from 'effector-react';
import { FolderStore } from '../../../state/folder/FolderStore';
import OneFile from '../../../components/folder/study/OneFile';
import { FolderResponse } from '../../../types/model/folderResponse';
import { restPatchHandler } from '../../../rest/RestClient';
import { handleParsedResponse } from '../../../utils/utils';
import { ParsedResponse } from '../../../rest/ServerResponseParse';
import { FolderAggregationUpdateRequest } from '../../../types/model/folderAggregationUpdateRequest';
import { addToast } from '../../../state/toast/ToastEvents';
import { setFolder } from '../../../state/folder/FolderEvents';
import { TypeFolderStore } from '../../../types/storeTypes';
import { clearDocuments } from '../../../state/documents/DocumentsEvents';
import { FOLDERS } from '../../../rest/apiPath';
import { TypeActor } from '../../../types/globalTypes';
import { ActorStore } from '../../../state/actor/ActorStore';
import { isFolderComplete } from '../../../services/folderService';
import { requestGetFolderCompletion } from '../../../state/folder/FolderEffects';

type Props = {};

const StudyOperator = (props: Props) => {
  const [oneFile, setOneFile] = useState(false as boolean);
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const actorStore = useStore<TypeActor[]>(ActorStore);

  useEffect(() => {
    setOneFile(folderStore.onePDF);
  }, [folderStore, setOneFile]);

  return (
    <>
      {folderStore.onePDF ? (
        <StudyOneFile />
      ) : (
        <Study isInvalid={(file) => !file.uploaded} />
      )}
      <OneFile
        onClick={(checked: boolean) => {
          restPatchHandler<FolderAggregationUpdateRequest, FolderResponse>(
            FOLDERS.ONE_PDF,
          )({
            id: folderStore.id,
            dto: { aggregate: checked },
          }).then((result: ParsedResponse<FolderResponse>) =>
            handleParsedResponse(result)((data: FolderResponse) => {
              setOneFile(data.onePDF);
              addToast({
                type: 'success',
              });
              clearDocuments();
              setFolder({ ...folderStore, ...data } as TypeFolderStore);
              requestGetFolderCompletion({
                id: folderStore.id,
              });
            }),
          );
          setOneFile(checked);
        }}
        checked={oneFile}
      />
      <ButtonLaunchControl
        disabled={
          folderStore.onePDF
            ? !folderStore.completed
            : !isFolderComplete(actorStore, folderStore)
        }
      />
    </>
  );
};

export default StudyOperator;
