import { ParsedResponse, ServerResponseParser } from './ServerResponseParse';
import 'whatwg-fetch';

type RestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

interface DataWithId {
  id: string;
}

interface QueryParamsDto {
  [index: string]: any;
}

export const CONTENT_TYPE = {
  JSON: 'application/json',
  FORM_DATA: 'multipart/form-data',
  EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PDF: 'application/pdf',
};

const buildQueryParameters = <T extends QueryParamsDto>(dto?: T): string => {
  return dto
    ? Object.keys(dto)
        .map((key, index) =>
          dto[key] !== undefined
            ? `${index !== 0 ? '&' : ''}${key}=${String(dto[key])}`
            : '',
        )
        .join('')
    : '';
};

export const makeRestCall = <T, U>(
  url: string,
  method: RestMethod,
  data?: T | FormData,
  contentType: string = CONTENT_TYPE.JSON,
  responseType: string = CONTENT_TYPE.JSON,
  hasContent: boolean = true,
): Promise<ParsedResponse<U>> => {
  return fetch(url, {
    method,
    body:
      data && contentType === CONTENT_TYPE.JSON
        ? JSON.stringify(data)
        : data && contentType === CONTENT_TYPE.FORM_DATA
        ? (data as FormData)
        : undefined,
    headers:
      data && contentType === CONTENT_TYPE.JSON
        ? { 'Content-Type': contentType }
        : {},
  })
    .then((response: Response) => {
      return method === 'DELETE'
        ? {
            responseCode: response.status,
            errorMessage: undefined,
            data: undefined,
            ok: true,
          }
        : new ServerResponseParser<U>().parseResponse(
            response,
            responseType,
            hasContent,
          );
    })
    .catch(() => ({
      responseCode: 500,
      errorMessage: 'Erreur réseau',
      data: undefined,
      ok: false,
    }));
};

export const restListHandler = <T>(
  endpointUrl: string,
  suffix: string = '',
): (() => Promise<ParsedResponse<T[]>>) => {
  return () => makeRestCall<void, T[]>(endpointUrl + suffix, 'GET');
};

export const restHandler = <T>(
  endpointUrl: string,
  suffix: string = '',
  contentType: string = CONTENT_TYPE.JSON,
  responseType: string = CONTENT_TYPE.JSON,
): (() => Promise<ParsedResponse<T>>) => {
  return () =>
    makeRestCall<void, T>(
      endpointUrl + suffix,
      'GET',
      undefined,
      contentType,
      responseType,
    );
};

export const restWithoutAuthHandler = <T>(
  endpointUrl: string,
  suffix: string = '',
): (() => Promise<ParsedResponse<T>>) => {
  return () => makeRestCall<void, T>(endpointUrl + suffix, 'GET');
};

export const restWithoutAuthPostHandler = <T, U>(
  endpointUrl: string,
  contentType: string = CONTENT_TYPE.JSON,
): ((data: T) => Promise<ParsedResponse<U>>) => {
  return (data: T) =>
    makeRestCall<T, U>(endpointUrl, 'POST', data, contentType);
};

export const restDetailsHandler = <T, U>(
  endpointUrl: string,
): ((data: {
  id: string;
  isClient?: boolean;
  query?: U;
}) => Promise<ParsedResponse<T>>) => {
  return (data: { id: string; query?: U }) => {
    return makeRestCall<void, T>(
      endpointUrl.replace('{id}', data.id) +
        (data.query !== undefined
          ? `?${buildQueryParameters(data.query)}`
          : ''),
      'GET',
    );
  };
};

export const restIdListHandler = <T>(
  endpointUrl: string,
): ((data: { id: string }) => Promise<ParsedResponse<T[]>>) => {
  return (data: { id: string }) =>
    makeRestCall<void, T[]>(endpointUrl.replace('{id}', data.id), 'GET');
};

export const restCreationHandler = <T, U, V>(
  endpointUrl: string,
  contentType: string = CONTENT_TYPE.JSON,
): ((data: T, query?: U) => Promise<ParsedResponse<V>>) => {
  return (data: T, query?: U) =>
    makeRestCall<T, V>(
      endpointUrl +
        (query !== undefined ? `?${buildQueryParameters(query)}` : ''),
      'POST',
      data,
      contentType,
    );
};

export const restPostHandler = <T, U, V>(
  endpointUrl: string,
  contentType: string = CONTENT_TYPE.JSON,
  responseType: string = CONTENT_TYPE.JSON,
): ((
  data: {
    id: string;
    body: T;
  },
  query?: U,
) => Promise<ParsedResponse<V>>) => {
  return (data: { id: string; body: T }, query?: U) =>
    makeRestCall<T, V>(
      endpointUrl.replace('{id}', data.id) +
        (query !== undefined ? `?${buildQueryParameters(query)}` : ''),
      'POST',
      data.body,
      contentType,
      responseType,
    );
};

export const restCreationHandlerNoContent = <T, U>(
  endpointUrl: string,
  contentType: string = CONTENT_TYPE.JSON,
  responseType: string = CONTENT_TYPE.JSON,
): ((data: T, id?: string) => Promise<ParsedResponse<U>>) => {
  return (data: T, id?: string) =>
    makeRestCall<T, U>(
      endpointUrl.replace('{id}', id || ''),
      'POST',
      data,
      contentType,
      responseType,
      false,
    );
};

export const restDeletionHandler = <T extends DataWithId>(
  endpointUrl: string,
): ((data: T) => Promise<ParsedResponse<void>>) => {
  return (data: T) =>
    makeRestCall<T, void>(endpointUrl + data.id, 'DELETE', data);
};

export const restUpdateHandler = <T>(
  endpointUrl: string,
): ((data: { id: string; dto: T }) => Promise<ParsedResponse<void>>) => {
  return (data: { id: string; dto: T }) =>
    makeRestCall<T, void>(endpointUrl + data.id, 'PUT', data.dto);
};

export const restListPatchHandler = <T, U>(
  endpointUrl: string,
): ((data: T[]) => Promise<ParsedResponse<U[]>>) => {
  return (data: T[]) => makeRestCall<T[], U[]>(endpointUrl, 'PATCH', data);
};

export const restPatchHandler = <T, U>(
  endpointUrl: string,
): ((data: { id: string; dto: T }) => Promise<ParsedResponse<U>>) => {
  return (data: { id: string; dto: T }) =>
    makeRestCall<T, U>(endpointUrl.replace('{id}', data.id), 'PATCH', data.dto);
};

export const restPatchHandlerNoContent = <T, U>(
  endpointUrl: string,
): ((data: { id: string; dto: T }) => Promise<ParsedResponse<U>>) => {
  return (data: { id: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl.replace('{id}', data.id),
      'PATCH',
      data.dto,
      CONTENT_TYPE.JSON,
      CONTENT_TYPE.JSON,
      false,
    );
};
