import React from 'react';
import { LabelItemResponse } from '../../types/model/labelItemResponse';
import BmwInput from '../form/BmwInput';
import { useTranslation } from 'react-i18next';
import { BACK_OFFICE_LENGTH } from '../../utils/constants';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type Props = {
  data: LabelItemResponse;
  onChange: (newData: LabelItemResponse) => void;
};

const Edit = (props: Props) => {
  const { data, onChange } = props;
  const { t } = useTranslation();
  const error =
    data.labelDescription.length > BACK_OFFICE_LENGTH.DESCRIPTION
      ? t('validation.max', {
          max: BACK_OFFICE_LENGTH.DESCRIPTION,
          current: data.labelDescription.length,
        })
      : '';
  return (
    <>
      <BmwInput
        onChange={(e) => onChange({ ...data, labelName: e.target.value })}
        containerClassName="field mr-2 col-lg-3 col-12"
        value={data.labelName}
        error={
          data.labelName.length > BACK_OFFICE_LENGTH.NAME
            ? t('validation.max', {
                max: BACK_OFFICE_LENGTH.NAME,
                current: data.labelName.length,
              })
            : ''
        }
      />
      <div className="field mx-2 col-xl-6 col-lg-5 col-12">
        <ReactQuill
          onChange={(content) =>
            onChange({ ...data, labelDescription: content })
          }
          className={error ? 'invalid' : ''}
          value={data.labelDescription}
        />
        {error && <div className="invalid">{error}</div>}
      </div>
    </>
  );
};

export default Edit;
