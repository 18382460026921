import React from 'react';
import Reprise from '../reprise/Reprise';
import { useStore } from 'effector-react';
import { TypeFolderStore } from '../../../types/storeTypes';
import { FolderStore } from '../../../state/folder/FolderStore';
import Forbidden from '../../error/Forbidden';

const RepriseClient = () => {
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  if (folderStore.onePDF) {
    return <Forbidden />;
  }
  return <Reprise isError={(file) => file.rejected || !file.uploaded} />;
};

export default RepriseClient;
