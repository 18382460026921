import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { FolderStore } from '../../state/folder/FolderStore';
import { PROFILES } from '../../utils/rights';
import { UserStore } from '../../state/user/UserStore';
import { TypeFolderStore, TypeUserStore } from '../../types/storeTypes';
import {
  DATE_FORMAT_SHORT_WITH_TIME,
  DATE_INPUT_FORMAT_LONG,
  formatDate,
} from '../../utils/date';

const FolderHistory = () => {
  const { t } = useTranslation();
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const userStore = useStore<TypeUserStore>(UserStore);

  const formatDateTime = (date: Date) => {
    return formatDate(
      date.toString(),
      DATE_FORMAT_SHORT_WITH_TIME,
      DATE_INPUT_FORMAT_LONG,
      false,
    );
  };

  if (!userStore.profiles.includes(PROFILES.CLIENT))
    return (
      <div className="p-3 border border-dark mt-5">
        <h3 className="mb-3">{t('folder.history.title')}</h3>
        <div className="d-flex flex-column align-items-start">
          <div className="mb-2">
            <span className="font-weight-bold">{`${t(
              'folder.history.creation_date',
            )} : `}</span>
            {formatDateTime(folderStore.creationDate)}
          </div>
          {folderStore.studyLastMedocSent && (
            <div className="mb-2">
              <span className="font-weight-bold">{`${t(
                'folder.history.study_last_medoc_sent',
              )} : `}</span>
              {formatDateTime(folderStore.studyLastMedocSent)}
            </div>
          )}
          {folderStore.lastPaymentDate && (
            <div className="mb-2">
              <span className="font-weight-bold">{`${t(
                'folder.history.last_payment_date',
              )} : `}</span>
              {formatDateTime(folderStore.lastPaymentDate)}
            </div>
          )}
          {folderStore.paymentLastMedocSent && (
            <div className="mb-2">
              <span className="font-weight-bold">{`${t(
                'folder.history.payment_last_medoc_sent',
              )} : `}</span>
              {formatDateTime(folderStore.paymentLastMedocSent)}
            </div>
          )}
          {folderStore.endDate && (
            <div className="mb-2">
              <span className="font-weight-bold">{`${t(
                'folder.history.end_date',
              )} : `}</span>
              {formatDateTime(folderStore.endDate)}
            </div>
          )}
        </div>
      </div>
    );
  else return <div></div>;
};

export default FolderHistory;
