import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Shortcuts from '../components/dashboard/Shortcuts';
import Filter from '../components/dashboard/Filter';
import BmwTable from '../components/BmwTable';
import { TypeColumn, TypeFilter, TypeSort } from '../types/tableTypes';
import { UserStore } from '../state/user/UserStore';
import { useStore } from 'effector-react';
import { isDealer, PROFILES } from '../utils/rights';
import { makeRestCall, restHandler } from '../rest/RestClient';
import { FolderItemResponse } from '../types/model/folderItemResponse';
import { requestGetDealerships } from '../state/dealership/DealershipEffects';
import { TypeDealershipStore, TypeUserStore } from '../types/storeTypes';
import { DealershipStore } from '../state/dealership/DealershipStore';
import { FOLDER_ATTRIBUTES, ORDER, STATUS } from '../utils/constants';
import { handleParsedResponse } from '../utils/utils';
import { ParsedResponse } from '../rest/ServerResponseParse';
import { FolderItemPersonResponse } from '../types/model/folderItemPersonResponse';
import {
  DATE_FORMAT_SHORT,
  DATE_INPUT_FORMAT,
  formatDate,
} from '../utils/date';
import { FOLDERS } from '../rest/apiPath';
import { PageDashboard } from '../types/model/pageDashboard';
import { TypeFolderAttribute } from '../types/globalTypes';

type Props = {
  path: string;
};

interface TypeCount {
  count: number;
}

export const getName = (person: FolderItemPersonResponse) =>
  `${person.firstname}${person.lastname ? ` ${person.lastname}` : ''}`;

const Dashboard = (props: Props) => {
  const userStore = useStore<TypeUserStore>(UserStore);
  const dealershipStore = useStore<TypeDealershipStore>(DealershipStore);
  const [data, setData] = useState([] as FolderItemResponse[]);
  const [count, setCount] = useState<number>(0);
  const [filter, setFilter] = useState({
    status: [],
    dealership: undefined,
    search: '',
  } as TypeFilter);
  const [page, setPage] = useState<number>(0);
  const [maxPage, setMaxPage] = useState<number>(1);
  const [sort, setSort] = useState({
    name: FOLDER_ATTRIBUTES.CREATION_DATE,
    order: ORDER.DESC,
  } as TypeSort);
  const [loading, setLoading] = useState<boolean>(false);
  const [actionNeeded, setActionNeeded] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    requestGetDealerships();
  }, []);

  useEffect(() => {
    const filterByDealership =
      dealershipStore.selected &&
      !userStore.profiles.includes(PROFILES.RESTRICTED_DEALER);
    makeRestCall(
      `${FOLDERS.COUNT}?${
        filterByDealership ? `dealershipId=${dealershipStore.selected}&` : ''
      }status=${STATUS.STUDY_SUPPLY_DOCUMENT}&status=${
        STATUS.STUDY_RETAKE_SUPPLY
      }&status=${STATUS.PAYMENT_SURVEY}&status=${
        STATUS.PAYMENT_SUPPLY_DOCUMENT
      }&status=${STATUS.PAYMENT_RETAKE_SUPPLY}&`,
      'GET',
    ).then((result) =>
      handleParsedResponse(result)((data: TypeCount) => setCount(data.count)),
    );
  }, [dealershipStore.selected, userStore]);

  const columns = [
    {
      label: t('dashboard.table.id'),
      name: 'id',
      accessor: (data: string) => data,
    },
    {
      label: t('dashboard.table.status'),
      name: 'status',
      accessor: (data: string) => t(`status.${data}`),
    },
    {
      label: t('dashboard.table.creationDate'),
      name: 'creationDate',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
    },
    {
      label: t('dashboard.table.dealer'),
      name: 'dealer',
      accessor: (data: FolderItemPersonResponse) => getName(data),
    },
    {
      label: t('dashboard.table.client'),
      name: 'client',
      accessor: (data: FolderItemPersonResponse) => getName(data),
    },
  ] as TypeColumn[];

  const convertColumnName = (name: TypeFolderAttribute): string => {
    switch (name) {
      case 'id':
        return 'FOLDER_ID';
      case 'status':
        return 'FOLDER_STATUS';
      case 'creationDate':
        return 'CREATION_DATE';
      case 'dealer':
        return 'DEALER_NAME';
      case 'client':
        return 'ACTOR_NAME';
    }
  };

  const buildSearchQueryParams = useCallback((): string => {
    const filterByDealership =
      filter.dealership &&
      !userStore.profiles.includes(PROFILES.RESTRICTED_DEALER);
    return `?${filterByDealership ? `dealershipId=${filter.dealership}&` : ''}${
      filter.status ? `status=${filter.status.join(',')}&` : ''
    }${
      filter.search ? `query=${filter.search}&` : ''
    }pageIndex=${page}&pageSize=20&sort=${convertColumnName(
      sort.name as TypeFolderAttribute,
    )}&direction=${sort.order}`;
  }, [filter, userStore.profiles, sort, page]);

  const search = useCallback(() => {
    setLoading(true);
    restHandler<PageDashboard>(FOLDERS.GET, buildSearchQueryParams())()
      .then((res: ParsedResponse<PageDashboard>) =>
        handleParsedResponse(res)((data: PageDashboard) => {
          setData(data.content || []);
          setPage(data.pageIndex || 0);
          setMaxPage(data.maxPageIndex || 1);
        }),
      )
      .finally(() => setLoading(false));
  }, [buildSearchQueryParams]);

  useEffect(() => {
    search();
  }, [search]);

  const onCountClick = (): void => {
    if (actionNeeded) {
      setActionNeeded(false);
      setFilter({
        status: [],
        dealership: dealershipStore.selected,
        search: '',
      });
    } else {
      setActionNeeded(true);
      setFilter({
        status: [
          STATUS.STUDY_SUPPLY_DOCUMENT,
          STATUS.STUDY_RETAKE_SUPPLY,
          STATUS.PAYMENT_SURVEY,
          STATUS.PAYMENT_SUPPLY_DOCUMENT,
          STATUS.PAYMENT_RETAKE_SUPPLY,
        ],
        dealership: dealershipStore.selected,
        search: '',
      });
    }
  };

  return (
    <>
      <div className="content-body container px-2 px-sm-0">
        <div className="mb-5 d-flex flex-row flex-wrap align-items-stretch justify-content-between">
          <h2 className="title">
            {t('dashboard.title.first')}
            <br />
            {t('dashboard.title.second')}
          </h2>
        </div>
        <div className="my-3">
          {isDealer(userStore.profiles) && (
            <Shortcuts
              onClick={onCountClick}
              count={count}
              disabled={loading}
              selected={actionNeeded}
            />
          )}
          <Filter
            filter={filter}
            setFilter={setFilter}
            setPage={setPage}
            disabled={loading}
            setActionNeeded={setActionNeeded}
          />
          <BmwTable
            loading={loading}
            columns={columns}
            data={data}
            page={page}
            maxPage={maxPage}
            sort={sort}
            setPage={setPage}
            setSort={setSort}
          />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
