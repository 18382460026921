import { createEffect } from 'effector';
import { restIdListHandler } from '../../rest/RestClient';
import { DocumentTypeItemResponse } from '../../types/model/documentTypeItemResponse';
import { addDocumentType } from './DocumentTypeEvents';
import { handleParsedResponse } from '../../utils/utils';
import { DOCUMENT_TYPES } from '../../rest/apiPath';

export const requestGetDocumentType = createEffect({
  handler: restIdListHandler<DocumentTypeItemResponse>(DOCUMENT_TYPES.GET),
});

requestGetDocumentType.done.watch(({ result }) =>
  handleParsedResponse(
    result,
    false,
  )((data: DocumentTypeItemResponse[]) => {
    addDocumentType(data);
  }),
);
