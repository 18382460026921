/**
 * Sofiadoc back end API.
 * API endpoints for front application.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: bmwfinance-e-kyc@reeliant.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TestLoginProfileEnum =
  | 'RESTRICTED_DEALER'
  | 'DEALER'
  | 'UNRESTRICTED_DEALER'
  | 'STUDY_OPERATOR'
  | 'LEVEL_2'
  | 'PAYMENT_OPERATOR'
  | 'CLIENT'
  | 'BACK_OFFICE_REPORTING'
  | 'BACK_OFFICE_LABEL';

export const TestLoginProfileEnum = {
  RESTRICTEDDEALER: 'RESTRICTED_DEALER' as TestLoginProfileEnum,
  DEALER: 'DEALER' as TestLoginProfileEnum,
  UNRESTRICTEDDEALER: 'UNRESTRICTED_DEALER' as TestLoginProfileEnum,
  STUDYOPERATOR: 'STUDY_OPERATOR' as TestLoginProfileEnum,
  LEVEL_2: 'LEVEL_2' as TestLoginProfileEnum,
  PAYMENTOPERATOR: 'PAYMENT_OPERATOR' as TestLoginProfileEnum,
  CLIENT: 'CLIENT' as TestLoginProfileEnum,
  BACKOFFICEREPORTING: 'BACK_OFFICE_REPORTING' as TestLoginProfileEnum,
  BACKOFFICELABEL: 'BACK_OFFICE_LABEL' as TestLoginProfileEnum,
};
