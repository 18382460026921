import { createStore } from 'effector';
import {
  clearActors,
  setActorCompletion,
  setActorReminder,
  setActors,
  setShouldUpdateLastInvitation,
} from './ActorEvents';
import { TypeActor } from '../../types/globalTypes';
import { ActorReminderUpdateRequest } from '../../types/model/actorReminderUpdateRequest';
import { CompletionResponse } from '../../types/model/completionResponse';
import { CLIENT_TYPE } from '../../utils/rights';

export interface TypeActorCompletion extends CompletionResponse {
  id: string;
}

export const ActorStore = createStore<TypeActor[]>([])
  .on(setActors, (state: TypeActor[], payload: TypeActor[]) =>
    [...payload].sort(sortActor),
  )
  .on(
    setActorCompletion,
    (state: TypeActor[], payload: TypeActorCompletion) => [
      ...state.map((value: TypeActor) => ({
        ...value,
        nbDocumentValid:
          payload.id === value.id
            ? payload.nbDocumentValid
            : value.nbDocumentValid,
        nbDocumentTotal:
          payload.id === value.id
            ? payload.nbDocumentTotal
            : value.nbDocumentTotal,
        nbDocumentToUpload:
          payload.id === value.id
            ? payload.nbDocumentToUpload
            : value.nbDocumentToUpload,
        completed:
          payload.id === value.id
            ? payload.nbDocumentValid === payload.nbDocumentTotal
            : value.completed,
        hasFeedbackToSubmit:
          payload.id === value.id
            ? payload.hasFeedbackToSubmit
            : value.hasFeedbackToSubmit,
        isMissingRefusalId:
          payload.id === value.id
            ? payload.isMissingRefusalId
            : value.isMissingRefusalId,
        completionUpdated:
          payload.id === value.id ? true : value.completionUpdated,
      })),
    ],
  )
  .on(
    setActorReminder,
    (
      state: TypeActor[],
      payload: { id: string; dto: ActorReminderUpdateRequest },
    ) => [
      ...state.map((value: TypeActor) => ({
        ...value,
        autoReminder:
          value.id === payload.id ? payload.dto.reminder : value.autoReminder,
      })),
    ],
  )
  .on(
    setShouldUpdateLastInvitation,
    (state: TypeActor[], payload: { id: string; value: boolean }) => [
      ...state.map((actor: TypeActor) => ({
        ...actor,
        shouldUpdateLastInvitation:
          actor.id === payload.id
            ? payload.value
            : actor.shouldUpdateLastInvitation,
      })),
    ],
  )
  .on(clearActors, () => []);

const sortActor = (a: TypeActor, b: TypeActor): number => {
  if (a === null || a.type === null) {
    return 1;
  } else if (b === null || b.type === null) {
    return -1;
  }
  if (a.type === b.type) {
    return 0;
  }
  if (a.type === CLIENT_TYPE.PRIMARY) {
    return -1;
  } else if (b.type === CLIENT_TYPE.PRIMARY) {
    return 1;
  } else if (a.type === CLIENT_TYPE.TERTIARY) {
    return 1;
  } else if (b.type === CLIENT_TYPE.TERTIARY) {
    return -1;
  }
  return 0;
};
