import { CONTENT_TYPE } from './RestClient';

export interface ParsedResponse<T> {
  data: T | undefined;
  responseCode: number | undefined;
  filename?: string;
  errorMessage: string | undefined;
  ok: boolean;
}

export interface ErrorResponse {
  error: string;
  message?: string;
}

export class ServerResponseParser<T> {
  parseResponse(
    response: Response,
    contentType: string = CONTENT_TYPE.JSON,
    hasContent: boolean = true,
  ): Promise<ParsedResponse<T>> {
    if (response.ok) {
      if (hasContent) {
        const promise: Promise<any> =
          contentType === CONTENT_TYPE.EXCEL || contentType === CONTENT_TYPE.PDF
            ? response.blob()
            : response.text();
        return promise
          .then((text) =>
            contentType === CONTENT_TYPE.JSON
              ? text.length
                ? JSON.parse(text)
                : null
              : text,
          )
          .then((json: T) => {
            return {
              responseCode: response.status,
              errorMessage: undefined,
              filename: extractFilenameFromHeader(response.headers),
              data: json,
              ok: true,
            };
          });
      } else {
        return Promise.resolve(response).then(() => {
          return {
            responseCode: response.status,
            errorMessage: undefined,
            data: undefined,
            ok: true,
          };
        });
      }
    } else {
      return response.json().then((json: ErrorResponse) => {
        return {
          responseCode: response.status,
          errorMessage: json.message
            ? json.error + ' : ' + json.message
            : json.error,
          data: undefined,
          ok: false,
        };
      });
    }
  }
}

export const extractFilenameFromHeader = (header: Headers): string => {
  let filename = '';
  const disposition = header.get('content-disposition');
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};
