import { useTranslation } from 'react-i18next';
import BmwYesNoModal from './BmwYesNoModal';
import React from 'react';
import { restPatchHandler } from '../../rest/RestClient';
import { FOLDERS } from '../../rest/apiPath';
import { handleParsedResponse } from '../../utils/utils';
import { FolderResponse } from '../../types/model/folderResponse';
import { ParsedResponse } from '../../rest/ServerResponseParse';
import { successToast } from '../../utils/toast';
import { setFolder } from '../../state/folder/FolderEvents';
import { TypeFolderStore } from '../../types/storeTypes';

type Props = {
  show: boolean;
  handleClose: () => void;
  folderStore: TypeFolderStore;
};

const BmwRefuseModal = (props: Props) => {
  const { show, handleClose, folderStore } = props;
  const { t } = useTranslation();

  return (
    <BmwYesNoModal
      show={show}
      handleClose={handleClose}
      title={t('refuse.modal.title')}
      yesButton={{
        label: t('refuse.modal.yes'),
        type: 'secondary',
        onClick: async () => {
          await restPatchHandler<null, FolderResponse>(FOLDERS.FEEDBACK_SUBMIT)(
            {
              id: folderStore.id,
              dto: null,
            },
          ).then((result: ParsedResponse<FolderResponse>) =>
            handleParsedResponse(result)((data: FolderResponse) => {
              successToast(t);
              setFolder({ ...folderStore, ...data });
            }),
          );
          handleClose();
        },
      }}
      noButton={{
        label: t('refuse.modal.no'),
        type: 'primary',
        onClick: async () => {
          handleClose();
        },
      }}
    >
      {t('refuse.modal.description')}
    </BmwYesNoModal>
  );
};

export default BmwRefuseModal;
