import React from 'react';
import { LabelItemResponse } from '../../types/model/labelItemResponse';
import ReactHtmlParser from 'react-html-parser';

type Props = {
  data: LabelItemResponse;
};

const Display = (props: Props) => {
  const { data } = props;
  return (
    <>
      <div className="field mr-2 col-lg-3 col-md-12">{data.labelName}</div>
      <div className="field mx-2 col-xl-6 col-lg-5 col-md-12">
        {ReactHtmlParser(data.labelDescription)}
      </div>
    </>
  );
};

export default Display;
