import React, { ReactElement, useEffect, useState } from 'react';
import HtmlParser from 'react-html-parser';
import { Accordion } from 'react-bootstrap';
import BmwAccordion from '../folder/BmwAccordion';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import { ROUTE_COOKIE } from '../../utils/routes';

interface TypeParagraph {
  title: string;
  body: ReactElement[];
  link?: ReactElement;
}

type Props = {
  name: string;
  hasLink: number[];
  numberOfBloc: number;
};

const PublicDisplay = (props: Props) => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState<string>('bloc_0');
  const { hasLink, name, numberOfBloc } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = Array.from(Array(numberOfBloc).keys()).map(
    (i: number): TypeParagraph => ({
      title: t(`${name}.bloc_${i + 1}.title`),
      body: HtmlParser(t(`${name}.bloc_${i + 1}.body`)),
      link: hasLink.includes(i + 1) ? (
        <Link to={ROUTE_COOKIE}>{t(`${name}.bloc_${i + 1}.link`)}</Link>
      ) : undefined,
    }),
  );

  return (
    <div className="content-body container px-2 px-sm-0">
      <h2>{t(`${name}.h2`)}</h2>
      <p className="text-justify">{HtmlParser(t(`${name}.introduction`))}</p>
      <Accordion activeKey={activeKey}>
        {content.map((paragraph, index) => (
          <BmwAccordion
            key={`bloc_${index.toString()}`}
            id={`bloc_${index.toString()}`}
            title={paragraph.title}
            activeKey={activeKey}
            onClick={() => {
              if (index.toString() === activeKey) {
                setActiveKey('');
              } else {
                setActiveKey(`bloc_${index.toString()}`);
              }
            }}
            sendInvitation={false}
            hideValidate
            disableScroll
          >
            <div className="text-justify">
              {paragraph.body}
              {paragraph.link}
            </div>
          </BmwAccordion>
        ))}
      </Accordion>
    </div>
  );
};

export default PublicDisplay;
