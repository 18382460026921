import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TypeUnbindPage,
  TypeUnbindPageInstance,
} from '../../../types/unbindTypes';
import BmwDropdown, { TypeItem } from '../../form/BmwDropdown';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useStore } from 'effector-react/effector-react.cjs';
import { DocumentTypeItemResponse } from '../../../types/model/documentTypeItemResponse';
import { DocumentTypeStore } from '../../../state/documentType/DocumentTypeStore';
import BmwLoader from '../../BmwLoader';
import ReactHtmlParser from 'react-html-parser';

type Props = {
  page: TypeUnbindPage;
  thumbnail?: string;
  typeOptions: TypeItem[];
  subtypeOptions: TypeItem[];
  actorOptions: TypeItem[];
  data: any;
  totalPages: number;
  setPage: Function;
  resetPage: Function;
  deletePageInstance: Function;
  className?: string;
};

const UnbindPage = (props: Props) => {
  const {
    page,
    totalPages,
    setPage,
    resetPage,
    deletePageInstance,
    className = '',
    typeOptions,
    subtypeOptions,
    actorOptions,
    data,
    thumbnail,
  } = props;
  const [statusClassName, setStatusClassName] = useState<string>('');
  const [isFullScreenVisible, setIsFullScreenVisible] = useState<boolean>();
  const { t } = useTranslation();

  const documentTypeStore =
    useStore<DocumentTypeItemResponse[]>(DocumentTypeStore);

  const isFolderDocument = useMemo(() => {
    const category = documentTypeStore.find(
      (t) => t.id === page.instances[0].type,
    )?.category;
    return category === 'FOLDER';
  }, [documentTypeStore, page]);

  const actorAvailableOptions = useMemo(() => {
    if (isFolderDocument) return [];
    return actorOptions;
  }, [isFolderDocument, actorOptions]);

  const getActorOptionsForInstance = (instanceId: number) => {
    const selectedActorIds = page.instances
      .filter((i) => i.id !== instanceId && i.actor)
      .map((i) => i.actor);
    return actorAvailableOptions.filter(
      (option) => !selectedActorIds.includes(option.id),
    );
  };

  const actorLabel = (instance: TypeUnbindPageInstance) => {
    if (instance.actor) {
      return (
        instance.subType &&
        actorOptions?.find((actor) => actor.id === instance.actor)?.label
      );
    } else {
      if (isFolderDocument) return t('folder.unbind.modal.isFolderDocument');
      return t('folder.unbind.modal.actorPlaceholder');
    }
  };

  const updatePage = useCallback(
    (modifications: Object) => {
      setPage(page.id, modifications, false, false, false);
    },
    [page.id, setPage],
  );

  const setPageType = (instanceId: number, type: string) => {
    updatePage({
      instanceId: instanceId,
      type: type,
      subType: undefined,
      actor: undefined,
      validated: false,
    });
  };

  const setPageSubtype = useCallback(
    (instanceId: number, subtype: string) => {
      const type = page.instances[0].type;
      updatePage({
        instanceId: instanceId,
        type: type,
        subType: subtype,
        actor: undefined,
        validated: false,
      });
    },
    [updatePage, page.instances],
  );

  const isPageInstanceComplete = useCallback(
    (instance: TypeUnbindPageInstance) => {
      return (
        instance.type &&
        instance.subType &&
        (instance.actor || !actorAvailableOptions.length)
      );
    },
    [actorAvailableOptions.length],
  );

  const setPageActor = useCallback(
    (instanceId: number, actorId: string) => {
      const type = page.instances[0].type;
      const subType = page.instances[0].subType;
      updatePage({
        instanceId: instanceId,
        type: type,
        subType: subType,
        actor: actorId,
        validated: false,
      });
    },
    [updatePage, page.instances],
  );

  const getPageStatus = useCallback(() => {
    if (page.instances.length === 1) {
      const firstInstance = page.instances[0];
      if (!firstInstance.type && !firstInstance.subType && !firstInstance.actor)
        return '';
    }
    for (const instance of page.instances) {
      if (!isPageInstanceComplete(instance)) {
        return 'page-not-completed';
      }
    }
    if (page.validated) return 'page-validated';
    return 'page-completed';
  }, [page.instances, page.validated, isPageInstanceComplete]);

  useEffect(() => {
    page.instances.forEach((instance) => {
      if (instance.type && !instance.subType && subtypeOptions.length === 1) {
        setPageSubtype(instance.id, subtypeOptions[0]?.id);
      }
      if (instance.subType && !instance.actor) {
        const availableActorsForInstance = getActorOptionsForInstance(
          instance.id,
        );
        if (availableActorsForInstance.length === 1) {
          setPageActor(instance.id, availableActorsForInstance[0]?.id);
        }
      }
    });

    setStatusClassName(getPageStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page.instances,
    page.validated,
    subtypeOptions,
    setPageSubtype,
    actorAvailableOptions,
    setPageActor,
    getPageStatus,
  ]);

  const updatePageChecked = (isShiftPressed: boolean) => {
    setPage(page.id, { checked: !page.checked }, true, isShiftPressed, false);
  };

  const updatePageValidated = () => {
    setPage(page.id, { validated: !page.validated }, false, false, true);
  };

  return (
    <div
      className={`unbind-page d-flex align-items-center ${statusClassName} ${className}`}
    >
      <Modal
        show={isFullScreenVisible}
        onHide={() => {
          setIsFullScreenVisible(false);
        }}
        centered
        className={`modal-unbind-visualizer with-pages`}
      >
        <Modal.Header>
          <button
            onClick={() => setIsFullScreenVisible(false)}
            className="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Document file={{ data: data as Uint8Array }} loading={<BmwLoader />}>
            <Page pageNumber={page.pageNumber + 1} />
          </Document>
        </Modal.Body>
      </Modal>

      <div className="unbind-page-content d-flex flex-column justify-content-center align-items-center">
        <div className="unbind-page-content-header mb-3 text-center">
          {ReactHtmlParser(t('folder.unbind.modal.multiSelect'))}
        </div>
        <OverlayTrigger
          overlay={
            <Tooltip
              className="tooltip-primary"
              id="tooltip-disabled"
              placement="bottom-start"
            >
              {t('folder.unbind.modal.multiSelect-tooltip')}
            </Tooltip>
          }
        >
          <div className="unbind-page-checkbox mr-0">
            <label
              className="checkbox-container"
              onClick={(e) => {
                e.preventDefault();
                updatePageChecked(e.shiftKey);
              }}
            >
              <input type="checkbox" checked={page.checked} />
              <span className="checkmark"></span>
            </label>
          </div>
        </OverlayTrigger>
      </div>
      <div className="unbind-page-img d-flex align-items-center justify-content-center">
        {thumbnail && (
          <img
            src={thumbnail}
            alt={`${t('folder.unbind.modal.pageNumber')}${page.pageNumber}`}
          />
        )}
        <div
          className="unbind-page-img-expand d-flex align-items-center justify-content-center"
          onClick={() => {
            setIsFullScreenVisible(true);
          }}
        >
          <i className="fa fa-expand" />
        </div>
      </div>
      <div className="unbind-page-content flex-grow-1">
        <div className="unbind-page-content-header d-flex align-items-center justify-content-between pl-4">
          <div>
            {`${t('folder.unbind.modal.pageNumber')}${
              page.pageNumber + 1
            }/${totalPages}`}
          </div>
          <div className="d-flex align-items-center">
            {[
              'page-completed',
              'page-validated',
              'page-not-completed',
            ].includes(statusClassName) && (
              <div
                className="ml-5 d-flex align-items-center cursor-pointer"
                onClick={() => {
                  resetPage(page.pageNumber);
                }}
              >
                <div className="mr-2">{t('folder.unbind.modal.reset')}</div>
                <i className="fa fa-rotate-left mr-2" />
              </div>
            )}
            {['page-completed', 'page-validated'].includes(statusClassName) && (
              <div className="ml-5 d-flex align-items-center">
                <div className="mr-2">
                  {t('folder.unbind.modal.validateSelection')}
                </div>
                <div className="unbind-page-checkbox mr-0">
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={page.validated}
                      onChange={() => updatePageValidated()}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
        {page.instances.map((instance) => (
          <>
            <div className="unbind-page-content-selects d-flex">
              <div className="unbind-page-content-select">
                <div className="unbind-page-content-select-label">
                  {t('folder.unbind.modal.type')}
                </div>
                <BmwDropdown
                  disabled={instance.id > 0}
                  onSelect={(key: string | null) => {
                    if (key) setPageType(instance.id, key);
                  }}
                  items={typeOptions}
                  label={
                    instance.type
                      ? typeOptions?.find((type) => type.id === instance.type)
                          ?.label
                      : t('folder.unbind.modal.typePlaceholder')
                  }
                  className={`unbind-dropdown ${
                    page.instances.length > 1 ? 'unbind-dropdown-primary' : ''
                  }`}
                />
              </div>
              <div className="unbind-page-content-select">
                <div className="unbind-page-content-select-label">
                  {t('folder.unbind.modal.subType')}
                </div>
                <BmwDropdown
                  disabled={!subtypeOptions.length || instance.id > 0}
                  onSelect={(key: string | null) => {
                    if (key) setPageSubtype(instance.id, key);
                  }}
                  items={subtypeOptions}
                  label={
                    instance.type && instance.subType
                      ? subtypeOptions.find(
                          (subtype) => subtype.id === instance.subType,
                        )?.label
                      : t('folder.unbind.modal.subTypePlaceholder')
                  }
                  className={`unbind-dropdown ${
                    page.instances.length > 1 ? 'unbind-dropdown-primary' : ''
                  }`}
                />
              </div>
              <div className="unbind-page-content-select">
                <div className="unbind-page-content-select-label">
                  {t('folder.unbind.modal.actor')}
                </div>
                <BmwDropdown
                  disabled={
                    !actorOptions.length ||
                    !instance.subType ||
                    !actorAvailableOptions.length
                  }
                  onSelect={(key: string | null) => {
                    if (key) setPageActor(instance.id, key);
                  }}
                  items={getActorOptionsForInstance(instance.id)}
                  label={actorLabel(instance)}
                  className="unbind-dropdown"
                />
              </div>
              <div className="copy-action-container">
                {instance.id > 0 ? (
                  <OverlayTrigger
                    overlay={
                      <Tooltip className="tooltip-danger" id="tooltip-disabled">
                        {t('folder.unbind.modal.delete-page')}
                      </Tooltip>
                    }
                  >
                    <i
                      className={'fa fa-trash-o icon-button-medium icon-danger'}
                      onClick={() => {
                        deletePageInstance(page.id, instance.id);
                      }}
                    ></i>
                  </OverlayTrigger>
                ) : actorAvailableOptions &&
                  actorAvailableOptions.length > page.instances.length ? (
                  <OverlayTrigger
                    overlay={
                      <Tooltip
                        className="tooltip-primary"
                        id="tooltip-disabled"
                      >
                        {t('folder.unbind.modal.copy-page')}
                      </Tooltip>
                    }
                  >
                    <i
                      className={
                        'fa fa-light fa-clone icon-button-medium icon-primary'
                      }
                      onClick={() => {
                        updatePage({
                          instanceId: page.instances.length,
                          type: page.instances[0].type,
                          subType: page.instances[0].subType,
                          actor: undefined,
                        });
                      }}
                    ></i>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    overlay={
                      <Tooltip
                        className="tooltip-disabled"
                        id="tooltip-disabled"
                      >
                        {t('folder.unbind.modal.copy-unavailable')}
                      </Tooltip>
                    }
                  >
                    <i
                      className={
                        'fa fa-light fa-clone icon-button-medium icon-primary icon-disabled'
                      }
                    ></i>
                  </OverlayTrigger>
                )}
              </div>
            </div>
            {instance.replaceAlert && (
              <div className="unbind-page-content-alert d-flex align-items-center">
                <i className="fa fa-exclamation-triangle mr-2" />
                <div>{t('folder.unbind.modal.alreadyExists')}</div>
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default UnbindPage;
