import React from 'react';
import { useTranslation } from 'react-i18next';
import BmwYesNoModal from './BmwYesNoModal';
import { logout } from '../../services/loginService';

type Props = {
  show: boolean;
  handleClose: () => void;
};

const BmwLogout = (props: Props) => {
  const { show, handleClose } = props;
  const { t } = useTranslation();
  return (
    <BmwYesNoModal
      show={show}
      handleClose={handleClose}
      title={t('logout.modal.title')}
      yesButton={{
        label: t('logout.modal.yes'),
        onClick: async () => {
          await logout();
          handleClose();
        },
      }}
      noButton={{ label: t('logout.modal.no'), onClick: handleClose }}
    >
      {t('logout.modal.description')}
    </BmwYesNoModal>
  );
};

export default BmwLogout;
