import React, { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalButton } from '../../types/globalTypes';
import BmwButton from '../form/BmwButton';

type Props = {
  show: boolean;
  handleClose: () => void;
  title: string;
  children?: ReactNode;
  yesButton?: ModalButton;
  noButton?: ModalButton;
  yesButtonDisabled?: boolean | undefined;
};

const BmwYesNoModal = (props: Props) => {
  const {
    show,
    handleClose,
    title,
    children,
    yesButton,
    noButton,
    yesButtonDisabled,
  } = props;
  return (
    <Modal show={show} onHide={handleClose} centered className="w-100">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <button
          onClick={() => handleClose()}
          className="close outline-none shadow-none"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <div className="options-menu d-flex justify-content-between">
          {yesButton && (
            <BmwButton
              label={yesButton.label}
              onClick={() => yesButton?.onClick()}
              type={yesButton.type ? yesButton.type : 'primary'}
              className="button-option"
              disabled={yesButtonDisabled}
            />
          )}
          {noButton && (
            <BmwButton
              label={noButton.label}
              onClick={() => noButton?.onClick()}
              type={noButton.type ? noButton.type : 'secondary'}
            />
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default BmwYesNoModal;
