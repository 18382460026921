import React from 'react';
import { useTranslation } from 'react-i18next';
import HtmlParser from 'react-html-parser';

type Props = {
  path: string;
};

const Logout = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div
      id="logout"
      className="content-body bg-login px-2 px-sm-0 align-items-start"
    >
      <div className="logout custom-card-transparent d-flex flex-column align-items-center">
        <h2 className="title">{t('logout.title')}</h2>
        <p>{HtmlParser(t('logout.description'))}</p>
      </div>
    </div>
  );
};

export default Logout;
