import React from 'react';
import Reprise from '../reprise/Reprise';
import { FolderStore } from '../../../state/folder/FolderStore';
import { useStore } from 'effector-react';
import RepriseOneFile from '../reprise/RepriseOneFile';
import { TypeFolderStore } from '../../../types/storeTypes';

const RepriseCommercial = () => {
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  return (
    <>
      {folderStore.onePDF ? (
        <RepriseOneFile />
      ) : (
        <Reprise isError={(file) => file.rejected || !file.uploaded} />
      )}
    </>
  );
};

export default RepriseCommercial;
