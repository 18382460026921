import React from 'react';
import PublicDisplay from '../../components/public/PublicDisplay';

type Props = {
  path: string;
};

const PersonalData = (props: Props) => (
  <PublicDisplay name="personalData" hasLink={[8]} numberOfBloc={12} />
);

export default PersonalData;
