import React, { useEffect, useState } from 'react';
import FileItem from '../../../components/folder/study/FileItem';
import { useTranslation } from 'react-i18next';
import BmwBloc from '../../../components/layout/BmwBloc';
import BmwAlert from '../../../components/BmwAlert';
import { useStore } from 'effector-react';
import { TypeDocument } from '../../../types/globalTypes';
import { DocumentsStore } from '../../../state/documents/DocumentsStore';
import { requestGetDocumentFolder } from '../../../state/documents/DocumentsEffects';
import { FolderStore } from '../../../state/folder/FolderStore';
import { TypeFolderStore } from '../../../types/storeTypes';
import ButtonLaunchControl from '../../../components/folder/study/ButtonLaunchControl';

const RepriseOneFile = () => {
  const documentStore = useStore<TypeDocument[]>(DocumentsStore);
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const { t } = useTranslation();
  const file = documentStore[0];
  const [loading] = useState<boolean>(false);

  useEffect(() => {
    requestGetDocumentFolder({
      id: folderStore.id,
    });
  }, [folderStore.id]);

  return (
    <>
      <div className="mb-5">
        <BmwBloc
          title={t('folder.oneFile.title')}
          description={t('folder.oneFile.warning')}
        >
          <div className="btn-toolbar">
            {file && (
              <FileItem
                loading={loading}
                files={file.files}
                fileId={file.id}
                subtypeId={file.subtype?.id}
                isUploaded={file.files.length > 0}
                className="custom-file-btn error"
                title={
                  file.files.length > 0
                    ? file.files[0].name
                    : t('folder.oneFile.title')
                }
                isOnePdf={true}
                fileIndex={0}
              />
            )}
            <div className="d-flex ml-3">
              <BmwAlert type={'danger'} label={file?.refusal?.reason || ''} />
            </div>
          </div>
        </BmwBloc>
      </div>
      <ButtonLaunchControl disabled={!folderStore.completed} />
    </>
  );
};

export default RepriseOneFile;
