import { createEvent } from 'effector';
import { TypeActor } from '../../types/globalTypes';
import { TypeActorCompletion } from './ActorStore';
import { ActorReminderUpdateRequest } from '../../types/model/actorReminderUpdateRequest';

export const setActors = createEvent<TypeActor[]>('SET_ACTORS');
export const setActorCompletion = createEvent<TypeActorCompletion>(
  'SET_ACTOR_COMPLETION',
);
export const setActorReminder = createEvent<{
  id: string;
  dto: ActorReminderUpdateRequest;
}>('SET_ACTOR_REMINDER');
export const clearActors = createEvent('CLEAR_ACTORS');
export const setShouldUpdateLastInvitation = createEvent<{
  id: string;
  value: boolean;
}>('SET_SHOULD_UPDATE_LAST_INVITATION');
