import React, { useEffect, useState } from 'react';
import Header from '../../components/folder/Header';
import { getActorName, getPathVariable } from '../../utils/utils';
import { useStore } from 'effector-react';
import { FolderStore } from '../../state/folder/FolderStore';
import { UserStore } from '../../state/user/UserStore';
import { isOperator, PROFILES } from '../../utils/rights';
import FolderRouter from './FolderRouter';
import { requestGetFolder } from '../../state/folder/FolderEffects';
import { clearFolder } from '../../state/folder/FolderEvents';
import { requestGetActors } from '../../state/actor/ActorEffects';
import {
  BRAND,
  CATEGORY,
  ENDING_STATUS,
  MODAL_KEYS,
  PAYMENT_STATUS,
  REFUSAL_TYPE,
  STUDY_STATUS,
} from '../../utils/constants';
import { clearDocuments } from '../../state/documents/DocumentsEvents';
import { clearActors } from '../../state/actor/ActorEvents';
import { requestGetDocumentType } from '../../state/documentType/DocumentTypeEffects';
import { clearDocumentType } from '../../state/documentType/DocumentTypeEvents';
import {
  TypeFolderStore,
  TypeModalStore,
  TypeUserStore,
} from '../../types/storeTypes';
import { DocumentTypeItemResponse } from '../../types/model/documentTypeItemResponse';
import { DocumentTypeStore } from '../../state/documentType/DocumentTypeStore';
import { ActorStore } from '../../state/actor/ActorStore';
import { TypeActor, TypeBrand } from '../../types/globalTypes';
import { requestGetRefusals } from '../../state/refusal/RefusalEffets';
import { clearRefusals } from '../../state/refusal/RefusalEvents';
import { useTranslation } from 'react-i18next';
import { setBrand } from '../../state/header/HeaderEvents';
import { ActorCategoryEnum } from '../../types/model/actorCategoryEnum';
import Forbidden from '../error/Forbidden';
import { ParsedResponse } from '../../rest/ServerResponseParse';
import { ROUTE_NOT_FOUND } from '../../utils/routes';
import { navigate } from '@reach/router';
import { ModalStore } from '../../state/modal/ModalStore';
import { toggleModal } from '../../state/modal/ModalEvents';
import BmwYesNoModal from '../../components/modal/BmwYesNoModal';
import { logout } from '../../services/loginService';
import BmwLoader from '../../components/BmwLoader';
import HtmlParser from 'react-html-parser';
import FolderHistory from '../../components/folder/FolderHistory';

type Props = {
  path: string;
};

const Folder = (props: Props) => {
  const [forbidden, setForbidden] = useState<boolean>(false);
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const userStore = useStore<TypeUserStore>(UserStore);
  const documentTypeStore =
    useStore<DocumentTypeItemResponse[]>(DocumentTypeStore);
  const actorStore = useStore<TypeActor[]>(ActorStore);
  const modalStore = useStore<TypeModalStore>(ModalStore);
  const { t } = useTranslation();

  const handleForbidden = (err: ParsedResponse<any>): ParsedResponse<any> => {
    if (err.responseCode === 403) {
      setForbidden(true);
    }
    return err;
  };

  const handleNotFound = (err: ParsedResponse<any>): void => {
    if (err.responseCode === 404) {
      navigate(ROUTE_NOT_FOUND);
    }
  };

  useEffect(() => {
    const folderId = getPathVariable(3);
    if (!isNaN(folderId as any)) {
      requestGetActors({ id: folderId })
        .then(handleForbidden)
        .then(handleNotFound);
    } else {
      navigate(ROUTE_NOT_FOUND);
    }
    return () => {
      clearFolder();
      clearActors();
      clearDocuments();
      clearDocumentType();
      clearRefusals();
    };
  }, []);

  useEffect(() => {
    const folderId = getPathVariable(3);
    if (!isNaN(folderId as any)) {
      if (!!userStore.firstname) {
        requestGetFolder({
          id: folderId,
          isClient: userStore.profiles.includes(PROFILES.CLIENT),
        })
          .then(handleForbidden)
          .then(handleNotFound);
      }
    } else {
      navigate(ROUTE_NOT_FOUND);
    }
  }, [userStore.profiles, userStore.firstname]);

  useEffect(() => {
    clearDocumentType();
    if (!!folderStore.id && !ENDING_STATUS.includes(folderStore.status)) {
      requestGetDocumentType({
        id: CATEGORY.ACTOR,
      }).then(handleForbidden);
      requestGetDocumentType({
        id: CATEGORY.FOLDER,
      }).then(handleForbidden);
      requestGetDocumentType({
        id: CATEGORY.PAYMENT,
      }).then(handleForbidden);
    }
  }, [folderStore.id, folderStore.status]);

  useEffect(() => {
    clearRefusals();
    if (isOperator(userStore.profiles)) {
      if (!!folderStore.id && STUDY_STATUS.includes(folderStore.status)) {
        requestGetRefusals({
          id: REFUSAL_TYPE.STUDY_DOCUMENT,
        });
      } else if (PAYMENT_STATUS.includes(folderStore.status)) {
        requestGetRefusals({
          id: REFUSAL_TYPE.PAYMENT_DOCUMENT,
        });
      }
    }
  }, [userStore.profiles, folderStore.id, folderStore.status]);

  useEffect(() => {
    if (folderStore.brand) {
      setBrand(folderStore.brand as TypeBrand);
    }
    return () => {
      setBrand(BRAND.BMW as TypeBrand);
    };
  }, [folderStore.brand]);

  const displayHeader =
    ENDING_STATUS.includes(folderStore.status) ||
    !folderStore.onePDF ||
    !userStore.profiles.includes(PROFILES.CLIENT);

  const documentTypeNotLoaded =
    documentTypeStore.length === 0 &&
    !ENDING_STATUS.includes(folderStore.status);
  const actorNotLoaded = actorStore.length === 0;
  const folderNotLoaded = !folderStore.id;

  const loading = documentTypeNotLoaded || actorNotLoaded || folderNotLoaded;

  const actor = actorStore.find((a) => a.id === userStore.actorId);

  if (forbidden) {
    return <Forbidden />;
  }

  if (folderNotLoaded) {
    return <BmwLoader opaqueBackground />;
  }

  return (
    <>
      {loading && <BmwLoader opaqueBackground />}
      <BmwYesNoModal
        show={!!modalStore.clientUploadComplete}
        handleClose={() => toggleModal(MODAL_KEYS.clientUploadComplete)}
        title={t('folder.complete.title')}
        yesButton={{
          label: t('actions.disconnect'),
          onClick: async () => {
            toggleModal(MODAL_KEYS.clientUploadComplete);
            await logout();
          },
        }}
        noButton={{
          label: t('actions.continue'),
          onClick: () => {
            toggleModal(MODAL_KEYS.clientUploadComplete);
          },
        }}
      >
        {HtmlParser(t('folder.complete.description'))}
      </BmwYesNoModal>
      <div
        className={`content-body px-2 px-sm-0 ${
          userStore.profiles.includes(PROFILES.CLIENT)
            ? 'bg-client py-0'
            : 'container'
        }`}
      >
        <div
          className={`${
            userStore.profiles.includes(PROFILES.CLIENT)
              ? 'container bg-client-container flex-grow-1 px-4 px-md-5'
              : ''
          }`}
        >
          {displayHeader &&
            STUDY_STATUS.includes(folderStore.status) &&
            userStore.profiles.includes(PROFILES.CLIENT) &&
            actor && (
              <div className="mb-3 color-primary client-info">
                {t(
                  `folder.${
                    actor.category === ActorCategoryEnum.COMPANY
                      ? 'company'
                      : 'client'
                  }Message`,
                  {
                    name: getActorName(actor),
                  },
                )}
              </div>
            )}
          {displayHeader && <Header />}
          <FolderRouter />
          <FolderHistory />
        </div>
      </div>
    </>
  );
};

export default Folder;
