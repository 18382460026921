import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportingTable from '../../components/reporting/ReportingTable';
import ReportingFilter from '../../components/reporting/ReportingFilter';
import { ReportingAgencyTimeEnum } from '../../types/model/reportingAgencyTimeEnum';
import { TypeSort } from '../../types/tableTypes';
import { ORDER } from '../../utils/constants';
import { ReportingAgencyResponse } from '../../types/model/reportingAgencyResponse';
import { CONF_REPORTING_PAGE_SIZE } from '../../utils/configuration';
import { CONTENT_TYPE, restHandler } from '../../rest/RestClient';
import { EXPORTS, REPORTINGS } from '../../rest/apiPath';
import { ParsedResponse } from '../../rest/ServerResponseParse';
import {
  downloadFile,
  formatNumber,
  handleParsedResponse,
  roundNumber,
} from '../../utils/utils';
import { PageReportingAgency } from '../../types/model/pageReportingAgency';
import Pagination from '../../components/Pagination';
import moment from 'moment';
import {
  DATE_FORMAT_SHORT,
  DATE_INPUT_FORMAT,
  DATE_MONTH_YEAR_LITTERAL,
  formatDate,
} from '../../utils/date';
import BmwButton from '../../components/form/BmwButton';
import ReportingHeader from '../../components/reporting/ReportingHeader';

type Props = {
  path: string;
};

type Data = {
  id: string;
  title: string;
  cniMean: string;
  passportMean: string;
  jddMean: string;
  ribMean: string;
  titleX: string;
  cniXMean: string;
  passportXMean: string;
  jddXMean: string;
  ribXMean: string;
  titleMean: string;
  sofiadocClosed: string;
  sofiadocValidated: string;
  nonSofiadocClosed: string;
  nonSofiadocValidated: string;
  studyGoBackCountMean: string;
  paymentGoBackCountMean: string;
  studyProcessTimeMean: string;
  paymentProcessTimeMean: string;
  totalProcessTimeMean: string;
};

interface Column {
  name: string;
  label: string;
  className?: string;
  tooltip?: string;
  accessor: (data: any) => any;
}

interface Filter {
  startDate?: string;
  endDate?: string;
  dealershipIds: string[];
  district?: string;
  aggregation: ReportingAgencyTimeEnum;
}

const AgenceView = (props: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<Filter>({
    dealershipIds: [],
    aggregation: ReportingAgencyTimeEnum.DAY,
  });
  const [page, setPage] = useState<number>(0);
  const [maxPage, setMaxPage] = useState<number>(1);
  const [sort, setSort] = useState<TypeSort>({
    name: '',
    order: ORDER.ASC,
  });
  const [data, setData] = useState<ReportingAgencyResponse[]>([]);

  const buildSearchQueryParams = useCallback((): string => {
    return `?${filter.district ? `district=${filter.district}&` : ''}${
      filter.startDate ? `startDate=${filter.startDate}&` : ''
    }${filter.endDate ? `endDate=${filter.endDate}&` : ''}time=${
      filter.aggregation
    }&${filter.dealershipIds
      .map((dealershipId: string) => `dealershipIds=${dealershipId}&`)
      .join('')}pageIndex=${page}&pageSize=${CONF_REPORTING_PAGE_SIZE}`;
  }, [filter, page]);

  const search = useCallback(() => {
    setLoading(true);
    restHandler<PageReportingAgency>(
      REPORTINGS.AGENCY,
      buildSearchQueryParams(),
    )()
      .then((res: ParsedResponse<PageReportingAgency>) =>
        handleParsedResponse(res)((data: PageReportingAgency) => {
          setData(data.content || []);
          setPage(data.pageIndex || 0);
          setMaxPage(data.maxPageIndex || 1);
        }),
      )
      .finally(() => setLoading(false));
  }, [buildSearchQueryParams]);

  useEffect(() => {
    search();
  }, [search]);

  const columns = [
    {
      label: t('reporting.agence.table.allFolders'),
      name: 'title',
      accessor: (data: string) => (
        <div className="font-weight-bold text-primary">{data}</div>
      ),
    },
    {
      label: t('reporting.agence.table.cni'),
      name: 'cniMean',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.agence.table.passport'),
      name: 'passportMean',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.agence.table.proofAddress'),
      name: 'jddMean',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.agence.table.rib'),
      name: 'ribMean',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.agence.table.folders'),
      name: 'titleX',
      className: 'mt-5',
      accessor: (data: string) => (
        <div className="font-weight-bold text-primary">{data}</div>
      ),
    },
    {
      label: t('reporting.agence.table.cni'),
      name: 'cniXMean',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.agence.table.passport'),
      name: 'passportXMean',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.agence.table.proofAddress'),
      name: 'jddXMean',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.agence.table.rib'),
      name: 'ribXMean',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.agence.table.detailFolders'),
      name: 'titleMean',
      className: 'mt-5',
      tooltip: t('reporting.agence.table.tooltip.detailFolders'),
      accessor: (data: string) => (
        <div className="font-weight-bold text-primary">{data}</div>
      ),
    },
    {
      label: t('reporting.agence.table.nbSofiadocFolders'),
      tooltip: t('reporting.agence.table.tooltip.nbSofiadocFolders'),
      name: 'sofiadocClosed',
      accessor: (data: string) => formatNumber(parseInt(data)),
    },
    {
      label: t('reporting.agence.table.nbValidatedSofiadocFolders'),
      name: 'sofiadocValidated',
      tooltip: t('reporting.agence.table.tooltip.nbValidatedSofiadocFolders'),
      accessor: (data: string) => formatNumber(parseInt(data)),
    },
    {
      label: t('reporting.agence.table.nbNonSofiadocFolders'),
      tooltip: t('reporting.agence.table.tooltip.nbNonSofiadocFolders'),
      name: 'nonSofiadocClosed',
      accessor: (data: string) => formatNumber(parseInt(data)),
    },
    {
      label: t('reporting.agence.table.nbNonSofiadocValidatedFolders'),
      name: 'nonSofiadocValidated',
      tooltip: t(
        'reporting.agence.table.tooltip.nbNonSofiadocValidatedFolders',
      ),
      accessor: (data: string) => formatNumber(parseInt(data)),
    },
    {
      label: t('reporting.agence.table.nbGobackStudy'),
      name: 'studyGoBackCountMean',
      tooltip: t('reporting.agence.table.tooltip.nbGobackStudy'),
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.agence.table.nbGobackPayment'),
      name: 'paymentGoBackCountMean',
      tooltip: t('reporting.agence.table.tooltip.nbGobackPayment'),
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.agence.table.averageDurationStudy'),
      name: 'studyProcessTimeMean',
      tooltip: t('reporting.agence.table.tooltip.averageDurationStudy'),
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.agence.table.averageDurationPayment'),
      name: 'paymentProcessTimeMean',
      tooltip: t('reporting.agence.table.tooltip.averageDurationPayment'),
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.agence.table.averageDurationGlobal'),
      name: 'totalProcessTimeMean',
      tooltip: t('reporting.agence.table.tooltip.averageDurationGlobal'),
      accessor: (data: string) => data,
    },
  ] as Column[];

  const getTimeInDay = (time: number): string | undefined =>
    formatDate(
      moment().add(time, 'days').format(DATE_INPUT_FORMAT),
      DATE_FORMAT_SHORT,
      DATE_INPUT_FORMAT,
      false,
    );

  const getTimeInWeek = (year: number, time: number): string | undefined => {
    const startOfWeek = moment().year(year).isoWeek(time).startOf('isoWeek');
    const endOfWeek = moment().year(year).isoWeek(time).endOf('isoWeek');
    const start =
      !filter.startDate ||
      startOfWeek.isSameOrAfter(moment(filter.startDate, DATE_FORMAT_SHORT))
        ? startOfWeek
        : moment(filter.startDate, DATE_FORMAT_SHORT);
    const end =
      !filter.endDate ||
      endOfWeek.isSameOrBefore(moment(filter.endDate, DATE_FORMAT_SHORT))
        ? endOfWeek
        : moment(filter.endDate, DATE_FORMAT_SHORT);
    return `${formatDate(
      start.format(DATE_INPUT_FORMAT),
      DATE_FORMAT_SHORT,
      DATE_INPUT_FORMAT,
      false,
    )} - ${formatDate(
      end.format(DATE_INPUT_FORMAT),
      DATE_FORMAT_SHORT,
      DATE_INPUT_FORMAT,
      false,
    )}`;
  };

  const getTimeInYear = (year: number, time: number): string | undefined => {
    const startOfMonth = moment()
      .year(year)
      .month(time - 1)
      .startOf('month');
    const endOfMonth = moment()
      .year(year)
      .month(time - 1)
      .endOf('month');
    const start =
      !filter.startDate ||
      startOfMonth.isSameOrAfter(moment(filter.startDate, DATE_FORMAT_SHORT))
        ? startOfMonth
        : moment(filter.startDate, DATE_FORMAT_SHORT);
    const end =
      !filter.endDate ||
      endOfMonth.isSameOrBefore(moment(filter.endDate, DATE_FORMAT_SHORT))
        ? endOfMonth
        : moment(filter.endDate, DATE_FORMAT_SHORT);
    if (start.date() !== 1 || end.date() !== end.daysInMonth()) {
      return `${formatDate(
        start.format(DATE_INPUT_FORMAT),
        DATE_FORMAT_SHORT,
        DATE_INPUT_FORMAT,
        false,
      )} - ${formatDate(
        end.format(DATE_INPUT_FORMAT),
        DATE_FORMAT_SHORT,
        DATE_INPUT_FORMAT,
        false,
      )}`;
    }
    const date = start.format(DATE_MONTH_YEAR_LITTERAL);
    return date.charAt(0).toUpperCase() + date.slice(1);
  };

  const getTimeToDisplay = (
    year?: number,
    time?: number,
  ): string | undefined => {
    if (
      (!time && time !== 0) ||
      (!year && filter.aggregation !== ReportingAgencyTimeEnum.DAY)
    ) {
      return '';
    }
    if (filter.aggregation === ReportingAgencyTimeEnum.DAY) {
      return getTimeInDay(time);
    } else if (filter.aggregation === ReportingAgencyTimeEnum.WEEK) {
      return getTimeInWeek(year || moment().year(), time);
    }
    return getTimeInYear(year || moment().year(), time);
  };

  const mapToData = (value: ReportingAgencyResponse): Data => {
    const date = getTimeToDisplay(value.year, value.time) || '';
    return {
      id: value.time?.toString() || '',
      title: date,
      titleX: date,
      titleMean: date,
      cniMean:
        value.cniMean || value.cniMean === 0
          ? `${roundNumber(value.cniMean, 1)}%`
          : '',
      passportMean:
        value.passportMean || value.passportMean === 0
          ? `${roundNumber(value.passportMean, 1)}%`
          : '',
      jddMean:
        value.jddMean || value.jddMean === 0
          ? `${roundNumber(value.jddMean, 1)}%`
          : '',
      ribMean:
        value.ribMean || value.ribMean === 0
          ? `${roundNumber(value.ribMean, 1)}%`
          : '',
      cniXMean:
        value.cniXMean || value.cniXMean === 0
          ? `${roundNumber(value.cniXMean, 1)}%`
          : '',
      passportXMean:
        value.passportXMean || value.passportXMean === 0
          ? `${roundNumber(value.passportXMean, 1)}%`
          : '',
      jddXMean:
        value.jddXMean || value.jddXMean === 0
          ? `${roundNumber(value.jddXMean, 1)}%`
          : '',
      ribXMean:
        value.ribXMean || value.ribXMean === 0
          ? `${roundNumber(value.ribXMean, 1)}%`
          : '',
      sofiadocClosed: value.sofiadocClosed?.toString() || '0',
      sofiadocValidated: value.sofiadocValidated?.toString() || '0',
      nonSofiadocClosed: value.nonSofiadocClosed?.toString() || '0',
      nonSofiadocValidated: value.nonSofiadocValidated?.toString() || '0',
      studyProcessTimeMean:
        value.studyProcessTimeMean || value.studyProcessTimeMean === 0
          ? `${roundNumber(value.studyProcessTimeMean, 1)}j`
          : '',
      paymentProcessTimeMean:
        value.paymentProcessTimeMean || value.paymentProcessTimeMean === 0
          ? `${roundNumber(value.paymentProcessTimeMean, 1)}j`
          : '',
      totalProcessTimeMean:
        value.totalProcessTimeMean || value.totalProcessTimeMean === 0
          ? `${roundNumber(value.totalProcessTimeMean, 1)}j`
          : '',
      studyGoBackCountMean: roundNumber(value.studyGoBackCountMean, 1),
      paymentGoBackCountMean: roundNumber(value.paymentGoBackCountMean, 1),
    };
  };

  const onExport = useCallback(() => {
    setLoading(true);
    restHandler<string>(
      EXPORTS.AGENCY,
      buildSearchQueryParams(),
      undefined,
      CONTENT_TYPE.EXCEL,
    )()
      .then((res: ParsedResponse<string>) => {
        handleParsedResponse(res)((blob: Blob) =>
          downloadFile(res.filename || '', blob),
        );
      })
      .finally(() => setLoading(false));
  }, [buildSearchQueryParams]);

  return (
    <div className="content-body container-fluid px-2 px-sm-0">
      <div className="container">
        <ReportingHeader />
        <div className="mb-5 d-flex flex-row flex-wrap align-items-stretch justify-content-between">
          <h2 className="title">
            {t('reporting.agence.title.first')}
            <br />
            {t('reporting.agence.title.second')}
          </h2>
          <BmwButton
            type="primary"
            className="export"
            label={t('reporting.export')}
            onClick={onExport}
            disabled={loading}
          />
        </div>
        <div className="mt-3">
          <ReportingFilter
            filter={filter}
            setFilter={setFilter}
            setPage={setPage}
            disabled={loading}
            aggregationFilter
          />
        </div>
      </div>
      <div className="my-3 overflow-auto">
        <ReportingTable
          loading={loading}
          setLoading={setLoading}
          columns={columns}
          data={data.map(
            (reporting: ReportingAgencyResponse): Data => mapToData(reporting),
          )}
          horizontal
          sort={sort}
          setSort={setSort}
          headerMarginAuto={false}
        />
      </div>
      <Pagination
        maxPage={maxPage}
        changePage={(newPage: number) => {
          setPage(newPage - 1);
        }}
        disabled={loading}
        currentPage={page + 1}
        minPage={1}
        size={7}
      />
    </div>
  );
};

export default AgenceView;
