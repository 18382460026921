import React from 'react';
import { useTranslation } from 'react-i18next';
import HtmlParser from 'react-html-parser';
import { Link } from '@reach/router';

type Props = {
  path: string;
};

const Cookie = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className="content-body container px-2 px-sm-0">
      <h2>{t('cookie.h2')}</h2>
      <p className="text-justify">
        {HtmlParser(t('cookie.definition'))}
        <Link
          to={'#'}
          onClick={() =>
            window.open(
              'https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser',
              '_blank',
            )
          }
        >
          {HtmlParser(t('cookie.cnilLink'))}
        </Link>
      </p>
      {HtmlParser(t('cookie.body'))}
    </div>
  );
};

export default Cookie;
