import { createStore } from 'effector';
import { TypeUserStore } from '../../types/storeTypes';
import { clearUser, setUser } from './UserEvents';
import { CONF_CLIENT_TYPE, CONF_PROFILE } from '../../utils/configuration';
import { MeInformationResponse } from '../../types/model/meInformationResponse';
import { TypeProfile } from '../../types/rightTypes';

export const UserStore = createStore<TypeUserStore>({
  profiles: CONF_PROFILE,
  clientType: CONF_CLIENT_TYPE,
})
  .on(setUser, (state: TypeUserStore, payload: MeInformationResponse) => ({
    ...state,
    ...payload,
    profiles: payload.profiles as TypeProfile[],
  }))
  .on(clearUser, (state: TypeUserStore) => ({
    ...state,
    profiles: [],
  }));
