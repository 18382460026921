import React from 'react';
import { TypeColumn, TypeSort } from '../types/tableTypes';
import { ORDER } from '../utils/constants';
import { navigate } from '@reach/router';
import { ROUTE_FOLDER } from '../utils/routes';
import Pagination from './Pagination';
import BmwLoader from './BmwLoader';
import { FolderItemResponse } from '../types/model/folderItemResponse';

type Props = {
  columns: TypeColumn[];
  data: FolderItemResponse[];
  loading: boolean;
  page: number;
  maxPage: number;
  sort: TypeSort;
  setPage: (page: number) => void;
  setSort: (sort: TypeSort) => void;
};

const BmwTable = (props: Props) => {
  const { columns, data, loading, sort, page, maxPage, setSort, setPage } =
    props;

  const onSortChange = (column: TypeColumn) => {
    if (sort.name === column.name && sort.order === ORDER.ASC) {
      setSort({ name: column.name, order: ORDER.DESC });
    } else {
      setSort({ name: column.name, order: ORDER.ASC });
    }
  };

  return (
    <div className="custom-container-table">
      <div className="custom-table striped">
        {loading && <BmwLoader background />}
        <div className="custom-thead">
          <div className="custom-tr">
            {columns.map((column: TypeColumn) => (
              <div
                key={column.name}
                onClick={() => onSortChange(column)}
                className={`custom-th ${
                  sort.name === column.name
                    ? sort.order === ORDER.ASC
                      ? 'asc'
                      : 'desc'
                    : ''
                }`}
              >
                <div className="custom-content">{column.label}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="custom-tbody">
          {data.map((d: FolderItemResponse) => (
            <div
              key={d.id}
              className="custom-tr-group"
              onClick={() => navigate(ROUTE_FOLDER.replace(':id', d.id))}
            >
              <div className="custom-tr">
                {columns.map((column: TypeColumn) => (
                  <div key={`${column.name}_${d.id}`} className="custom-td">
                    <div className="custom-content">
                      {column.accessor(d[column.name])}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Pagination
        maxPage={maxPage}
        changePage={(newPage: number) => {
          setPage(newPage - 1);
        }}
        disabled={loading}
        currentPage={page + 1}
        minPage={1}
        size={7}
      />
    </div>
  );
};

export default BmwTable;
