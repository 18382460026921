import React, { ReactNode } from 'react';
import BmwButton from './form/BmwButton';
import { useTranslation } from 'react-i18next';

type Props = {
  label: string | ReactNode;
  type?: 'danger' | 'warning' | 'info';
  onClose: () => void;
};

const BmwBanner = (props: Props) => {
  const { label, type = 'danger', onClose } = props;
  const { t } = useTranslation();
  return (
    <div id="banner" className={`alert-${type}`}>
      <div className="container">
        <i className="fa fa-times close" onClick={onClose} />
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="my-2">{label}</div>
          <BmwButton
            className="float-right small my-2"
            type="tertiary"
            label={t('actions.ok')}
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default BmwBanner;
