import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Form } from 'react-bootstrap';
import BmwDropdown, { TypeItem } from '../form/BmwDropdown';
import BmwButton from '../form/BmwButton';
import {
  DATE_FORMAT_SHORT,
  DATE_INPUT_FORMAT,
  formatDate,
} from '../../utils/date';
import {
  getQueryVariable,
  handleParsedResponse,
  isMouseInside,
} from '../../utils/utils';
import { restListHandler } from '../../rest/RestClient';
import { DealershipItemResponse } from '../../types/model/dealershipItemResponse';
import { DEALERSHIPS } from '../../rest/apiPath';
import { ParsedResponse } from '../../rest/ServerResponseParse';
import BmwLoader from '../BmwLoader';
import { ReportingAgencyTimeEnum } from '../../types/model/reportingAgencyTimeEnum';
import { requestGetDealerships } from '../../state/dealership/DealershipEffects';

interface SelectedDealership {
  id: string;
  name: string;
}

interface Filter {
  startDate?: string;
  endDate?: string;
  dealershipIds: string[];
  district?: string;
  aggregation?: string;
  folderId?: string;
}

type Props = {
  filter: Filter;
  setFilter: Function;
  setPage: Function;
  disabled: boolean;
  aggregationFilter?: boolean;
  folderIdFilter?: boolean;
};

const ReportingFilter = (props: Props) => {
  const {
    filter,
    setFilter,
    setPage,
    disabled,
    aggregationFilter = false,
    folderIdFilter = false,
  } = props;
  const { t } = useTranslation();
  const id = getQueryVariable('id');
  const name = getQueryVariable('name');
  const [search, setSearch] = useState<string>('');
  const [dealerships, setDealerships] = useState<DealershipItemResponse[]>([]);
  const [selectedDealerships, setSelectedDealerships] = useState<
    SelectedDealership[]
  >(
    id && name
      ? [
          {
            id,
            name,
          },
        ]
      : [],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [districts, setDistricts] = useState<string[]>([]);
  const [folderId, setFolderId] = useState<string>('');

  const searchDealership = useCallback(() => {
    if (search) {
      setLoading(true);
      restListHandler<DealershipItemResponse>(
        `${DEALERSHIPS.GET}?search=${search}`,
      )().then((res: ParsedResponse<DealershipItemResponse[]>) =>
        handleParsedResponse(res)((data: DealershipItemResponse[]) => {
          setDealerships(
            data.filter(
              (dealership: DealershipItemResponse) =>
                !selectedDealerships
                  .map(
                    (selectedDealerships: SelectedDealership) =>
                      selectedDealerships.id,
                  )
                  .includes(dealership.sofiawebId || ''),
            ),
          );
          setLoading(false);
        }),
      );
    } else {
      setDealerships([]);
    }
  }, [search, selectedDealerships]);

  useEffect(() => {
    searchDealership();
  }, [searchDealership]);

  useEffect(() => {
    window.addEventListener('click', clickCallback);
    requestGetDealerships().then(
      (result: ParsedResponse<DealershipItemResponse[]>) => {
        if (result.ok && result.data) {
          setDistricts(
            result.data
              .map((dealership: DealershipItemResponse) => dealership.district)
              .filter(
                (district: string, index: number, self: string[]) =>
                  self.indexOf(district) === index,
              )
              .sort((a: string, b: string) => (a > b ? 1 : -1)),
          );
        }
      },
    );
    return () => {
      window.removeEventListener('click', clickCallback);
    };
  }, []);

  const clickCallback = (event: MouseEvent): void => {
    const element = document.getElementById('dealerships-container');
    if (element) {
      const boundingClientRect = element.getBoundingClientRect();
      const inside = isMouseInside(
        event.clientX,
        event.clientY,
        boundingClientRect,
      );
      if (!inside) {
        setSearch('');
      }
    } else {
      setSearch('');
    }
  };

  const onDealershipClick = (dealership: DealershipItemResponse): void => {
    setFilter((prevState: Filter) => ({
      ...prevState,
      dealershipIds: [...prevState.dealershipIds, dealership.sofiawebId],
    }));
    setSelectedDealerships((prevState: SelectedDealership[]) => [
      ...prevState,
      {
        id: dealership.sofiawebId || '',
        name: dealership.name,
      },
    ]);
    setDealerships((prevState: DealershipItemResponse[]) =>
      prevState.filter(
        (item: DealershipItemResponse) =>
          item.sofiawebId !== dealership.sofiawebId,
      ),
    );
    setSearch('');
    setPage(0);
  };

  const onSelectedDealershipClick = (
    selectedDealership: SelectedDealership,
  ) => {
    setFilter((prevState: Filter) => ({
      ...prevState,
      dealershipIds: prevState.dealershipIds.filter(
        (dealershipId: string) => selectedDealership.id !== dealershipId,
      ),
    }));
    setSelectedDealerships((prevState: SelectedDealership[]) =>
      prevState.filter(
        (item: SelectedDealership) => item.id !== selectedDealership.id,
      ),
    );
    setPage(0);
  };

  const items = [
    ...[
      {
        id: '',
        label: t('reporting.filter.districts.all'),
      },
    ],
    ...districts.map((district: string) => ({ id: district, label: district })),
  ] as TypeItem[];

  return (
    <>
      <div
        className={`${
          aggregationFilter || folderIdFilter
            ? 'd-flex flex-column flex-lg-row align-content-strech align-items-lg-end'
            : ''
        }`}
      >
        <div className="flex-grow-1 d-flex flex-column flex-sm-row align-content-strech align-items-sm-center">
          <Form.Group className="flex-grow-1 mr-0 mr-sm-3">
            <Form.Label>{t('reporting.filter.period.start.label')}</Form.Label>
            <Form.Control
              disabled={disabled}
              type="date"
              max={
                filter.endDate &&
                formatDate(
                  filter.endDate,
                  DATE_INPUT_FORMAT,
                  DATE_FORMAT_SHORT,
                  false,
                )
              }
              onChange={(e) => setStartDate(e.target.value)}
              onBlur={() => {
                setFilter((prevState: Filter) => ({
                  ...prevState,
                  startDate: formatDate(
                    startDate,
                    DATE_FORMAT_SHORT,
                    DATE_INPUT_FORMAT,
                    false,
                  ),
                }));
                setPage(0);
              }}
            />
          </Form.Group>
          <Form.Group className="flex-grow-1">
            <Form.Label>{t('reporting.filter.period.end.label')}</Form.Label>
            <Form.Control
              disabled={disabled}
              type="date"
              min={
                filter.startDate &&
                formatDate(
                  filter.startDate,
                  DATE_INPUT_FORMAT,
                  DATE_FORMAT_SHORT,
                  false,
                )
              }
              onChange={(e) => setEndDate(e.target.value)}
              onBlur={() => {
                setFilter((prevState: Filter) => ({
                  ...prevState,
                  endDate: formatDate(
                    endDate,
                    DATE_FORMAT_SHORT,
                    DATE_INPUT_FORMAT,
                    false,
                  ),
                }));
                setPage(0);
              }}
            />
          </Form.Group>
        </div>
        {aggregationFilter && (
          <ButtonGroup className="triple-btn mb-3 ml-0 ml-lg-3">
            <BmwButton
              disabled={disabled}
              type={
                filter.aggregation === ReportingAgencyTimeEnum.DAY
                  ? 'primary'
                  : 'tertiary'
              }
              label={t('reporting.filter.aggregation.day')}
              onClick={() => {
                setFilter((prevState: Filter) => ({
                  ...prevState,
                  aggregation: ReportingAgencyTimeEnum.DAY,
                }));
                setPage(0);
              }}
            />
            <BmwButton
              disabled={disabled}
              type={
                filter.aggregation === ReportingAgencyTimeEnum.WEEK
                  ? 'primary'
                  : 'tertiary'
              }
              label={t('reporting.filter.aggregation.week')}
              onClick={() => {
                setFilter((prevState: Filter) => ({
                  ...prevState,
                  aggregation: ReportingAgencyTimeEnum.WEEK,
                }));
                setPage(0);
              }}
            />
            <BmwButton
              disabled={disabled}
              type={
                filter.aggregation === ReportingAgencyTimeEnum.MONTH
                  ? 'primary'
                  : 'tertiary'
              }
              label={t('reporting.filter.aggregation.month')}
              onClick={() => {
                setFilter((prevState: Filter) => ({
                  ...prevState,
                  aggregation: ReportingAgencyTimeEnum.MONTH,
                }));
                setPage(0);
              }}
            />
          </ButtonGroup>
        )}
        {folderIdFilter && (
          <Form.Group className="flex-grow-1 ml-sm-3">
            <Form.Label>{t('reporting.filter.folder_id.label')}</Form.Label>
            <Form.Control
              placeholder={t('reporting.filter.folder_id.placeholder')}
              onChange={(
                e: React.ChangeEvent<
                  HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
                >,
              ) => setFolderId(e.target.value)}
              onKeyPress={(event: React.KeyboardEvent) => {
                if (event.key === 'Enter') {
                  setFilter((prevState: Filter) => ({
                    ...prevState,
                    folderId: folderId,
                  }));
                  setPage(0);
                }
              }}
              type="text"
            />
          </Form.Group>
        )}
      </div>
      <div className="d-flex flex-column flex-sm-row align-content-strech align-items-sm-end">
        <div className="flex-grow-1">
          <Form.Group>
            <Form.Label>{t('reporting.filter.concessions.label')}</Form.Label>
            <Form.Control
              disabled={disabled}
              type="text"
              value={search}
              placeholder={t('reporting.filter.concessions.placeholder')}
              onChange={(e) => setSearch(e.target.value)}
            />
            {(dealerships.length > 0 || loading) && (
              <div id="dealerships-container" className="dealerships-container">
                {loading && <BmwLoader />}
                {!loading &&
                  dealerships.map((dealership: DealershipItemResponse) => {
                    return (
                      <div
                        key={dealership.sofiawebId}
                        className="dealership"
                        onClick={() => onDealershipClick(dealership)}
                      >
                        {`${dealership.name} (${dealership.sofiawebId})`}
                      </div>
                    );
                  })}
              </div>
            )}
          </Form.Group>
        </div>
        <div className="ml-0 ml-sm-3 district-dropdown">
          <BmwDropdown
            disabled={disabled}
            onSelect={(key: string | null) => {
              setFilter((prevState: Filter) => ({
                ...prevState,
                district: key,
              }));
              setPage(0);
            }}
            items={items}
            label={
              filter.district
                ? filter.district
                : t('reporting.filter.districts.label')
            }
          />
        </div>
      </div>
      <div className="d-flex selected-dealership-container">
        {selectedDealerships.map((selectedDealership: SelectedDealership) => (
          <div
            key={selectedDealership.id}
            className="selected-dealership"
            onClick={() => onSelectedDealershipClick(selectedDealership)}
          >
            {`${selectedDealership.name} (${selectedDealership.id})`}
          </div>
        ))}
      </div>
    </>
  );
};

export default ReportingFilter;
