import { useTranslation } from 'react-i18next';
import BmwYesNoModal from './BmwYesNoModal';
import React from 'react';
import { requestSendInvitation } from '../../state/actor/ActorEffects';
import { handleParsedResponse } from '../../utils/utils';
import { InvitationResponse } from '../../types/model/invitationResponse';
import { addToast } from '../../state/toast/ToastEvents';
import { setShouldUpdateLastInvitation } from '../../state/actor/ActorEvents';
import { TypeActor } from '../../types/globalTypes';

type Props = {
  show: boolean;
  handleClose: () => void;
  setLoading: (loading: boolean) => void;
  actor: TypeActor;
};

const BmwMailModal = (props: Props) => {
  const { show, handleClose, setLoading, actor } = props;
  const { t } = useTranslation();
  return (
    <BmwYesNoModal
      show={show}
      handleClose={handleClose}
      title={t('email.modal.title')}
      yesButton={{
        label: t('email.modal.yes'),
        onClick: async () => {
          setLoading(true);
          actor.id &&
            requestSendInvitation({
              actorId: actor.id,
            })
              .then((response) => {
                handleParsedResponse(
                  response,
                  true,
                )((value: InvitationResponse) => {
                  if (value.sent) {
                    addToast({
                      type: 'success',
                      title: t('toast.actor.invitation.success.title'),
                      message: t('toast.actor.invitation.success.message'),
                    });
                  } else {
                    addToast({
                      type: 'danger',
                      title: t('toast.actor.invitation.error.title'),
                      message: t('toast.actor.invitation.error.message'),
                    });
                  }
                  setShouldUpdateLastInvitation({
                    id: value.actorId,
                    value: true,
                  });
                });
              })
              .finally(() => setLoading(false));
          handleClose();
        },
      }}
      noButton={{ label: t('email.modal.no'), onClick: handleClose }}
    >
      {t('email.modal.description', {
        firstName: actor?.firstname,
        lastName: actor?.lastname,
        email: actor?.email,
      })}
    </BmwYesNoModal>
  );
};

export default BmwMailModal;
