import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportingTable from '../../components/reporting/ReportingTable';
import ReportingFilter from '../../components/reporting/ReportingFilter';
import { TypeSort } from '../../types/tableTypes';
import { ORDER, REPORTING_DEALERSHIP_ATTRIBUTES } from '../../utils/constants';
import { ROUTE_REPORTING_FOLDER } from '../../utils/routes';
import { navigate } from '@reach/router';
import Pagination from '../../components/Pagination';
import { TypeReportingDealershipAttribute } from '../../types/globalTypes';
import { CONTENT_TYPE, restHandler } from '../../rest/RestClient';
import { EXPORTS, REPORTINGS } from '../../rest/apiPath';
import { ParsedResponse } from '../../rest/ServerResponseParse';
import {
  downloadFile,
  formatNumber,
  handleParsedResponse,
  roundNumber,
} from '../../utils/utils';
import { ReportingDealershipResponse } from '../../types/model/reportingDealershipResponse';
import { PageReportingDealership } from '../../types/model/pageReportingDealership';
import { ReportingFolderResponse } from '../../types/model/reportingFolderResponse';
import { CONF_REPORTING_PAGE_SIZE } from '../../utils/configuration';
import BmwButton from '../../components/form/BmwButton';
import ReportingHeader from '../../components/reporting/ReportingHeader';

type Props = {
  path: string;
};

interface Data {
  id: string;
  name: string;
  count: string;
  studyProcessTimeMean: string;
  paymentProcessTimeMean: string;
  totalProcessTimeMean: string;
  studyGoBackCountMean: string;
  paymentGoBackCountMean: string;
  cniMean: string;
  passportMean: string;
  ribMean: string;
  jddMean: string;
}

interface Filter {
  startDate?: string;
  endDate?: string;
  dealershipIds: string[];
  district?: string;
}

interface Column {
  name: string;
  label: string;
  className?: string;
  accessor: (data: any) => any;
  width?: number;
}

const ConcessionView = (props: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<Filter>({
    dealershipIds: [],
  });
  const [page, setPage] = useState<number>(0);
  const [maxPage, setMaxPage] = useState<number>(1);
  const [sort, setSort] = useState<TypeSort>({
    name: REPORTING_DEALERSHIP_ATTRIBUTES.NAME,
    order: ORDER.ASC,
  });
  const [data, setData] = useState<ReportingDealershipResponse[]>([]);

  const convertColumnName = (
    name: TypeReportingDealershipAttribute,
  ): string => {
    switch (name) {
      case 'name':
        return 'NAME';
    }
  };

  const buildSearchQueryParams = useCallback((): string => {
    return `?${filter.district ? `district=${filter.district}&` : ''}${
      filter.startDate ? `startDate=${filter.startDate}&` : ''
    }${filter.endDate ? `endDate=${filter.endDate}&` : ''}${filter.dealershipIds
      .map((dealershipId: string) => `dealershipIds=${dealershipId}&`)
      .join(
        '',
      )}pageIndex=${page}&pageSize=${CONF_REPORTING_PAGE_SIZE}&sort=${convertColumnName(
      sort.name as TypeReportingDealershipAttribute,
    )}&direction=${sort.order}`;
  }, [filter, sort, page]);

  const search = useCallback(() => {
    setLoading(true);
    restHandler<PageReportingDealership>(
      REPORTINGS.DEALERSHIP,
      buildSearchQueryParams(),
    )()
      .then((res: ParsedResponse<PageReportingDealership>) =>
        handleParsedResponse(res)((data: PageReportingDealership) => {
          setData(data.content || []);
          setPage(data.pageIndex || 0);
          setMaxPage(data.maxPageIndex || 1);
        }),
      )
      .finally(() => setLoading(false));
  }, [buildSearchQueryParams]);

  useEffect(() => {
    search();
  }, [search]);

  const columns = [
    {
      label: t('reporting.concession.table.id'),
      name: 'name',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.concession.table.nbFolders'),
      name: 'count',
      className: 'custom-bg-blue',
      accessor: (data: string) => formatNumber(parseInt(data)),
      width: 180,
    },
    {
      label: t('reporting.concession.table.averageDurationStudy'),
      name: 'studyProcessTimeMean',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 190,
    },
    {
      label: t('reporting.concession.table.averageDurationPayment'),
      name: 'paymentProcessTimeMean',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 190,
    },
    {
      label: t('reporting.concession.table.averageDurationGlobal'),
      name: 'totalProcessTimeMean',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 190,
    },
    {
      label: t('reporting.concession.table.nbGobackStudy'),
      name: 'studyGoBackCountMean',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 160,
    },
    {
      label: t('reporting.concession.table.nbGobackPayment'),
      name: 'paymentGoBackCountMean',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 160,
    },
    {
      label: t('reporting.concession.table.cni'),
      name: 'cniMean',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.concession.table.passport'),
      name: 'passportMean',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.concession.table.rib'),
      name: 'ribMean',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.concession.table.proofAddress'),
      name: 'jddMean',
      accessor: (data: string) => data,
      width: 130,
    },
  ] as Column[];

  const mapToData = (value: ReportingDealershipResponse): Data => ({
    id: value.dealershipId || '',
    name: value.dealershipName || '',
    count: value.count?.toString() || '0',
    studyProcessTimeMean:
      value.studyProcessTimeMean || value.studyProcessTimeMean === 0
        ? `${roundNumber(value.studyProcessTimeMean, 1)}j`
        : '',
    paymentProcessTimeMean:
      value.paymentProcessTimeMean || value.paymentProcessTimeMean === 0
        ? `${roundNumber(value.paymentProcessTimeMean, 1)}j`
        : '',
    totalProcessTimeMean:
      value.totalProcessTimeMean || value.totalProcessTimeMean === 0
        ? `${roundNumber(value.totalProcessTimeMean, 1)}j`
        : '',
    studyGoBackCountMean: roundNumber(value.studyGoBackCountMean, 1),
    paymentGoBackCountMean: roundNumber(value.paymentGoBackCountMean, 1),
    cniMean:
      value.cniMean || value.cniMean === 0
        ? `${roundNumber(value.cniMean, 1)}%`
        : '',
    passportMean:
      value.passportMean || value.passportMean === 0
        ? `${roundNumber(value.passportMean, 1)}%`
        : '',
    ribMean:
      value.ribMean || value.ribMean === 0
        ? `${roundNumber(value.ribMean, 1)}%`
        : '',
    jddMean:
      value.jddMean || value.jddMean === 0
        ? `${roundNumber(value.jddMean, 1)}%`
        : '',
  });

  const onExport = useCallback(() => {
    setLoading(true);
    restHandler<string>(
      EXPORTS.DEALERSHIP,
      buildSearchQueryParams(),
      undefined,
      CONTENT_TYPE.EXCEL,
    )()
      .then((res: ParsedResponse<string>) => {
        handleParsedResponse(res)((blob: Blob) =>
          downloadFile(res.filename || '', blob),
        );
      })
      .finally(() => setLoading(false));
  }, [buildSearchQueryParams]);

  const supHeader = (
    <div className="custom-tr">
      <div className="custom-th not-sortable" style={{ flex: '1 0 9%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.concession.table.concession')}
        </div>
      </div>
      <div className="custom-th not-sortable" style={{ flex: '1 0 55%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.concession.table.folderTreatment')}
        </div>
      </div>
      <div className="custom-th not-sortable" style={{ flex: '1 0 36%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.concession.table.pieceAnalysis')}
        </div>
      </div>
    </div>
  );

  const mainData = [...data];
  const total = mainData.pop();

  return (
    <div className="content-body container-fluid px-2 px-sm-0">
      <div className="container">
        <ReportingHeader />
        <div className="mb-5 d-flex flex-row flex-wrap align-items-stretch justify-content-between">
          <h2 className="title">
            {t('reporting.concession.title.first')}
            <br />
            {t('reporting.concession.title.second')}
          </h2>
          <BmwButton
            type="primary"
            className="export"
            label={t('reporting.export')}
            onClick={onExport}
            disabled={loading}
          />
        </div>
        <div className="mt-3">
          <ReportingFilter
            filter={filter}
            setFilter={setFilter}
            setPage={setPage}
            disabled={loading}
          />
        </div>
      </div>
      <div className="my-3 overflow-auto">
        <ReportingTable
          loading={loading}
          setLoading={setLoading}
          columns={columns}
          data={mainData.map(
            (reporting: ReportingFolderResponse): Data => mapToData(reporting),
          )}
          total={total && mapToData(total)}
          sortableColumns={['name']}
          supHeader={supHeader}
          minWidth="1350px"
          sort={sort}
          setSort={setSort}
          onClick={(row: any) =>
            navigate(`${ROUTE_REPORTING_FOLDER}?id=${row.id}&name=${row.name}`)
          }
        />
      </div>
      <Pagination
        maxPage={maxPage}
        changePage={(newPage: number) => {
          setPage(newPage - 1);
        }}
        disabled={loading}
        currentPage={page + 1}
        minPage={1}
        size={7}
      />
    </div>
  );
};

export default ConcessionView;
