import React from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTE_REPORTING } from '../../utils/routes';
import { Link } from '@reach/router';

const ReportingHeader = () => {
  const { t } = useTranslation();

  return (
    <Link className={'text-decoration-none'} to={ROUTE_REPORTING}>
      <h5 className="mb-4 description">
        <i className="fa fa-chevron-left mr-2" />
        {t('reporting.header.backButton')}
      </h5>
    </Link>
  );
};

export default ReportingHeader;
