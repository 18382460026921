import { useStore } from 'effector-react/effector-react.cjs';
import { TypeFolderStore, TypeUserStore } from '../../types/storeTypes';
import { UserStore } from '../../state/user/UserStore';
import { navigate } from '@reach/router';
import { getDefaultRoute } from '../../utils/routes';
import BmwButton from '../../components/form/BmwButton';
import React, { useEffect } from 'react';
import { PROFILES } from '../../utils/rights';
import NotFound from '../error/NotFound';
import { setBrand } from '../../state/header/HeaderEvents';
import { TypeBrand } from '../../types/globalTypes';
import { BRAND } from '../../utils/constants';
import { FolderStore } from '../../state/folder/FolderStore';
import BmwLoader from '../../components/BmwLoader';
import { useTranslation } from 'react-i18next';
import HtmlParser from 'react-html-parser';

type Props = {
  path: string;
};

const Guidance = (props: Props) => {
  const userStore = useStore<TypeUserStore>(UserStore);
  const folderStore = useStore<TypeFolderStore>(FolderStore);

  const loading = !folderStore.id;

  const onBackClick = (): void => {
    navigate(getDefaultRoute(userStore));
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (folderStore.brand) {
      setBrand(folderStore.brand as TypeBrand);
    }
    return () => {
      setBrand(BRAND.BMW as TypeBrand);
    };
  }, [folderStore.brand]);

  return (
    <>
      {loading && <BmwLoader opaqueBackground />}
      {userStore.profiles.includes(PROFILES.CLIENT) ? (
        <div
          className={`content-body px-2 px-sm-0 ${
            userStore.profiles.includes(PROFILES.CLIENT)
              ? 'bg-client py-0'
              : 'container'
          }`}
        >
          <div
            className={`${
              userStore.profiles.includes(PROFILES.CLIENT)
                ? 'container bg-client-container flex-grow-1 px-4 px-md-5'
                : ''
            }`}
          >
            <h2>{t('guidancePage.title')}</h2>
            <p>{HtmlParser(t('guidancePage.content'))}</p>

            <video
              src="/files/Video_parcours_utilisateur_final_v2.0.mp4"
              width="100%"
              controls
            >
              Your browser does not support the HTML5 Video element.
            </video>

            <div className="mt-4">
              <BmwButton
                className="w-100-m"
                label="J'ai compris"
                onClick={onBackClick}
              />
            </div>
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default Guidance;
