import { TypeProfile } from '../types/rightTypes';

export const PROFILES = {
  RESTRICTED_DEALER: 'RESTRICTED_DEALER' as TypeProfile,
  DEALER: 'DEALER' as TypeProfile,
  UNRESTRICTED_DEALER: 'UNRESTRICTED_DEALER' as TypeProfile,
  STUDY_OPERATOR: 'STUDY_OPERATOR' as TypeProfile,
  PAYMENT_OPERATOR: 'PAYMENT_OPERATOR' as TypeProfile,
  CLIENT: 'CLIENT' as TypeProfile,
  BACK_OFFICE_REPORTING: 'BACK_OFFICE_REPORTING' as TypeProfile,
  BACK_OFFICE_LABEL: 'BACK_OFFICE_LABEL' as TypeProfile,
  LEVEL_2: 'LEVEL_2' as TypeProfile,
};

export const CLIENT_TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TERTIARY: 'TERTIARY',
};

export const isOperator = (profiles: TypeProfile[]): boolean =>
  profiles.includes(PROFILES.STUDY_OPERATOR) ||
  profiles.includes(PROFILES.PAYMENT_OPERATOR);

export const isDealer = (profiles: TypeProfile[]): boolean =>
  profiles.includes(PROFILES.DEALER) ||
  profiles.includes(PROFILES.RESTRICTED_DEALER) ||
  profiles.includes(PROFILES.UNRESTRICTED_DEALER);

export const isBackOffice = (profiles: TypeProfile[]): boolean =>
  profiles.includes(PROFILES.BACK_OFFICE_LABEL) ||
  profiles.includes(PROFILES.BACK_OFFICE_REPORTING);

export const hasAccessToFolder = (profiles: TypeProfile[]): boolean =>
  isDealer(profiles) ||
  isOperator(profiles) ||
  profiles.includes(PROFILES.CLIENT);

export const hasAccessToOpenBanking = (profiles: TypeProfile[]): boolean =>
  profiles.includes(PROFILES.DEALER) ||
  profiles.includes(PROFILES.RESTRICTED_DEALER) ||
  profiles.includes(PROFILES.CLIENT) ||
  profiles.includes(PROFILES.LEVEL_2);

export const hasAccessToDashboard = (profiles: TypeProfile[]): boolean =>
  hasAccessToFolder(profiles) && !profiles.includes(PROFILES.CLIENT);

export const isOnlyClientAndOptionalBackOffice = (
  profiles: TypeProfile[],
): boolean =>
  profiles.includes(PROFILES.CLIENT) && !hasAccessToDashboard(profiles);

export const getAllProfiles = () =>
  Object.keys(PROFILES).map((key: string) => PROFILES[key as TypeProfile]);

export const getDashboardProfiles = () =>
  Object.keys(PROFILES)
    .map((key: string) => PROFILES[key as TypeProfile])
    .filter(
      (profile: TypeProfile) =>
        !isBackOffice([profile]) && PROFILES.CLIENT !== profile,
    );

export const getBackOfficeProfiles = () =>
  Object.keys(PROFILES)
    .map((key: string) => PROFILES[key as TypeProfile])
    .filter((profile: TypeProfile) => isBackOffice([profile]));

export const hasOneProfileMatch = (
  a: TypeProfile[],
  b: TypeProfile[],
): boolean => !!a.find((value: TypeProfile) => b.includes(value));
