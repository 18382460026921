import React from 'react';
import { useTranslation } from 'react-i18next';
import BmwButton from '../../form/BmwButton';
import { restPatchHandler } from '../../../rest/RestClient';
import { FOLDERS } from '../../../rest/apiPath';
import { FolderResponse } from '../../../types/model/folderResponse';
import { useStore } from 'effector-react';
import { TypeFolderStore } from '../../../types/storeTypes';
import { FolderStore } from '../../../state/folder/FolderStore';
import { ParsedResponse } from '../../../rest/ServerResponseParse';
import { handleParsedResponse } from '../../../utils/utils';
import { setFolder } from '../../../state/folder/FolderEvents';

type Props = {
  disabled?: boolean;
  callback?: () => void;
};

const ButtonLaunchControl = (props: Props) => {
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const { disabled = false, callback } = props;
  const { t } = useTranslation();

  const onClick = (): void => {
    restPatchHandler<null, FolderResponse>(FOLDERS.STUDY_PROCESS)({
      id: folderStore.id,
      dto: null,
    })
      .then((result: ParsedResponse<FolderResponse>) =>
        handleParsedResponse(result)((data: FolderResponse) =>
          setFolder({ ...folderStore, ...data }),
        ),
      )
      .then(() => {
        if (callback) {
          callback();
        }
      });
  };

  return (
    <div className="d-flex justify-content-end">
      <BmwButton
        label={t('folder.launchControl')}
        onClick={onClick}
        disabled={disabled}
      />
    </div>
  );
};

export default ButtonLaunchControl;
