/**
 * Sofiadoc back end API.
 * API endpoints for front application.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: bmwfinance-e-kyc@reeliant.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type DocumentSourceEnum =
  | 'OPERATOR_MANDATORY'
  | 'OPERATOR_OPTIONAL'
  | 'DEALER_OPTIONAL'
  | 'CLIENT_OPTIONAL';

export const DocumentSourceEnum = {
  OPERATORMANDATORY: 'OPERATOR_MANDATORY' as DocumentSourceEnum,
  OPERATOROPTIONAL: 'OPERATOR_OPTIONAL' as DocumentSourceEnum,
  DEALEROPTIONAL: 'DEALER_OPTIONAL' as DocumentSourceEnum,
  CLIENTOPTIONAL: 'CLIENT_OPTIONAL' as DocumentSourceEnum,
};
