import React, { useEffect, useState } from 'react';
import { TypeBrand } from '../types/globalTypes';
import { BRAND } from '../utils/constants';
import {
  getDefaultRoute,
  ROUTE_BACK_OFFICE,
  ROUTE_DASHBOARD,
  ROUTE_FOLDER,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_REPORTING,
  ROUTE_TERMS,
} from '../utils/routes';
import { Link, navigate, Router } from '@reach/router';
import Menu from './Menu';
import { useStore } from 'effector-react';
import { UserStore } from '../state/user/UserStore';
import { TypeFolderStore, TypeUserStore } from '../types/storeTypes';
import { Dropdown } from 'react-bootstrap';
import {
  getAllProfiles,
  getDashboardProfiles,
  hasOneProfileMatch,
  PROFILES,
} from '../utils/rights';
import { TypeProfile } from '../types/rightTypes';
import { useTranslation } from 'react-i18next';
import BmwLogout from '../components/modal/BmwLogout';
import { FolderStore } from '../state/folder/FolderStore';

type Props = {
  brand: TypeBrand;
};

interface TypeOption {
  label: string;
  onClick?: () => void;
  route?: string;
  profiles: TypeProfile[];
}

const Header = (props: Props) => {
  const userStore = useStore<TypeUserStore>(UserStore);
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const { brand } = props;
  const { t } = useTranslation();
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [bool, setBool] = useState<boolean>(false);

  useEffect(() => {}, [bool]);

  const path = window.location.pathname;
  const options: TypeOption[] = [
    {
      label: t('header.dashboard'),
      route: ROUTE_DASHBOARD,
      profiles: getDashboardProfiles(),
    },
    {
      label: t('header.label'),
      route: ROUTE_BACK_OFFICE,
      profiles: [PROFILES.BACK_OFFICE_LABEL],
    },
    {
      label: t('header.reporting'),
      route: ROUTE_REPORTING,
      profiles: [PROFILES.BACK_OFFICE_REPORTING],
    },
    {
      label: t('header.disconnect'),
      onClick: () => setShowLogoutModal(true),
      profiles: getAllProfiles(),
    },
  ].filter(
    (option: TypeOption) =>
      hasOneProfileMatch(option.profiles, userStore.profiles) &&
      (option.label !== t('header.disconnect') ||
        (path !== ROUTE_LOGIN && path !== ROUTE_LOGOUT)),
  );

  const isMini = brand === BRAND.MINI;
  const isAlphera = brand === BRAND.ALPHERA;
  return (
    <header>
      <BmwLogout
        handleClose={() => setShowLogoutModal(false)}
        show={showLogoutModal}
      />
      <div className="container px-0">
        <div className="d-flex align-items-stretch justify-content-end flex-nowrap py-3">
          <div
            id="title"
            className="flex-grow-1 d-flex align-items-center overflow-hidden ml-2 ml-sm-0"
          >
            <span className="text-ellipsis w-100">
              {folderStore.id
                ? `${t('header.dealership')} ${folderStore.dealershipName}`
                : t('header.defaultDealership')}
            </span>
          </div>
          {options.length !== 0 && (
            <Dropdown id="user-menu" alignRight>
              <Dropdown.Toggle as="div" id="user-menu-toggle">
                <i className="fa fa-user link" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ul>
                  {options.map((option: TypeOption) => (
                    <li
                      key={option.label}
                      onClick={() => {
                        option.onClick && option.onClick();
                        option.route && navigate(option.route);
                        !option.onClick &&
                          document.dispatchEvent(new MouseEvent('click'));
                      }}
                    >
                      {option.label}
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <Link
            to={getDefaultRoute(userStore)}
            className="pl-2 pl-sm-4 d-flex align-items-center mr-2 mr-sm-0"
            onClick={() => setBool((prevState: boolean) => !prevState)}
          >
            <img
              src={`/static/img/${
                isMini ? 'mini.jpg' : isAlphera ? 'alphera.jpg' : 'bmw.png'
              }`}
              className="img-fluid header-logo"
              alt={isMini ? 'Mini' : 'BMW'}
            />
          </Link>
        </div>
        <Router>
          <Menu path={ROUTE_FOLDER} />
          <Menu path={ROUTE_TERMS} />
        </Router>
      </div>
    </header>
  );
};

export default Header;
