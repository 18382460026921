import { Dropdown } from 'react-bootstrap';
import React, { ReactNode } from 'react';
import { SelectCallback } from 'react-bootstrap/helpers';
import BmwLoader from '../BmwLoader';

export interface TypeItem {
  id: string;
  label: string;
  optionLabel?: string;
  isTitle?: boolean;
  className?: string;
  disabled?: boolean;
}

type Props = {
  onSelect: SelectCallback;
  items: TypeItem[];
  label: ReactNode | string;
  variant?: 'primary' | 'secondary';
  loading?: boolean;
  disabled?: boolean;
  className?: string;
};

const BmwDropdown = (props: Props) => {
  const {
    items,
    onSelect,
    label,
    variant = 'primary',
    loading,
    disabled = false,
    className = '',
  } = props;
  return (
    <Dropdown onSelect={onSelect} className={`mb-3 ${className}`}>
      <Dropdown.Toggle
        disabled={loading || disabled}
        variant={variant}
        id="dropdownMenuButtons"
      >
        {loading ? <BmwLoader /> : label}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {items.map((item: TypeItem) =>
          item.isTitle ? (
            <div key={item.id} className="p-2 ml-2 font-weight-bold">
              {item.optionLabel ?? item.label}
            </div>
          ) : (
            <Dropdown.Item
              key={item.id}
              eventKey={item.id}
              className={item.className}
              disabled={item.disabled}
            >
              {item.optionLabel ?? item.label}
            </Dropdown.Item>
          ),
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BmwDropdown;
