import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  default?: boolean;
  className?: string;
};

const Forbidden = (props: Props) => {
  const { className = '' } = props;
  const { t } = useTranslation();

  return (
    <div className={`content-body container px-2 px-sm-0 ${className}`}>
      <div className="forbidden">
        <h2 className="title">{t('forbidden.title')}</h2>
        <p className="description">{t('forbidden.description')}</p>
      </div>
    </div>
  );
};

export default Forbidden;
