import React from 'react';
import { useTranslation } from 'react-i18next';
import BmwYesNoModal from './BmwYesNoModal';
import { handleParsedResponse } from '../../utils/utils';
import { TypeFolderStore } from '../../types/storeTypes';
import { restPatchHandler } from '../../rest/RestClient';
import { FolderResponse } from '../../types/model/folderResponse';
import { FOLDERS } from '../../rest/apiPath';
import { ParsedResponse } from '../../rest/ServerResponseParse';
import { successToast } from '../../utils/toast';
import { setFolder } from '../../state/folder/FolderEvents';

type Props = {
  show: boolean;
  handleClose: () => void;
  folderStore: TypeFolderStore;
  setActiveKey: React.Dispatch<React.SetStateAction<string>> | null;
};

const BmwAskModal = (props: Props) => {
  const { show, handleClose, folderStore, setActiveKey } = props;
  const { t } = useTranslation();

  const processCallback = (): void => {
    if (setActiveKey != null) {
      setActiveKey('');
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <BmwYesNoModal
      show={show}
      handleClose={handleClose}
      title={t('refuse.modal.title')}
      yesButton={{
        label: t('refuse.modal.yes'),
        type: 'secondary',
        onClick: async () => {
          await restPatchHandler<null, FolderResponse>(FOLDERS.FEEDBACK_SUBMIT)(
            {
              id: folderStore.id,
              dto: null,
            },
          )
            .then((result: ParsedResponse<FolderResponse>) =>
              handleParsedResponse(result)((data: FolderResponse) => {
                successToast(t);
                setFolder({ ...folderStore, ...data });
              }),
            )
            .then(processCallback);
          handleClose();
        },
      }}
      noButton={{
        label: t('refuse.modal.no'),
        type: 'primary',
        onClick: async () => {
          handleClose();
        },
      }}
    >
      {t('refuse.modal.description')}
    </BmwYesNoModal>
  );
};

export default BmwAskModal;
