import * as React from 'react';
import { useState } from 'react';
import { useStore } from 'effector-react';
import { FolderStore } from '../../../state/folder/FolderStore';
import { TypeFolderStore, TypeUserStore } from '../../../types/storeTypes';
import { UserStore } from '../../../state/user/UserStore';
import BmwButton from '../../../components/form/BmwButton';
import { useTranslation } from 'react-i18next';
import { PROFILES } from '../../../utils/rights';
import BmwStudyPhaseModal from '../../../components/modal/BmwStudyPhaseModal';
import BmwAlert from '../../../components/BmwAlert';

const PaymentContainer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showStudyPhaseModal, setShowStudyPhaseModal] =
    useState<boolean>(false);
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const userStore = useStore<TypeUserStore>(UserStore);
  const { t } = useTranslation();

  const handlePhaseChange = () => {
    setShowStudyPhaseModal(true);
  };

  return (
    <>
      <BmwStudyPhaseModal
        handleClose={() => setShowStudyPhaseModal(false)}
        show={showStudyPhaseModal}
        folderStore={folderStore}
        setLoading={setLoading}
      />
      <BmwAlert label={t('folder.alert.PAYMENT')} type="info" />
      <div className="mt-3 d-flex justify-content-end">
        <div className="d-flex flex-wrap justify-content-end">
          {userStore.profiles.includes(PROFILES.PAYMENT_OPERATOR) && (
            <BmwButton
              className="mb-3"
              type="tertiary"
              loading={loading}
              onClick={() => handlePhaseChange()}
              label={t('payment.backToStudyPhase')}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentContainer;
