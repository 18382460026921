import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { FolderStore } from '../../state/folder/FolderStore';
import { CLIENT_TYPE, PROFILES } from '../../utils/rights';
import { ActorStore } from '../../state/actor/ActorStore';
import { ActorCategoryEnum } from '../../types/model/actorCategoryEnum';
import { ENDING_STATUS, PAYMENT_STATUS } from '../../utils/constants';
import { UserStore } from '../../state/user/UserStore';
import { TypeFolderStore, TypeUserStore } from '../../types/storeTypes';
import { TypeActor } from '../../types/globalTypes';
import { getDefaultRoute, ROUTE_PREFIX } from '../../utils/routes';
import { Link, navigate } from '@reach/router';
import BmwButton from '../form/BmwButton';
import { requestGetUser } from '../../state/user/UserEffets';
const Header = () => {
  const { t } = useTranslation();
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const actorStore = useStore<TypeActor[]>(ActorStore);
  const userStore = useStore<TypeUserStore>(UserStore);

  const client = actorStore.find((actor) => actor.type === CLIENT_TYPE.PRIMARY);

  return (
    <div id="header" className="mb-4">
      {!userStore.profiles.includes(PROFILES.CLIENT) && (
        <Link
          className={'text-decoration-none'}
          to={getDefaultRoute(userStore)}
        >
          <h5 className="mb-4 description">
            <i className="fa fa-chevron-left mr-2" />
            {t('folder.header.backButton')}
          </h5>
        </Link>
      )}
      <h2 className="mb-4">
        {t(
          `folder.header.${
            ENDING_STATUS.includes(folderStore.status) ||
            (PAYMENT_STATUS.includes(folderStore.status) &&
              (userStore.profiles.includes(PROFILES.STUDY_OPERATOR) ||
                userStore.profiles.includes(PROFILES.CLIENT)))
              ? 'informationTitle'
              : PAYMENT_STATUS.includes(folderStore.status)
              ? 'paymentTitle'
              : 'completeTitle'
          }`,
        )}
      </h2>
      <div className="d-flex flex-column flex-md-row align-items-start">
        <div className="flex-grow-1">
          <div className="mb-2">
            <span className="font-weight-bold">{`${t(
              'folder.header.id',
            )} : `}</span>
            {folderStore.id}
          </div>
          {client?.category !== ActorCategoryEnum.COMPANY ? (
            <>
              <div className="mb-2">
                <span className="font-weight-bold">{`${t(
                  `folder.header.lastname${
                    PAYMENT_STATUS.includes(folderStore.status)
                      ? '_payment'
                      : ''
                  }`,
                )} : `}</span>
                {client?.lastname}
              </div>
              <div className="mb-2">
                <span className="font-weight-bold">{`${t(
                  `folder.header.firstname${
                    PAYMENT_STATUS.includes(folderStore.status)
                      ? '_payment'
                      : ''
                  }`,
                )} : `}</span>
                {client?.firstname}
              </div>
            </>
          ) : (
            <>
              <div className="mb-2">
                <span className="font-weight-bold">{`${t(
                  'folder.header.corporation.name',
                )} : `}</span>
                {client?.corporationName}
              </div>
            </>
          )}
        </div>
        <div className="flex-grow-1">
          <div className="mb-2">
            <span className="font-weight-bold">{`${t(
              'folder.header.brand',
            )} : `}</span>
            {folderStore.vehicleBrand}
          </div>
          <div className="mb-2">
            <span className="font-weight-bold">{`${t(
              'folder.header.model',
            )} : `}</span>
            {folderStore.vehicleModel}
          </div>
        </div>
        {userStore.profiles.includes(PROFILES.CLIENT) && (
          <div className="flex-grow-1">
            <BmwButton
              disabled={false}
              label={t('folder.header.guidanceButton')}
              onClick={async () => {
                const me = await requestGetUser();
                await navigate(
                  me.data
                    ? ROUTE_PREFIX + '/folder/' + me.data.folderId + '/infos'
                    : '',
                );
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
