import { TypeToastStore } from '../../types/storeTypes';
import { createStore } from 'effector';
import { addToast, removeToast } from './ToastEvents';
import { TOAST_DURATION } from '../../utils/constants';

export const ToastStore = createStore<TypeToastStore[]>([])
  .on(addToast, (state: TypeToastStore[], payload: TypeToastStore) => {
    setTimeout(() => removeToast(), TOAST_DURATION);
    return [...state, payload];
  })
  .on(removeToast, (state: TypeToastStore[]) => {
    const newArray = [...state];
    newArray.shift();
    return newArray;
  });
