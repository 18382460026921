import { createEffect } from 'effector';
import { restListHandler } from '../../rest/RestClient';
import { DealershipItemResponse } from '../../types/model/dealershipItemResponse';
import { handleParsedResponse } from '../../utils/utils';
import { setDealerships } from './DealershipEvents';
import { DEALERSHIPS } from '../../rest/apiPath';

export const requestGetDealerships = createEffect({
  handler: restListHandler<DealershipItemResponse>(DEALERSHIPS.GET),
});

requestGetDealerships.done.watch(({ result }) =>
  handleParsedResponse(result)((data: DealershipItemResponse[]) =>
    setDealerships(data),
  ),
);
