import { useTranslation } from 'react-i18next';
import BmwYesNoModal from './BmwYesNoModal';
import React, { useState } from 'react';
import { restPatchHandler } from '../../rest/RestClient';
import { FolderResponse } from '../../types/model/folderResponse';
import { FOLDERS } from '../../rest/apiPath';
import { ParsedResponse } from '../../rest/ServerResponseParse';
import { handleParsedResponse } from '../../utils/utils';
import { successToast } from '../../utils/toast';
import { setFolder } from '../../state/folder/FolderEvents';
import { TypeFolderStore } from '../../types/storeTypes';
import { FolderMailingListUpdateRequest } from '../../types/model/folderMailingListUpdateRequest';

type Props = {
  show: boolean;
  handleClose: () => void;
  folderStore: TypeFolderStore;
};

const BmwMailingList = (props: Props) => {
  const { show, handleClose, folderStore } = props;
  const { t } = useTranslation();
  const [wrongEmailFormatWarning, setWrongEmailFormatWarning] =
    useState<string>('');
  const [disableYesButton, setDisableYesButton] = useState<
    boolean | undefined
  >();
  const pattern = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

  const validateEmail = (mail: string, elementToShow: HTMLElement | null) => {
    let invalidEmailsList: Array<string> = [];
    let warningMessage = t('mailing-list.modal.warning-message');
    let emailsList: Array<string> = mail.split(';').map((mail) => mail.trim());
    let validateEmails = true;

    emailsList?.forEach(function (value) {
      value = value.trim();
      let empty = false;
      if (value.slice(-1) === ';') {
        value = value.substring(0, value.length - 1);
        if (value.length === 0) {
          empty = true;
        }
      }
      if (!pattern.test(value) && (empty || value !== '')) {
        if (empty) {
          invalidEmailsList.push(t('mailing-list.modal.empty-email'));
        } else {
          invalidEmailsList.push(value);
        }
        validateEmails = false;
      }
    });

    if (invalidEmailsList) {
      invalidEmailsList.forEach(function (value) {
        if (warningMessage === t('mailing-list.modal.warning-message')) {
          warningMessage += value;
        } else {
          warningMessage = warningMessage + '; ' + value;
        }
      });
    } else {
      validateEmails = true;
    }

    setWrongEmailFormatWarning(warningMessage);

    if (elementToShow) {
      if (!validateEmails) {
        elementToShow.style.display = 'block';
      } else {
        elementToShow.style.display = 'none';
      }
    }

    setDisableYesButton(!validateEmails);

    return validateEmails;
  };

  return (
    <BmwYesNoModal
      show={show}
      handleClose={handleClose}
      title={t('mailing-list.modal.title')}
      yesButton={{
        label: t('mailing-list.modal.yes'),
        onClick: async () => {
          restPatchHandler<FolderMailingListUpdateRequest, FolderResponse>(
            FOLDERS.MAILING_LIST,
          )({
            id: folderStore.id,
            dto: {
              mailingList: (
                document.getElementById('mailingList') as HTMLInputElement
              ).value,
            },
          }).then((result: ParsedResponse<FolderResponse>) =>
            handleParsedResponse(result)((data: FolderResponse) => {
              successToast(t);
              setFolder({ ...folderStore, ...data });
            }),
          );
          handleClose();
        },
      }}
      noButton={{
        label: t('mailing-list.modal.no'),
        onClick: () => {
          setDisableYesButton(false);
          handleClose();
        },
      }}
      yesButtonDisabled={disableYesButton}
    >
      {t('mailing-list.modal.description')}
      <div className="form-outline  pt-2" style={{ marginTop: '5px' }}>
        <textarea
          onKeyUp={(e) =>
            validateEmail(
              (e.target as HTMLInputElement).value,
              document.getElementById('wrongEmailFormatWarning'),
            )
          }
          className="form-control"
          id="mailingList"
          defaultValue={folderStore.mailingList}
        />
      </div>
      <div style={{ minHeight: '15px', marginTop: '3px' }}>
        <div
          id="wrongEmailFormatWarning"
          style={{
            color: 'red',
            display: 'none',
            marginLeft: '5px',
            lineHeight: '100%',
          }}
        >
          {wrongEmailFormatWarning}
        </div>
      </div>
    </BmwYesNoModal>
  );
};

export default BmwMailingList;
