import { Accordion, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BmwAccordion from '../../../components/folder/BmwAccordion';
import React, { useCallback, useEffect, useState } from 'react';
import FileContainer from '../../../components/folder/study/FileContainer';
import AddFile from '../../../components/folder/study/AddFile';
import Invitation from '../../../components/folder/study/Invitation';
import { useStore } from 'effector-react';
import { FolderStore } from '../../../state/folder/FolderStore';
import { useTranslation } from 'react-i18next';
import { TypeActor, TypeDocument } from '../../../types/globalTypes';
import { DocumentsStore } from '../../../state/documents/DocumentsStore';
import { ActorStore } from '../../../state/actor/ActorStore';
import { FolderResponse } from '../../../types/model/folderResponse';
import {
  CATEGORY,
  DOCUMENT_SOURCE,
  STATUS,
  STUDY_SENDING_STATUS,
} from '../../../utils/constants';
import {
  requestGetDocumentActor,
  requestGetDocumentFolder,
} from '../../../state/documents/DocumentsEffects';
import {
  getActorName,
  handleParsedResponse,
  isMandatory,
  scrollAdapt,
} from '../../../utils/utils';
import BmwAlert from '../../../components/BmwAlert';
import { TypeFolderStore, TypeUserStore } from '../../../types/storeTypes';
import { requestGetActorCompletion } from '../../../state/actor/ActorEffects';
import { requestGetFolderCompletion } from '../../../state/folder/FolderEffects';
import { UserStore } from '../../../state/user/UserStore';
import {
  getNextActor,
  isFolderComplete,
} from '../../../services/folderService';
import BmwButton from '../../../components/form/BmwButton';
import { restPatchHandler } from '../../../rest/RestClient';
import { FOLDERS } from '../../../rest/apiPath';
import { ParsedResponse } from '../../../rest/ServerResponseParse';
import { setFolder } from '../../../state/folder/FolderEvents';
import ButtonLaunchControl from '../../../components/folder/study/ButtonLaunchControl';
import { successToast } from '../../../utils/toast';
import { clearDocuments } from '../../../state/documents/DocumentsEvents';
import { PROFILES } from '../../../utils/rights';
import BmwAskModal from '../../../components/modal/BmwAskModal';
import BmwRefuseModal from '../../../components/modal/BmwRefuseModal';
import BmwUnbindModal from '../../../components/modal/BmwUnbindModal';
import ReactHtmlParser from 'react-html-parser';

type Props = {
  isLoading?: boolean;
  isValid?: (file: TypeDocument) => boolean;
  isError?: (file: TypeDocument) => boolean;
  isWarning?: (file: TypeDocument) => boolean;
};

function useForceUpdate() {
  // eslint-disable-next-line
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

const Control = (props: Props) => {
  const [activeKey, setActiveKey] = useState<string>('');
  const [initialActorStore, setInitialActor] = useState<TypeActor[]>([]);
  const [showAskModal, setShowAskModal] = useState<boolean>(false);
  const [refuseModalVisible, setRefuseModalVisible] = useState<boolean>(false);
  const [isInvalidControlUpdate, setInvalidControlUpdate] =
    useState<boolean>(true);
  const [showUnbindModal, setShowUnbindModal] = useState<boolean>(false);
  const [unbindDocumentName, setUnbindDocumentName] = useState<
    string | undefined
  >(undefined);

  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const documentsStore = useStore<TypeDocument[]>(DocumentsStore);
  const actorStore = useStore<TypeActor[]>(ActorStore);
  const userStore = useStore<TypeUserStore>(UserStore);

  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();
  const {
    isLoading = false,
    isError = () => false,
    isValid = () => false,
    isWarning = () => false,
  } = props;

  const getTitle = (
    actor?: TypeActor,
    folder?: FolderResponse,
    total: number = 0,
    error: number = 0,
  ) => {
    if (!actor) {
      return (
        <span className="font-weight-bold">
          {t('folder.files.documents')} :{' '}
          {` ${error !== 0 ? ' ' + error : ''} ${t(
            `folder.files.${error !== 0 ? 'suspicious' : 'accepted'}`,
            { count: error !== 0 ? error : total },
          )}`}
        </span>
      );
    }
    return (
      <>
        <BmwAskModal
          handleClose={() => setShowAskModal(false)}
          show={showAskModal}
          folderStore={folderStore}
          setActiveKey={setActiveKey}
        />
        <span className="font-weight-bold">
          {t(`client.${folder?.category}.${actor.type.toLowerCase()}.label`)} :{' '}
        </span>
        {getActorName(actor)}
        <span className="font-weight-bold">{` -${
          error !== 0 ? ' ' + error : ''
        } ${t(`folder.files.${error !== 0 ? 'suspicious' : 'accepted'}`, {
          count: error !== 0 ? error : total,
        })}`}</span>
      </>
    );
  };

  const computeNextActor = useCallback(
    (actualActor: TypeActor | null, updateState = true) => {
      const nextActor = getNextActor(actualActor, userStore, initialActorStore);
      if (updateState || userStore.profiles.includes(PROFILES.CLIENT)) {
        if (nextActor) {
          setActiveKey(`actor_${nextActor.id}`);
        } else {
          if (!folderStore.completed && folderStore.nbDocumentTotal !== 0) {
            setActiveKey(folderKey);
          } else {
            setActiveKey('');
          }
        }
      }
    },
    [
      userStore,
      initialActorStore,
      folderStore.completed,
      folderStore.nbDocumentTotal,
    ],
  );

  const getActorCompletion = useCallback(() => {
    actorStore.forEach((actor: TypeActor) => {
      if (
        (!userStore.profiles.includes(PROFILES.CLIENT) ||
          actor.id === userStore.actorId) &&
        !actor.completionUpdated
      ) {
        requestGetActorCompletion({
          id: actor.id,
        });
      }
    });
  }, [actorStore, userStore.profiles, userStore.actorId]);

  useEffect(getActorCompletion, [getActorCompletion]);

  useEffect(() => {
    if (
      initialActorStore.length === 0 &&
      actorStore.length !== 0 &&
      !actorStore.find((a) => !a.completionUpdated)
    ) {
      setInitialActor(actorStore);
    }
  }, [actorStore, initialActorStore]);

  useEffect(() => {
    if (actorStore.find((a) => a.completed)) {
      setInitialActor(actorStore);
    }
  }, [actorStore]);

  useEffect(() => {
    if (activeKey !== '') {
      if (activeKey.split('_')[0] === folderKey) {
        requestGetDocumentFolder({
          id: folderStore.id,
        });
      } else {
        requestGetDocumentActor({
          id: activeKey.split('_')[1],
        });
      }
    }
  }, [activeKey, folderStore]);

  useEffect(() => computeNextActor(null, false), [computeNextActor]);

  useEffect(() => {
    const val =
      actorStore.some(
        (actor) => actor.hasFeedbackToSubmit && actor.isMissingRefusalId,
      ) ||
      (folderStore.hasFeedbackToSubmit && folderStore.isMissingRefusalId) ||
      (!actorStore.some((actor) => actor.hasFeedbackToSubmit) &&
        !folderStore.hasFeedbackToSubmit);

    if (folderStore.status === STATUS.STUDY_RETAKE_SUPPLY) {
      if (isFolderComplete(actorStore, folderStore)) {
        setRefuseModalVisible(true);
      }
    } else if (val !== isInvalidControlUpdate && isInvalidControlUpdate) {
      setRefuseModalVisible(true);
    }
    setInvalidControlUpdate(val);
  }, [actorStore, folderStore, isInvalidControlUpdate]);

  const processCallback = (): void => {
    setActiveKey('');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onClick = (): void => {
    restPatchHandler<null, FolderResponse>(FOLDERS.FEEDBACK_SUBMIT)({
      id: folderStore.id,
      dto: null,
    })
      .then((result: ParsedResponse<FolderResponse>) =>
        handleParsedResponse(result)((data: FolderResponse) => {
          successToast(t);
          setFolder({ ...folderStore, ...data });
        }),
      )
      .then(processCallback);
  };

  const disabled = STUDY_SENDING_STATUS.includes(folderStore.status);
  const folderKey = 'folder';
  return (
    <>
      <BmwRefuseModal
        handleClose={() => setRefuseModalVisible(false)}
        show={refuseModalVisible}
        folderStore={folderStore}
      />
      {disabled && (
        <BmwAlert
          type="info"
          label={t(`folder.alert.${STATUS.STUDY_LAUNCH_DOC_CONTROL}`)}
        />
      )}
      <div id="study" className="accordion mb-5 pt-4">
        <h4>{t('folder.pieces')}</h4>
        <Accordion activeKey={activeKey}>
          {actorStore.map((actor) => {
            const key = `actor_${actor.id}`;
            return (
              <BmwAccordion
                disabled={disabled}
                complete={actor.completed}
                error={actor.nbDocumentTotal - actor.nbDocumentValid}
                key={key}
                id={key}
                actorId={actor.id}
                title={getTitle(
                  actor,
                  folderStore,
                  actor.nbDocumentTotal,
                  actor.nbDocumentTotal - actor.nbDocumentValid,
                )}
                activeKey={activeKey}
                onClick={() => {
                  if (activeKey === key) {
                    setActiveKey('');
                  } else {
                    setActiveKey(key);
                  }
                }}
                validate={() => {
                  clearDocuments();
                  computeNextActor(actor);
                }}
                sendInvitation={
                  actor.hasEmailAddress &&
                  !actor.completed &&
                  folderStore.status === STATUS.STUDY_RETAKE_SUPPLY
                }
              >
                <h6>{t('folder.askedFiles')}</h6>
                <p className="description">{t('folder.description')}</p>
                <div className="mb-5">
                  {documentsStore
                    .filter((value) => isMandatory(value.source))
                    .map((file: TypeDocument) => (
                      <FileContainer
                        disabled={disabled}
                        valid={isValid(file)}
                        error={isError(file)}
                        warning={isWarning(file)}
                        id={`file_${file.id}_actor_${actor.id}`}
                        key={`file_${file.id}_actor_${actor.id}`}
                        file={file}
                        category={CATEGORY.ACTOR}
                      />
                    ))}
                </div>
                <AddFile
                  disabled={disabled}
                  title={t('folder.addFile.other.title')}
                  description={t('folder.addFile.other.description')}
                  button={t('folder.addFile.other.button')}
                  actor={actor}
                  source={DOCUMENT_SOURCE.OPERATOR_OPTIONAL}
                />
                <div className="mb-5">
                  {documentsStore
                    .filter((value) => !isMandatory(value.source))
                    .map((file: TypeDocument) => (
                      <FileContainer
                        disabled={disabled}
                        id={`file_${file.id}_actor_${actor.id}`}
                        key={`file_${file.id}_actor_${actor.id}`}
                        valid={isValid(file)}
                        error={isError(file)}
                        warning={isWarning(file)}
                        file={file}
                        category={CATEGORY.ACTOR}
                        mandatory={false}
                      />
                    ))}
                </div>
                <div className="custom-card-red p-4 mb-5">
                  <AddFile
                    disabled={disabled}
                    title={t('folder.addFile.ask.title')}
                    description={t('folder.addFile.ask.description')}
                    button={t('folder.addFile.ask.button')}
                    actor={actor}
                    source={DOCUMENT_SOURCE.OPERATOR_MANDATORY}
                    onSelect={() => {
                      if (folderStore.status === STATUS.STUDY_SEND_CONTROL) {
                        setShowAskModal(true);
                      }
                      requestGetActorCompletion({
                        id: actor.id,
                      });
                      scrollAdapt(key);
                    }}
                  />
                </div>
                <Invitation
                  actorId={actor.id}
                  checked={actor.autoReminder}
                  disabled={disabled}
                  sendInvitation={
                    !actor.completed &&
                    actor.hasEmailAddress &&
                    folderStore.status === STATUS.STUDY_RETAKE_SUPPLY
                  }
                />
              </BmwAccordion>
            );
          })}
          <BmwAccordion
            error={folderStore.nbDocumentTotal - folderStore.nbDocumentValid}
            complete={folderStore.completed}
            sendInvitation={false}
            id={folderKey}
            title={getTitle(
              undefined,
              undefined,
              folderStore.nbDocumentTotal,
              folderStore.nbDocumentTotal - folderStore.nbDocumentValid,
            )}
            activeKey={activeKey}
            onClick={() => {
              if (activeKey === folderKey) {
                setActiveKey('');
              } else {
                setActiveKey(folderKey);
              }
            }}
            validate={() => {
              clearDocuments();
              setActiveKey('');
            }}
          >
            <h6>{t('folder.askedFiles')}</h6>
            <p className="description">{t('folder.description')}</p>
            <div className="mb-5">
              {documentsStore
                .filter((value) => isMandatory(value.source))
                .map((file: TypeDocument) => (
                  <FileContainer
                    disabled={disabled}
                    valid={isValid(file)}
                    error={isError(file)}
                    warning={isWarning(file)}
                    id={`file_${file.id}_folder`}
                    key={`file_${file.id}_folder`}
                    file={file}
                    category={CATEGORY.FOLDER}
                  />
                ))}
            </div>
            <AddFile
              disabled={disabled}
              title={t('folder.addFile.other.title')}
              description={t('folder.addFile.other.description')}
              button={t('folder.addFile.other.button')}
              folderId={folderStore.id}
              source={DOCUMENT_SOURCE.OPERATOR_OPTIONAL}
            />
            <div className="mb-5">
              {documentsStore
                .filter((value) => !isMandatory(value.source))
                .map((file: TypeDocument) => (
                  <FileContainer
                    disabled={disabled}
                    valid={isValid(file)}
                    error={isError(file)}
                    warning={isWarning(file)}
                    id={`file_${file.id}_folder`}
                    key={`file_${file.id}_folder`}
                    file={file}
                    category={CATEGORY.FOLDER}
                    mandatory={false}
                  />
                ))}
            </div>
            <div className="custom-card-red p-4 mb-5">
              <AddFile
                disabled={disabled}
                title={t('folder.addFile.ask.title')}
                description={t('folder.addFile.ask.description')}
                button={t('folder.addFile.ask.button')}
                folderId={folderStore.id}
                source={DOCUMENT_SOURCE.OPERATOR_MANDATORY}
                onSelect={() => {
                  if (folderStore.status === STATUS.STUDY_SEND_CONTROL) {
                    setShowAskModal(true);
                  }
                  requestGetFolderCompletion({
                    id: folderStore.id,
                  });
                  scrollAdapt(folderKey);
                }}
              />
            </div>
          </BmwAccordion>
        </Accordion>
      </div>
      {folderStore.status === STATUS.STUDY_SEND_CONTROL && (
        <div className="d-flex justify-content-end">
          <BmwButton
            onClick={onClick}
            disabled={disabled || isInvalidControlUpdate}
            label={t('folder.validateControl')}
          />
        </div>
      )}
      {folderStore.status === STATUS.STUDY_RETAKE_SUPPLY && (
        <>
          <div className="unbind-card">
            <BmwButton
              label={ReactHtmlParser(
                unbindDocumentName
                  ? t('folder.unbind.resume-button', {
                      documentName: unbindDocumentName,
                    })
                  : t('folder.unbind.start-button'),
              )}
              type="primary"
              onClick={() => {
                setShowUnbindModal(true);
                setActiveKey('');
              }}
            />
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  {t('folder.unbind.description')}
                </Tooltip>
              }
            >
              <div className={'infoIcon'}></div>
            </OverlayTrigger>
          </div>
          <BmwUnbindModal
            onUnbindDocument={(documentName) => {
              setUnbindDocumentName(documentName);
            }}
            closeUnbindModal={() => {
              setShowUnbindModal(false);
              forceUpdate();
              getActorCompletion();
              if (userStore.profiles.includes(PROFILES.CLIENT)) {
                if (userStore.actorId) {
                  setActiveKey(`actor_${userStore.actorId}`);
                }
              } else {
                requestGetFolderCompletion({
                  id: folderStore.id,
                });
              }
            }}
            show={showUnbindModal}
            title={t('folder.unbind.modal.title')}
          />
          <div className="d-flex justify-content-end">
            <ButtonLaunchControl
              callback={processCallback}
              disabled={isLoading || !isFolderComplete(actorStore, folderStore)}
            />
          </div>
        </>
      )}
    </>
  );
};
export default Control;
