import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportingTable from '../../components/reporting/ReportingTable';
import ReportingFilter from '../../components/reporting/ReportingFilter';
import { CONTENT_TYPE, restHandler } from '../../rest/RestClient';
import { EXPORTS, REPORTINGS } from '../../rest/apiPath';
import { ParsedResponse } from '../../rest/ServerResponseParse';
import {
  downloadFile,
  getQueryVariable,
  handleParsedResponse,
} from '../../utils/utils';
import { ORDER, REPORTING_FOLDER_ATTRIBUTES } from '../../utils/constants';
import { TypeSort } from '../../types/tableTypes';
import { PageReportingFolder } from '../../types/model/pageReportingFolder';
import { ReportingFolderResponse } from '../../types/model/reportingFolderResponse';
import { TypeReportingFolderAttribute } from '../../types/globalTypes';
import Pagination from '../../components/Pagination';
import {
  DATE_FORMAT_SHORT,
  DATE_INPUT_FORMAT,
  formatDate,
} from '../../utils/date';
import { CONF_REPORTING_PAGE_SIZE } from '../../utils/configuration';
import BmwButton from '../../components/form/BmwButton';
import ReportingHeader from '../../components/reporting/ReportingHeader';

type Props = {
  path: string;
};

type Data = {
  id: string;
  folderId?: string;
  dealershipId?: string;
  dealershipName?: string;
  studyProcessTime?: string;
  paymentProcessTime?: string;
  totalProcessTime?: string;
  studyGoBackCount?: string;
  paymentGoBackCount?: string;
  endDate?: string;
  status?: string;
  cniLastUpload?: string;
  cniMean?: string;
  passportLastUpload?: string;
  passportMean?: string;
  ribLastUpload?: string;
  ribMean?: string;
  jddLastUpload?: string;
  jddMean?: string;
};

interface Column {
  name: string;
  label: string;
  className?: string;
  accessor: (data: any) => any;
}

interface Filter {
  startDate?: string;
  endDate?: string;
  dealershipIds: string[];
  district?: string;
  folderId?: string;
}

const DossierView = (props: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [maxPage, setMaxPage] = useState<number>(1);
  const [sort, setSort] = useState<TypeSort>({
    name: REPORTING_FOLDER_ATTRIBUTES.FOLDER_ID,
    order: ORDER.ASC,
  });
  const [data, setData] = useState<ReportingFolderResponse[]>([]);
  const [filter, setFilter] = useState({
    dealershipIds: getQueryVariable('id') ? [getQueryVariable('id')] : [],
  } as Filter);

  const convertColumnName = (name: TypeReportingFolderAttribute): string => {
    switch (name) {
      case 'folderId':
        return 'FOLDER_ID';
      case 'dealershipId':
        return 'DEALERSHIP_ID';
      case 'dealershipName':
        return 'DEALERSHIP_NAME';
      case 'endDate':
        return 'END_DATE';
    }
  };

  const buildSearchQueryParams = useCallback((): string => {
    return `?${filter.district ? `district=${filter.district}&` : ''}${
      filter.startDate ? `startDate=${filter.startDate}&` : ''
    }${filter.endDate ? `endDate=${filter.endDate}&` : ''}${
      filter.folderId ? `folderId=${filter.folderId}&` : ''
    }${filter.dealershipIds
      .map((dealershipId: string) => `dealershipIds=${dealershipId}&`)
      .join(
        '',
      )}pageIndex=${page}&pageSize=${CONF_REPORTING_PAGE_SIZE}&sort=${convertColumnName(
      sort.name as TypeReportingFolderAttribute,
    )}&direction=${sort.order}`;
  }, [filter, sort, page]);

  const search = useCallback(() => {
    setLoading(true);
    restHandler<PageReportingFolder>(
      REPORTINGS.FOLDER,
      buildSearchQueryParams(),
    )()
      .then((res: ParsedResponse<PageReportingFolder>) =>
        handleParsedResponse(res)((data: PageReportingFolder) => {
          setData(data.content || []);
          setPage(data.pageIndex || 0);
          setMaxPage(data.maxPageIndex || 1);
        }),
      )
      .finally(() => setLoading(false));
  }, [buildSearchQueryParams]);

  useEffect(() => {
    search();
  }, [search]);

  useEffect(() => {
    const query = getQueryVariable('search');
    if (query) {
      setFilter((prevState) => ({ ...prevState, dealershipIds: [query] }));
    }
  }, []);

  const columns = [
    {
      label: t('reporting.dossier.table.id'),
      name: 'folderId',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.dossier.table.concessionId'),
      name: 'dealershipId',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.dossier.table.concessionName'),
      name: 'dealershipName',
      accessor: (data: string) => data,
    },
    {
      label: t('reporting.dossier.table.durationStudy'),
      name: 'studyProcessTime',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.dossier.table.durationPayment'),
      name: 'paymentProcessTime',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.dossier.table.durationGlobal'),
      name: 'totalProcessTime',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.dossier.table.nbGobackStudy'),
      name: 'studyGoBackCount',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 160,
    },
    {
      label: t('reporting.dossier.table.nbGobackPayment'),
      name: 'paymentGoBackCount',
      className: 'custom-bg-blue',
      accessor: (data: string) => data,
      width: 160,
    },
    {
      label: t('reporting.dossier.table.endDate'),
      name: 'endDate',
      className: 'custom-bg-blue',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 150,
    },
    {
      label: t('reporting.dossier.table.status'),
      name: 'status',
      className: 'custom-bg-blue',
      accessor: (data: string) => t(`status.${data}`),
      width: 160,
    },
    {
      label: t('reporting.dossier.table.cniLastDate'),
      name: 'cniLastUpload',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 150,
    },
    {
      label: t('reporting.dossier.table.cni'),
      name: 'cniMean',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.dossier.table.passportLastDate'),
      name: 'passportLastUpload',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 150,
    },
    {
      label: t('reporting.dossier.table.passport'),
      name: 'passportMean',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.dossier.table.ribLastDate'),
      name: 'ribLastUpload',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 150,
    },
    {
      label: t('reporting.dossier.table.rib'),
      name: 'ribMean',
      accessor: (data: string) => data,
      width: 130,
    },
    {
      label: t('reporting.dossier.table.proofAddressLastDate'),
      name: 'jddLastUpload',
      accessor: (data: string) =>
        formatDate(data, DATE_FORMAT_SHORT, DATE_INPUT_FORMAT, false),
      width: 150,
    },
    {
      label: t('reporting.dossier.table.proofAddress'),
      name: 'jddMean',
      accessor: (data: string) => data,
      width: 130,
    },
  ] as Column[];

  const mapToData = (value: ReportingFolderResponse): Data => ({
    ...value,
    id: value.folderId || '',
    studyProcessTime:
      value.studyProcessTime || value.studyProcessTime === 0
        ? `${value.studyProcessTime}j`
        : '',
    paymentProcessTime:
      value.paymentProcessTime || value.paymentProcessTime === 0
        ? `${value.paymentProcessTime}j`
        : '',
    totalProcessTime:
      value.totalProcessTime || value.totalProcessTime === 0
        ? `${value.totalProcessTime}j`
        : '',
    studyGoBackCount: value.studyGoBackCount?.toString(),
    paymentGoBackCount: value.paymentGoBackCount?.toString(),
    endDate: value.endDate || value.endDate === null ? `${value.endDate}j` : '',
    status: value.status || '',
    cniMean: value.cniMean || value.cniMean === 0 ? `${value.cniMean}%` : '',
    passportMean:
      value.passportMean || value.passportMean === 0
        ? `${value.passportMean}%`
        : '',
    ribMean: value.ribMean || value.ribMean === 0 ? `${value.ribMean}%` : '',
    jddMean: value.jddMean || value.jddMean === 0 ? `${value.jddMean}%` : '',
  });

  const onExport = useCallback(() => {
    setLoading(true);
    restHandler<string>(
      EXPORTS.FOLDER,
      buildSearchQueryParams(),
      undefined,
      CONTENT_TYPE.EXCEL,
    )()
      .then((res: ParsedResponse<string>) => {
        handleParsedResponse(res)((blob: Blob) =>
          downloadFile(res.filename || '', blob),
        );
      })
      .finally(() => setLoading(false));
  }, [buildSearchQueryParams]);

  const supHeader = (
    <div className="custom-tr">
      <div className="custom-th not-sortable" style={{ flex: '1 0 20%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.dossier.table.dossier')}
        </div>
      </div>
      <div className="custom-th not-sortable" style={{ flex: '1 0 33%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.dossier.table.folderTreatment')}
        </div>
      </div>
      <div className="custom-th not-sortable" style={{ flex: '1 0 47%' }}>
        <div className="custom-content text-center font-weight-bold">
          {t('reporting.dossier.table.pieceAnalysis')}
        </div>
      </div>
    </div>
  );

  return (
    <div id="reporting" className="content-body container-fluid px-2 px-sm-0">
      <div className="container">
        <ReportingHeader />
        <div className="mb-5 d-flex flex-row flex-wrap align-items-stretch justify-content-between">
          <h2 className="title">
            {t('reporting.dossier.title.first')}
            <br />
            {t('reporting.dossier.title.second')}
          </h2>
          <BmwButton
            type="primary"
            className="export"
            label={t('reporting.export')}
            onClick={onExport}
            disabled={loading}
          />
        </div>
        <div className="mt-3">
          <ReportingFilter
            filter={filter}
            setFilter={setFilter}
            setPage={setPage}
            disabled={loading}
            folderIdFilter
          />
        </div>
      </div>
      <div className="my-3 overflow-auto">
        <ReportingTable
          loading={loading}
          setLoading={setLoading}
          columns={columns}
          data={data.map((reporting: ReportingFolderResponse) =>
            mapToData(reporting),
          )}
          sortableColumns={[
            'folderId',
            'dealershipId',
            'dealershipName',
            'endDate',
          ]}
          supHeader={supHeader}
          minWidth="1775px"
          setSort={setSort}
          sort={sort}
        />
      </div>
      <Pagination
        maxPage={maxPage}
        changePage={(newPage: number) => {
          setPage(newPage - 1);
        }}
        disabled={loading}
        currentPage={page + 1}
        minPage={1}
        size={7}
      />
    </div>
  );
};

export default DossierView;
