import React from 'react';
import { Link } from '@reach/router';
import {
  ROUTE_COOKIE,
  ROUTE_LEGAL_MENTION,
  ROUTE_PERSONAL_DATA,
} from '../utils/routes';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="container">
        <Link to={ROUTE_LEGAL_MENTION}>{t('legalMention.title')}</Link>
        <Link to={ROUTE_PERSONAL_DATA}>{t('personalData.title')}</Link>
        <Link to={ROUTE_COOKIE}>{t('cookie.title')}</Link>
      </div>
    </footer>
  );
};

export default Footer;
