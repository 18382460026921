import moment from 'moment';

export const DATE_FORMAT_SHORT = 'DD/MM/YYYY';

export const DATE_INPUT_FORMAT = 'YYYY-MM-DD';

export const DATE_INPUT_FORMAT_LONG = 'YYYY-MM-DD[T]HH:mm:SS[Z]';

export const DATE_FORMAT_SHORT_WITH_TIME = 'DD/MM/YYYY [à] HH[h]mm';

export const DATE_MONTH_YEAR_LITTERAL = 'MMMM YYYY';

export const formatDate = (
  date: string,
  format: string,
  inputFormat: string = DATE_INPUT_FORMAT,
  utc: boolean = true,
): string | undefined => {
  if (!moment(date, inputFormat).isValid()) {
    return undefined;
  }
  return utc
    ? moment(date, inputFormat).utc().format(format)
    : moment(date, inputFormat).format(format);
};
