import {
  CONTENT_TYPE,
  restCreationHandler,
  restDetailsHandler,
} from '../../rest/RestClient';
import { DOCUMENT_CLASSIFY } from '../../rest/apiPath';
import { DocumentClassifyResponse } from '../../types/model/documentClassifyResponse';
import { createEffect } from 'effector';
import { DocumentClassifyRequest } from '../../types/model/documentClassifyRequests';

export const requestClassifyFile = createEffect({
  handler: restCreationHandler<
    FormData,
    DocumentClassifyRequest,
    DocumentClassifyResponse
  >(DOCUMENT_CLASSIFY.POST, CONTENT_TYPE.FORM_DATA),
});

export const requestGetClassifyResult = createEffect({
  handler: restDetailsHandler<
    DocumentClassifyResponse,
    DocumentClassifyRequest
  >(DOCUMENT_CLASSIFY.GET),
});
