import React from 'react';

type Props = {
  label: string;
  type?: 'danger' | 'warning' | 'info';
};

const BmwAlert = (props: Props) => {
  const { label, type = 'danger' } = props;
  return (
    <div title={label} className={`alert alert-${type}`}>
      {label}
    </div>
  );
};

export default BmwAlert;
