import { hasAccessToFolder, PROFILES } from './rights';
import { TypeUserStore } from '../types/storeTypes';

export const ROUTE_PREFIX = '/fo';

export const ROUTE_DASHBOARD = ROUTE_PREFIX + '/';
export const ROUTE_LOGIN = ROUTE_PREFIX + '/login';
export const ROUTE_TEST_LOGIN = ROUTE_PREFIX + '/test-login';
export const ROUTE_LOGOUT = ROUTE_PREFIX + '/logout';
export const ROUTE_FOLDER = ROUTE_PREFIX + '/folder/:id';
export const ROUTE_TERMS = ROUTE_FOLDER + '/infos';
export const ROUTE_BACK_OFFICE = ROUTE_PREFIX + '/back-office';
export const ROUTE_PERSONAL_DATA = ROUTE_PREFIX + '/personal-data';
export const ROUTE_LEGAL_MENTION = ROUTE_PREFIX + '/legal-mention';
export const ROUTE_COOKIE = ROUTE_PREFIX + '/cookie';
export const ROUTE_REPORTING = ROUTE_PREFIX + '/reporting';
export const ROUTE_REPORTING_FOLDER = ROUTE_REPORTING + '/folder';
export const ROUTE_REPORTING_CURRENT_FOLDER =
  ROUTE_REPORTING + '/current-folder';

export const ROUTE_REPORTING_IMPORT_FOLDER_STATUS =
  ROUTE_REPORTING + '/import-status';
export const ROUTE_REPORTING_DEALERSHIP = ROUTE_REPORTING + '/dealership';
export const ROUTE_REPORTING_AGENCY = ROUTE_REPORTING + '/agency';

export const ROUTE_NOT_FOUND = ROUTE_PREFIX + '/not-found';

export const REPORTING_ROUTES = [
  ROUTE_REPORTING,
  ROUTE_REPORTING_DEALERSHIP,
  ROUTE_REPORTING_FOLDER,
  ROUTE_REPORTING_AGENCY,
];

export const PUBLIC_ROUTES = [
  ROUTE_COOKIE,
  ROUTE_LEGAL_MENTION,
  ROUTE_PERSONAL_DATA,
  ROUTE_LOGOUT,
  ROUTE_LOGIN,
  ROUTE_TEST_LOGIN,
];

export const REDIRECT_ROUTES = ['/', '/fo/folder', '/fo/folder/'];

export const getDefaultRoute = (userStore: TypeUserStore): string => {
  const profiles = userStore.profiles;
  const canHaveAccessToDashboard =
    hasAccessToFolder(profiles) && !profiles.includes(PROFILES.CLIENT);

  const userToken = window.sessionStorage.getItem('id');

  const routeClientOrLogin =
    profiles.includes(PROFILES.CLIENT) && userStore.folderId
      ? ROUTE_PREFIX + '/folder/' + userStore.folderId
      : ROUTE_LOGIN + (userToken ? `?id=${userToken}` : '');

  const routeReportingOrClientAndLogin = profiles.includes(
    PROFILES.BACK_OFFICE_REPORTING,
  )
    ? ROUTE_REPORTING
    : routeClientOrLogin;

  const routeLabelOrReportingAndClient = profiles.includes(
    PROFILES.BACK_OFFICE_LABEL,
  )
    ? ROUTE_BACK_OFFICE
    : routeReportingOrClientAndLogin;

  return canHaveAccessToDashboard
    ? ROUTE_DASHBOARD
    : routeLabelOrReportingAndClient;
};
