import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderStore } from '../state/header/HeaderStore';
import { MENU, STATUS } from '../utils/constants';
import { useStore } from 'effector-react';
import { setMenu } from '../state/header/HeaderEvents';
import {
  TypeFolderStore,
  TypeHeaderStore,
  TypeMenu,
} from '../types/storeTypes';
import { FolderStore } from '../state/folder/FolderStore';

type Props = {
  path: string;
};

const Menu = (props: Props) => {
  const { t } = useTranslation();
  const headerStore = useStore<TypeHeaderStore>(HeaderStore);
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const menu = headerStore.menu;

  useEffect(() => {
    if (folderStore.status.includes('STUDY')) {
      setMenu(MENU.STUDY as TypeMenu);
    } else if (folderStore.status === STATUS.PAYMENT_SURVEY) {
      setMenu(MENU.SURVEY as TypeMenu);
    } else {
      setMenu(MENU.PAYMENT as TypeMenu);
    }
  }, [folderStore.status]);

  return (
    <div className="wizard d-flex align-items-stretch">
      <div
        className={`step text-center ${
          menu === MENU.STUDY ? 'active' : 'forbidden'
        }`}
      >
        {t('folder.header.study')}
      </div>
      <div
        className={`step text-center ${
          [MENU.PAYMENT, MENU.SURVEY].includes(menu) ? 'active' : 'disabled'
        }`}
      >
        {t('folder.header.payment')}
      </div>
      <div className="empty-step flex-grow-1" />
    </div>
  );
};

export default Menu;
