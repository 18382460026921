import { createEffect } from 'effector';
import { restCreationHandler, restIdListHandler } from '../../rest/RestClient';
import { DocumentItemResponse } from '../../types/model/documentItemResponse';
import { TypeDocument } from '../../types/globalTypes';
import { handleParsedResponse } from '../../utils/utils';
import { DOCUMENT_STATUS } from '../../utils/constants';
import { addDocuments, setDocuments } from './DocumentsEvents';
import { DocumentCreationRequest } from '../../types/model/documentCreationRequest';
import { DocumentResponse } from '../../types/model/documentResponse';
import { DOCUMENTS } from '../../rest/apiPath';

export const requestGetDocumentActor = createEffect({
  handler: restIdListHandler<DocumentItemResponse>(DOCUMENTS.GET_ACTORS),
});

export const requestGetDocumentActorAppend = createEffect({
  handler: restIdListHandler<DocumentItemResponse>(DOCUMENTS.GET_ACTORS),
});

export const requestGetDocumentFolder = createEffect({
  handler: restIdListHandler<DocumentItemResponse>(DOCUMENTS.GET_FOLDERS),
});

export const requestGetDocumentFolderAppend = createEffect({
  handler: restIdListHandler<DocumentItemResponse>(DOCUMENTS.GET_FOLDERS),
});

export const requestGetPaymentDocument = createEffect({
  handler: restIdListHandler<DocumentItemResponse>(DOCUMENTS.PAYMENT),
});

export const requestAddDocument = createEffect({
  handler: restCreationHandler<DocumentCreationRequest, void, DocumentResponse>(
    DOCUMENTS.CREATE,
  ),
});

requestGetDocumentActor.done.watch(({ result }) =>
  handleParsedResponse(
    result,
    false,
  )((data: DocumentItemResponse[]) => setDocuments(data.map(toTypeDocument))),
);

requestGetDocumentActorAppend.done.watch(({ result }) =>
  handleParsedResponse(
    result,
    false,
  )((data: DocumentItemResponse[]) => addDocuments(data.map(toTypeDocument))),
);

requestGetDocumentFolder.done.watch(({ result }) =>
  handleParsedResponse(result)((data: DocumentItemResponse[]) =>
    setDocuments(data.map(toTypeDocument)),
  ),
);

requestGetDocumentFolderAppend.done.watch(({ result }) =>
  handleParsedResponse(result)((data: DocumentItemResponse[]) =>
    addDocuments(data.map(toTypeDocument)),
  ),
);

requestGetPaymentDocument.done.watch(({ result }) =>
  handleParsedResponse(result)((data: DocumentItemResponse[]) =>
    setDocuments(data.map(toTypeDocument)),
  ),
);

requestAddDocument.done.watch(({ result, params }) =>
  handleParsedResponse(result)((data: DocumentResponse) => {
    if (data.actorId) {
      requestGetDocumentActor({
        id: data.actorId,
      });
    } else if (data.folderId) {
      requestGetDocumentFolder({
        id: data.folderId,
      });
    }
  }),
);

const toTypeDocument = (value: DocumentItemResponse): TypeDocument => ({
  ...value,
  ignored: value.status === DOCUMENT_STATUS.IGNORED,
  uploaded: value.status !== DOCUMENT_STATUS.PENDING,
  rejected: value.status === DOCUMENT_STATUS.REJECTED,
  updated: value.status === DOCUMENT_STATUS.UPDATED,
  pending: value.status === DOCUMENT_STATUS.PENDING,
});
