import React, { useCallback, useState } from 'react';
import BmwDropdown, { TypeItem } from '../../form/BmwDropdown';
import { DocumentTypeStore } from '../../../state/documentType/DocumentTypeStore';
import { DocumentTypeItemResponse } from '../../../types/model/documentTypeItemResponse';
import { useStore } from 'effector-react';
import { requestAddDocument } from '../../../state/documents/DocumentsEffects';
import { addToast } from '../../../state/toast/ToastEvents';
import { useTranslation } from 'react-i18next';
import { CATEGORY, keyOfDocumentSource } from '../../../utils/constants';
import { handleParsedResponse } from '../../../utils/utils';
import { DocumentTargetEnum } from '../../../types/model/documentTargetEnum';
import BmwButtonGroup from '../../form/BmwButtonGroup';
import { TypeActor, TypeDocument } from '../../../types/globalTypes';
import { ActorCategoryEnum } from '../../../types/model/actorCategoryEnum';
import { DocumentsStore } from '../../../state/documents/DocumentsStore';
import { DocumentSourceEnum } from '../../../types/model/documentSourceEnum';
import ReactHtmlParser from 'react-html-parser';

type Props = {
  title: string;
  description: string;
  button: string;
  actor?: TypeActor;
  folderId?: string;
  source: keyOfDocumentSource;
  disabled?: boolean;
  onSelect?: () => void;
};

const mapDocumentTypeToItem = (
  documentType: DocumentTypeItemResponse,
): TypeItem => ({
  id: documentType.id,
  label: documentType.labelName || '',
});

const sortTypeItem = (a: TypeItem, b: TypeItem) => {
  if (a.label === b.label) {
    return 0;
  }
  return a.label > b.label ? 1 : -1;
};

const AddFile = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(true);
  const documentTypeStore =
    useStore<DocumentTypeItemResponse[]>(DocumentTypeStore);
  const documentsStore = useStore<TypeDocument[]>(DocumentsStore);
  const {
    title,
    description,
    button,
    actor,
    folderId,
    source,
    disabled = false,
    onSelect,
  } = props;
  const { t } = useTranslation();

  const getTarget = useCallback((): DocumentTargetEnum => {
    if (actor) {
      if (actor.category === ActorCategoryEnum.COMPANY) {
        return selected
          ? DocumentTargetEnum.CORPORATION
          : DocumentTargetEnum.SIGNATORY;
      } else {
        return selected
          ? DocumentTargetEnum.ACTORORFOLDER
          : DocumentTargetEnum.LODGER;
      }
    } else {
      return DocumentTargetEnum.ACTORORFOLDER;
    }
  }, [actor, selected]);

  const filterDocumentList = useCallback(
    (item: DocumentTypeItemResponse): boolean => {
      const target = getTarget();
      return !documentsStore.find(
        (document: TypeDocument) =>
          document.type.id === item.id && document.target === target,
      );
    },
    [getTarget, documentsStore],
  );

  const buildItems = useCallback((): TypeItem[] => {
    if (actor) {
      const actorDocumentTypes = documentTypeStore
        .filter(
          (documentTypeStore) => documentTypeStore.category === CATEGORY.ACTOR,
        )
        .filter(filterDocumentList);
      return actorDocumentTypes.map(mapDocumentTypeToItem).sort(sortTypeItem);
    } else if (folderId) {
      const folderDocumentTypes = documentTypeStore
        .filter(
          (documentTypeStore) => documentTypeStore.category === CATEGORY.FOLDER,
        )
        .filter(filterDocumentList);
      return folderDocumentTypes.map(mapDocumentTypeToItem).sort(sortTypeItem);
    }
    return [];
  }, [actor, documentTypeStore, folderId, filterDocumentList]);

  const items = buildItems();

  return (
    <>
      <h6>{title}</h6>
      <p className="description">{ReactHtmlParser(description)}</p>
      {actor && (
        <BmwButtonGroup
          disabled={disabled}
          selected={selected}
          onClick={(value) => setSelected(value)}
          className="mb-3 small-btn"
          firstLabel={t(
            `folder.actor.${
              actor.category === ActorCategoryEnum.COMPANY
                ? 'corporation'
                : 'actor'
            }`,
          )}
          secondLabel={t(
            `folder.actor.${
              actor.category === ActorCategoryEnum.COMPANY
                ? 'signatory'
                : 'lodger'
            }`,
          )}
        />
      )}
      <div className="small-btn">
        <BmwDropdown
          disabled={disabled || !items || items.length === 0}
          loading={loading}
          items={items}
          onSelect={(typeId: string | null) => {
            setLoading(true);
            typeId &&
              requestAddDocument({
                typeId: typeId,
                folderId: folderId,
                actorId: actor?.id,
                source: source as DocumentSourceEnum,
                target: getTarget(),
              }).then((res) =>
                handleParsedResponse(res)(() => {
                  setLoading(false);
                  addToast({
                    type: 'success',
                    title: t('toast.create.title'),
                    message: t('toast.create.message', { name: 'Le document' }),
                  });
                  onSelect && onSelect();
                }),
              );
          }}
          label={
            <>
              <i className="fa fa-plus pr-2" />
              {button}
            </>
          }
        />
      </div>
    </>
  );
};

export default AddFile;
